<template>
	<div class="p-3 mt-3" v-loading="loading">
		<div
			class="container flex justify-between mb-1 p-3 bg-white section-container"
		>
			<div class="w-full">
				<div
					class="w-full border-bottom"
					v-on:click="openArticleDescription"
				>
					<i
						class="lnr-chevron-down font-semibold ml-1 icon-size"
						v-if="articleDescription === false"
					></i>
					<i
						class="lnr-chevron-up font-semibold ml-1 icon-size"
						v-else
					></i
					><span class="ml-2 font-semibold text-base">{{
						$t("productInformation")
					}}</span>
				</div>
				<div class="row form-row" v-if="articleDescription === true">
					<div class="col-md-4">
						<div class="form-group mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("name")
							}}</span>
							<input
								type="text"
								v-model="articleObjData.description"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("articleNumber")
							}}</span>
							<div class="form-group w-full">
								<input
									type="text"
									v-model="articleObjData.article_number"
									disabled
									readonly
									class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
									style="outline: currentcolor none medium"
									required
								/>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group mt-2 w-full">
							<span class="text-sm text-gray-600 mb-2">{{
								$t("quantity")
							}}</span>
							<input
								type="number"
								min="0"
								v-model="articleObjData.quantity_in_stock"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("articleVideoURL")
							}}</span>
							<input
								type="text"
								v-model="articleObjData.product_video_url"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/2 mb-2">{{
								$t("unittypes")
							}}</span>
							<el-select
								v-model="unit_type"
								filterable
								class="w-full"
								:placeholder="`${$t('search')}`"
							>
								<el-option
									v-for="item in unitTypeList"
									:key="item.id"
									:label="`${item.title}`"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("deliveryTime")
							}}</span>
							<input
								type="text"
								v-model="articleObjData.delivery_time"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
							<small>{{ $t("estimatedDeliveryDay") }}</small>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="font-semibold">{{
								$t("description")
							}}</label>
							<vue-editor
								class="h-custom-screen"
								v-model="
									articleObjData.product_long_description
								"
								:editorToolbar="customToolbar"
							></vue-editor>
							<!--							<textarea type="text" cols="30" rows="10"-->
							<!--									  v-model="articleObjData.product_long_description"-->
							<!--									  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"></textarea>-->
						</div>
					</div>
				</div>
				<div
					class="w-full border-bottom mt-3 mb-20px"
					v-on:click="openPriceInformation"
				>
					<i
						class="lnr-chevron-down font-semibold ml-1 icon-size"
						v-if="stockInformation === false"
					></i>
					<i
						class="lnr-chevron-up font-semibold ml-1 icon-size"
						v-else
					></i
					><span class="ml-2 font-semibold text-base">{{
						$t("priceInformation")
					}}</span>
				</div>
				<div class="row form-row" v-if="priceInformation === true">
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("purchase_price")
							}}</span>
							<input
								type="number"
								min="0"
								v-model="articleObjData.purchase_price"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4 w-full mt-2">
						<div class="form-group w-full">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("salesPrice")
							}}</span>
							<input
								type="number"
								min="0"
								v-model="articleObjData.sales_price"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("discountedValue")
							}}</span>
							<input
								type="number"
								v-model="articleObjData.discount_value"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("campaignScheduleDate")
							}}</span>
							<el-date-picker
								style="
									width: 96% !important;
									height: 36px !important;
								"
								size="small"
								v-model="dateRangeFilter"
								type="daterange"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								range-separator="|"
							>
							</el-date-picker>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{
								$t("minimumOrderAmount")
							}}</span>
							<input
								type="number"
								v-model="articleObjData.minimum_order_amount"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
							/>
						</div>
					</div>
					<div class="col-md-4 w-full">
						<span class="text-sm text-gray-600 mb-2">{{
							$t("discountType")
						}}</span>
						<div class="pr-3 md:pr-0 w-full">
							<el-select
								v-model="articleObjData.discount_type"
								filterable
								:placeholder="`${$t('search')}`"
								class="w-full"
							>
								<el-option
									v-for="item in DISCOUNT_TYPES"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div
					class="w-full border-bottom mt-3 mb-20px"
					v-on:click="openRecommendationInformation"
				>
					<i
						class="lnr-chevron-down font-semibold ml-1 icon-size"
						v-if="recommendationInformation === false"
					></i>
					<i
						class="lnr-chevron-up font-semibold ml-1 icon-size"
						v-else
					></i
					><span class="ml-2 font-semibold text-base">{{
						$t("productRecommendations")
					}}</span>
				</div>
				<div
					class="row form-row"
					v-if="recommendationInformation === true"
				>
					<div class="col-md-12">
						<div class="form-group w-full mt-2">
							<div
								class="text-sm text-gray-600 w-full mb-2 border-bottom"
							>
								<span>{{ $t("recommendedProduct") }}</span>
								<span class="float-right"
									><i
										class="lnr-chevron-down"
										@click="addRecommendToggle = true"
										v-if="addRecommendToggle === false"
									></i
									><i
										class="lnr-chevron-up"
										@click="addRecommendToggle = false"
										v-else
									></i
								></span>
							</div>

							<div
								class="form-group unset-margin mt-2"
								v-if="addRecommendToggle === true"
							>
								<div>
									<input
										v-model="articleRecommendSearchName"
										type="text"
										@input="searchRecommendProduct"
										@focus="
											openArticleRecommendDropdown = true
										"
										:placeholder="$t('searchArticles')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
								<div
									class="absolute inset-0 z-40"
									@click="
										openArticleRecommendDropdown = false
									"
									v-if="openArticleRecommendDropdown"
								></div>
								<div
									style="z-index: 99999"
									class="w-1/2 absolute bg-white shadow border rounded mt-1"
									v-if="
										openArticleRecommendDropdown &&
										filteredArticle.length > 0
									"
								>
									<ul>
										<li
											class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="item in filteredArticle.slice(
												0,
												7
											)"
											:key="item.id"
											@click="addArticleToList(item)"
										>
											{{ item.description }}
										</li>
									</ul>
								</div>
							</div>
							<div class="row" v-if="addRecommendToggle === true">
								<div
									class="col-md-6 mb-1 mt-2"
									v-for="(article, key) in selectedArticle"
									:key="article.id"
								>
									<div
										class="flex justify-between bg-white border rounded-md shadow-sm p-1"
									>
										<div>{{ article.description }}</div>
										<div>
											<span
												class="cursor-pointer"
												@click="
													removeUserFromSelectedList(
														key
													)
												"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														clip-rule="evenodd"
													/>
												</svg>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group w-full mt-2">
							<div
								class="text-sm text-gray-600 w-full mb-2 border-bottom"
							>
								<span>{{ $t("recommendedAccessories") }}</span>
								<span class="float-right"
									><i
										class="lnr-chevron-down"
										@click="addAccessoriesToggle = true"
										v-if="addAccessoriesToggle === false"
									></i
									><i
										class="lnr-chevron-up"
										@click="addAccessoriesToggle = false"
										v-else
									></i
								></span>
							</div>

							<div
								class="form-group unset-margin mt-2"
								v-if="addAccessoriesToggle === true"
							>
								<div>
									<input
										v-model="articleAccessoriesSearchName"
										type="text"
										@input="searchAccessoriesProduct"
										@focus="
											openArticleAccessoriesDropdown = true
										"
										:placeholder="$t('searchArticles')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
								<div
									class="absolute inset-0 z-40"
									@click="
										openArticleAccessoriesDropdown = false
									"
									v-if="openArticleAccessoriesDropdown"
								></div>
								<div
									style="z-index: 99999"
									class="w-1/2 absolute bg-white shadow border rounded mt-1"
									v-if="
										openArticleAccessoriesDropdown &&
										filteredAccessories.length > 0
									"
								>
									<ul>
										<li
											class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="item in filteredAccessories.slice(
												0,
												7
											)"
											:key="item.id"
											@click="
												addArticleAccessoriesToList(
													item
												)
											"
										>
											{{ item.description }}
										</li>
									</ul>
								</div>
							</div>
							<div
								class="row"
								v-if="addAccessoriesToggle === true"
							>
								<div
									class="col-md-6 mb-1 mt-2"
									v-for="(
										article, key
									) in selectedAccessories"
									:key="article.id"
								>
									<div
										class="flex justify-between bg-white border rounded-md shadow-sm p-1"
									>
										<div>{{ article.description }}</div>
										<div>
											<span
												class="cursor-pointer"
												@click="
													removeArticleSelectedAccessoriesList(
														key
													)
												"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														clip-rule="evenodd"
													/>
												</svg>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="w-full border-bottom mt-3 mb-20px"
					v-on:click="openArticleVariationInformation"
				>
					<i
						class="lnr-chevron-down font-semibold ml-1 icon-size"
						v-if="articleVariationInformation === false"
					></i>
					<i
						class="lnr-chevron-up font-semibold ml-1 icon-size"
						v-else
					></i
					><span class="ml-2 font-semibold text-base">{{
						$t("articleVariations")
					}}</span>
				</div>
				<div
					class="row form-row"
					v-if="articleVariationInformation === true"
				>
					<div class="col-md-12">
						<div class="form-group w-full mt-2">
							<div
								class="text-sm text-gray-600 w-full mb-2 border-bottom"
							>
								<span>{{ $t("variations") }}</span>
								<span class="float-right"
									><i
										class="lnr-chevron-down"
										@click="addVariationToggle = true"
										v-if="addVariationToggle === false"
									></i
									><i
										class="lnr-chevron-up"
										@click="addVariationToggle = false"
										v-else
									></i
								></span>
							</div>

							<div
								class="form-group unset-margin mt-2"
								v-if="addVariationToggle === true"
							>
								<div>
									<input
										v-model="articleVariationSearchName"
										type="text"
										@input="searchVariationProduct"
										@focus="
											openArticleVariationDropdown = true
										"
										:placeholder="$t('searchArticles')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
								<div
									class="absolute inset-0 z-40"
									@click="
										openArticleVariationDropdown = false
									"
									v-if="openArticleVariationDropdown"
								></div>
								<div
									style="z-index: 99999"
									class="w-1/2 absolute bg-white shadow border rounded mt-1"
									v-if="
										openArticleVariationDropdown &&
										filteredVariationArticle.length > 0
									"
								>
									<ul>
										<li
											class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="item in filteredVariationArticle.slice(
												0,
												7
											)"
											:key="item.id"
											@click="
												addArticleVariationToList(item)
											"
										>
											{{ item.article_number }} -
											{{ item.description }}
										</li>
									</ul>
								</div>
							</div>
							<div class="row" v-if="addVariationToggle === true">
								<div
									class="col-md-6 mb-1 mt-2"
									v-for="(
										article, key
									) in selectedVariationArticle"
									:key="article.id"
								>
									<div
										class="flex justify-between bg-white border rounded-md shadow-sm p-1"
									>
										<div>{{ article.description }}</div>
										<div>
											<span
												class="cursor-pointer"
												@click="
													removeArticleVariationFromSelectedList(
														key
													)
												"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														clip-rule="evenodd"
													/>
												</svg>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="w-full border-bottom mt-3 mb-20px"
					v-on:click="openOtherInformation"
				>
					<i
						class="lnr-chevron-down font-semibold ml-1 icon-size"
						v-if="othersInformation === false"
					></i>
					<i
						class="lnr-chevron-up font-semibold ml-1 icon-size"
						v-else
					></i
					><span class="ml-2 font-semibold text-base">{{
						$t("othersInformation")
					}}</span>
				</div>
				<div class="row form-row" v-if="othersInformation === true">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label
										class="label-w-input col-form-label font-light whitespace-nowrap"
									>
										<input
											data-cy="post-invoice"
											type="checkbox"
											id="postInvoice"
											v-model="
												articleObjData.allow_custom_row_text
											"
											class="checkbox-height h-4 w-4"
										/>
										<span class="ml-2"
											>{{ $t("allowCustomText") }}
											<span
												class="help-tooltip cursor-pointer"
												:title="
													$t(
														'allowCustomTextHelpText'
													)
												"
											></span
										></span>
									</label>
								</div>
							</div>
							<div
								class="col-md-6"
								v-if="
									articleObjData.allow_custom_row_text ===
									true
								"
							>
								<div class="form-group">
									<label class="font-semibold">{{
										$t("articleCustomTextTitle")
									}}</label>
									<input
										type="text"
										min="0"
										v-model="
											articleObjData.custom_row_text_title
										"
										class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
										style="
											outline: currentcolor none medium;
										"
										required
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="row mb-2 mt-2">
							<div class="col-md-6">
								<div class="form-group">
									<el-switch
										v-model="showMinimumQty"
										:active-text="$t('enableMinimumQty')"
									>
									</el-switch>
									<div
										class="form-group mt-2"
										v-if="showMinimumQty === true"
									>
										<label class="font-semibold">{{
											$t("minimumOrderQty")
										}}</label>
										<input
											type="number"
											min="0"
											v-model="
												articleObjData.minimum_order_quantity
											"
											class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
											style="
												outline: currentcolor none
													medium;
											"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<el-switch
										v-model="
											articleObjData.is_paired_product
										"
										:active-text="$t('productInUnit')"
									>
									</el-switch>
									<div
										class="form-group mt-2"
										v-if="
											articleObjData.is_paired_product ===
											true
										"
									>
										<label class="font-semibold">{{
											$t("productPackageText")
										}}</label>
										<input
											type="number"
											min="0"
											v-model="
												articleObjData.product_package_bundle_number
											"
											class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
											style="
												outline: currentcolor none
													medium;
											"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="row mt-2">
							<div class="col-md-4">
								<div class="form-group">
									<label
										for="postInvoice"
										class="label-w-input col-form-label font-light whitespace-nowrap"
									>
										<input
											data-cy="has_customer_resale_price"
											type="checkbox"
											id="has_customer_resale_price"
											v-model="
												articleObjData.has_customer_resale_price
											"
											class="checkbox-height h-4 w-4"
										/>
										<span class="ml-2"
											>{{
												$t("showCustomerResalePrice")
											}}
											<span
												class="help-tooltip cursor-pointer"
												:title="
													$t(
														'showCustomerResalePrice'
													)
												"
											></span
										></span>
									</label>
								</div>
							</div>
							<div
								class="col-md-4"
								v-if="
									articleObjData.has_customer_resale_price ===
									true
								"
							>
								<div class="form-group">
									<label for="specification">{{
										$t("consumer_resale_field_title")
									}}</label>
									<input
										type="text"
										v-model="
											articleObjData.consumer_resale_field_title
										"
										class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
										style="
											outline: currentcolor none medium;
										"
									/>
								</div>
							</div>
							<div
								class="col-md-4"
								v-if="
									articleObjData.has_customer_resale_price ===
									true
								"
							>
								<div class="form-group">
									<label for="specification">{{
										$t("consumer_resale_price")
									}}</label>
									<input
										type="number"
										min="0"
										v-model="
											articleObjData.consumer_resale_amount
										"
										class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
										style="
											outline: currentcolor none medium;
										"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label
								>{{ $t("articlePdf") }}
								<a
									:href="articleObjData.product_pdf"
									class="text-blue ml-2"
									target="_blank"
									v-if="articleObjData.product_pdf"
									>pdf</a
								></label
							>
							<el-upload
								class="upload-pdf w-1/2"
								action="https://jsonplaceholder.typicode.com/posts/"
								:on-preview="handlePreview"
								:on-remove="handleRemove"
								:before-remove="beforeRemove"
								:limit="1"
								:auto-upload="false"
								:on-exceed="handleExceed"
								:on-change="handleChange"
								accept=".pdf"
								:file-list="pdfList"
							>
								<el-button size="small" type="primary">{{
									$t("clickUploadPdf")
								}}</el-button>
								<div slot="tip" class="el-upload__tip">
									{{ $t("pdfSizeText") }}
								</div>
							</el-upload>
						</div>
					</div>
				</div>
				<div class="flex justify-center">
					<button
						v-on:click="updateArticleDataModal"
						class="w-full text-white px-3 w-full md:w-1/4 bg-green-500 py-2 rounded-md whitespace-nowrap md:mt-7"
					>
						<span v-if="updating" class="flex justify-center">
							<svg
								class="animate-spin h-6 w-6 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									class="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									stroke-width="4"
								></circle>
								<path
									class="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						</span>
						<span class="text-xs" v-else>
							{{ $t("save") }}
						</span>
					</button>
				</div>
			</div>
		</div>
		<div
			class="flex flex-col bg-white p-3 mb-4"
			style="display: none !important"
		>
			<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
				<div class="flex flex-col">
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("name")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="text"
								v-model="articleObjData.description"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("articleNumber")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="text"
								v-model="articleObjData.article_number"
								disabled
								readonly
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("deliveryTime")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="text"
								v-model="articleObjData.delivery_time"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
							<small>{{ $t("estimatedDeliveryDay") }}</small>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("quantity")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="number"
								min="0"
								v-model="articleObjData.quantity_in_stock"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("articleVideoURL")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="text"
								v-model="articleObjData.product_video_url"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("purchase_price")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="number"
								min="0"
								v-model="articleObjData.purchase_price"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("salesPrice")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="number"
								min="0"
								v-model="articleObjData.sales_price"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("discountedValue")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="number"
								v-model="articleObjData.discount_value"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("campaignScheduleDate")
						}}</span>
						<div class="form-group">
							<el-date-picker
								style="
									width: 96% !important;
									height: 36px !important;
								"
								size="small"
								v-model="dateRangeFilter"
								type="daterange"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								range-separator="|"
							>
							</el-date-picker>
						</div>
					</div>
					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/3 mb-2">{{
							$t("minimumOrderAmount")
						}}</span>
						<div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
							<input
								type="number"
								v-model="articleObjData.minimum_order_amount"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
							/>
						</div>
					</div>

					<div
						class="flex flex-col md:flex-row md:justify-between md:items-center w-full"
					>
						<span class="text-sm text-gray-600 w-1/2 mb-2">{{
							$t("unittypes")
						}}</span>
						<div class="form-group w-full md:w-2/4 pr-3 md:pr-0">
							<el-select
								v-model="unit_type"
								filterable
								:placeholder="`${$t('search')}`"
							>
								<el-option
									v-for="item in unitTypeList"
									:key="item.id"
									:label="`${item.title} - ${item.unit}`"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="flex flex-col">
					<div class="form-group">
						<label for="specification" class="font-semibold">{{
							$t("description")
						}}</label>
						<textarea
							type="text"
							cols="30"
							rows="10"
							v-model="articleObjData.product_long_description"
							class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
						></textarea>
					</div>
					<div class="form-group">
						<label
							class="label-w-input col-form-label font-light whitespace-nowrap"
						>
							<input
								data-cy="post-invoice"
								type="checkbox"
								id="postInvoice"
								v-model="articleObjData.allow_custom_row_text"
								class="checkbox-height h-4 w-4"
							/>
							<span class="ml-2"
								>{{ $t("allowCustomText") }}
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('allowCustomTextHelpText')"
								></span
							></span>
						</label>
					</div>
					<div
						class="form-group"
						v-if="articleObjData.allow_custom_row_text === true"
					>
						<label for="specification" class="font-semibold">{{
							$t("articleCustomTextTitle")
						}}</label>
						<input
							type="text"
							min="0"
							v-model="articleObjData.custom_row_text_title"
							class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
							style="outline: currentcolor none medium"
							required
						/>
					</div>
					<div class="form-group">
						<el-switch
							v-model="showMinimumQty"
							:active-text="$t('enableMinimumQty')"
						>
						</el-switch>
						<div
							class="form-group mt-2"
							v-if="showMinimumQty === true"
						>
							<label class="font-semibold">{{
								$t("minimumOrderQty")
							}}</label>
							<input
								type="number"
								min="0"
								v-model="articleObjData.minimum_order_quantity"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
							/>
						</div>
					</div>
					<div class="form-group">
						<el-switch
							v-model="articleObjData.is_paired_product"
							:active-text="$t('productInUnit')"
						>
						</el-switch>
						<div
							class="form-group mt-2"
							v-if="articleObjData.is_paired_product === true"
						>
							<label class="font-semibold">{{
								$t("productPackageText")
							}}</label>
							<input
								type="number"
								min="0"
								v-model="
									articleObjData.product_package_bundle_number
								"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
							/>
						</div>
					</div>
					<div class="form-group">
						<label
							for="postInvoice"
							class="label-w-input col-form-label font-light whitespace-nowrap"
						>
							<input
								data-cy="has_customer_resale_price"
								type="checkbox"
								id="has_customer_resale_price"
								v-model="
									articleObjData.has_customer_resale_price
								"
								class="checkbox-height h-4 w-4"
							/>
							<span class="ml-2"
								>{{ $t("showCustomerResalePrice") }}
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('showCustomerResalePrice')"
								></span
							></span>
						</label>
					</div>
					<div
						class="form-group"
						v-if="articleObjData.has_customer_resale_price === true"
					>
						<label for="specification">{{
							$t("consumer_resale_field_title")
						}}</label>
						<input
							type="text"
							v-model="articleObjData.consumer_resale_field_title"
							class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
							style="outline: currentcolor none medium"
						/>
					</div>
					<div
						class="form-group"
						v-if="articleObjData.has_customer_resale_price === true"
					>
						<label for="specification">{{
							$t("consumer_resale_price")
						}}</label>
						<input
							type="number"
							min="0"
							v-model="articleObjData.consumer_resale_amount"
							class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
							style="outline: currentcolor none medium"
						/>
					</div>
					<div class="form-group">
						<label
							>{{ $t("articlePdf") }}
							<a
								:href="articleObjData.product_pdf"
								class="text-blue ml-2"
								target="_blank"
								v-if="articleObjData.product_pdf"
								>pdf</a
							></label
						>
						<el-upload
							class="upload-pdf"
							action="https://jsonplaceholder.typicode.com/posts/"
							:on-preview="handlePreview"
							:on-remove="handleRemove"
							:before-remove="beforeRemove"
							:limit="1"
							:auto-upload="false"
							:on-exceed="handleExceed"
							:on-change="handleChange"
							accept=".pdf"
							:file-list="pdfList"
						>
							<el-button size="small" type="primary">{{
								$t("clickUploadPdf")
							}}</el-button>
							<div slot="tip" class="el-upload__tip">
								{{ $t("pdfSizeText") }}
							</div>
						</el-upload>
					</div>
				</div>
			</div>
			<div class="flex justify-center">
				<button
					v-on:click="updateArticleDataModal"
					class="w-full text-white px-3 w-full md:w-1/4 bg-green-500 py-2 rounded-md whitespace-nowrap md:mt-7"
				>
					<span v-if="updating" class="flex justify-center">
						<svg
							class="animate-spin h-6 w-6 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							></circle>
							<path
								class="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
					</span>
					<span class="text-xs" v-else>
						{{ $t("save") }}
					</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Vue, { computed, reactive } from "vue";
import { DatePicker } from "element-ui";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import debounce from "lodash.debounce";

Vue.use(DatePicker);

export default {
	name: "articleEditComponent",
	components: {
		VueEditor,
	},
	props: {
		article_id: {
			required: true,
		},
		articleSpecificationObj: {
			type: Object,
		},
	},
	data: function () {
		const discount_types = reactive({
			types: computed(() => {
				return [
					{
						label: this.$t("discountAmount"),
						id: "AMOUNT",
					},
					{
						label: this.$t("discountPercentage"),
						id: "PERCENT",
					},
				];
			}),
		});

		return {
			addRecommendToggle: false,
			addAccessoriesToggle: false,
			addVariationToggle: false,
			articleDescription: false,
			stockInformation: false,
			priceInformation: false,
			recommendationInformation: false,
			articleVariationInformation: false,
			othersInformation: false,
			articleObjData: {
				product_video_url: "",
				product_long_description: "",
				discount_type: "",
				discount_value: "",
			},
			pdfList: [],
			articleCategoryValue: [],
			dateRangeFilter: [],
			selectedProductRecommend: [],
			selectedProductAccessories: [],
			selectedProductVariations: [],
			productArticle: [],
			accessoriesProduct: [],
			unitTypeList: [],
			unit_type: "",
			updating: false,
			loading: true,
			value: true,
			showMinimumQty: false,
			customToolbar: [
				["bold", "italic", "underline"],
				[{ list: "ordered" }, { list: "bullet" }],
				["code-block"],
			],
			openRecommendedArticleDropdown: false,
			articleSearchName: "",
			filteredArticle: [],
			selectedArticle: [],
			selectedVariationArticle: [],
			filteredVariationArticle: [],
			openRecommendedAccessoriesDropdown: false,
			openArticleRecommendDropdown: false,
			openArticleVariationDropdown: false,
			openArticleAccessoriesDropdown: false,
			articleAccessoriesSearchName: "",
			articleRecommendSearchName: "",
			articleVariationSearchName: "",
			filteredAccessories: [],
			selectedAccessories: [],
			articlesList: [],
			DISCOUNT_TYPES: discount_types.types,
		};
	},
	computed: {
		...mapGetters({
			GET_PRODUCT_CATEGORY: "portal/GET_PRODUCT_CATEGORY",
			GET_ARTICLES: "portal/GET_ARTICLES",
			GET_ALL_ARTICLES: "portal/GET_ALL_ARTICLES",
		}),
	},
	mounted() {
		this.articleObjData.product_long_description = this.cleanData(
			this.articleSpecificationObj.product_long_description
		);
		this.articleObjData.product_video_url = this.cleanData(
			this.articleSpecificationObj.product_video_url
		);
		this.articleObjData = this.articleSpecificationObj;
		if (this.articleObjData.unit_type) {
			this.unit_type = this.articleObjData.unit_type.id;
		}
		if (parseInt(this.articleObjData.minimum_order_quantity) > 0) {
			this.showMinimumQty = true;
		} else {
			this.showMinimumQty = false;
		}

		this.getArticle();
		this.getUnitType();

		// this.filterArticle()
	},
	methods: {
		searchRecommendProduct: debounce(function () {
			this.openArticleRecommendDropdown = true;
			this.$store
				.dispatch("portal/getFullArticles", {
					url: `?limit=20&search=${this.articleRecommendSearchName}`,
				})
				.then((resp) => {
					this.filteredArticle = resp.data.data;
				});
		}, 200),
		searchVariationProduct: debounce(function () {
			this.openArticleVariationDropdown = true;
			this.$store
				.dispatch("portal/getFullArticles", {
					url: `?limit=20&search=${this.articleVariationSearchName}`,
				})
				.then((resp) => {
					this.filteredVariationArticle = resp.data.data;
				});
		}, 200),
		searchAccessoriesProduct: debounce(function () {
			this.openArticleAccessoriesDropdown = true;
			this.$store
				.dispatch("portal/getFullArticles", {
					url: `?limit=20&search=${this.articleAccessoriesSearchName}`,
				})
				.then((resp) => {
					this.filteredAccessories = resp.data.data;
				});
		}),
		addArticleToList: function (item) {
			let exist = this.selectedArticle.filter(
				(item_) => parseInt(item.id) === parseInt(item_.id)
			);
			if (exist.length < 1) {
				this.selectedArticle.push(item);
			}
			this.openArticleRecommendDropdown = false;
		},
		addArticleVariationToList: function (item) {
			let exist = this.selectedVariationArticle.filter(
				(item_) => parseInt(item.id) === parseInt(item_.id)
			);
			if (exist.length < 1) {
				this.selectedVariationArticle.push(item);
			}
			this.openArticleVariationDropdown = false;
		},
		addArticleAccessoriesToList: function (item) {
			let exist = this.selectedAccessories.filter(
				(item_) => parseInt(item.id) === parseInt(item_.id)
			);
			if (exist.length < 1) {
				this.selectedAccessories.push(item);
			}
			this.openArticleAccessoriesDropdown = false;
		},
		removeUserFromSelectedList: function (key) {
			this.selectedArticle.splice(key, 1);
		},
		removeArticleVariationFromSelectedList: function (key) {
			this.selectedVariationArticle.splice(key, 1);
		},
		removeArticleSelectedAccessoriesList: function (key) {
			this.selectedAccessories.splice(key, 1);
		},
		openArticleDescription: function () {
			this.articleDescription = !this.articleDescription;
		},
		openStockInformation: function () {
			this.stockInformation = !this.stockInformation;
		},
		openPriceInformation: function () {
			this.priceInformation = !this.priceInformation;
		},
		openRecommendationInformation: function () {
			this.recommendationInformation = !this.recommendationInformation;
		},
		openArticleVariationInformation: function () {
			this.articleVariationInformation =
				!this.articleVariationInformation;
		},
		openOtherInformation: function () {
			this.othersInformation = !this.othersInformation;
		},
		getProductCategory() {
			this.$store.dispatch("portal/getProductCategory");
		},
		cleanData: function (name) {
			if (["nan"].includes(name)) {
				return "";
			} else {
				return name;
			}
		},
		getArticle() {
			this.loading = true;
			this.selectedProductAccessories = [];
			this.selectedProductRecommend = [];
			this.$store
				.dispatch("portal/getSingleArticle", {
					url: `/${this.article_id}/`,
				})
				.then((resp) => {
					// this.articleCategoryValue = resp.data.category.filter(item => item !== null)
					this.dateRangeFilter = [];
					if (
						resp.data.scheduled_campaign_start_date &&
						resp.data.scheduled_campaign_end_date
					) {
						this.dateRangeFilter = [
							resp.data.scheduled_campaign_start_date,
							resp.data.scheduled_campaign_end_date,
						];
					}
					this.articleObjData = resp.data;
					this.getArticleAccessories();
					this.getArticleRecommendation();
					this.getArticleVariations();
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},

		updateArticleDataModal() {
			if (
				this.articleObjData.description === "" ||
				this.articleObjData.description === null
			) {
				return this.$services.helpers.notification(
					this.$t("articleDescriptionError"),
					"error",
					this
				);
			}
			if (
				this.articleObjData.sales_price === "" ||
				this.articleObjData.sales_price === null
			) {
				return this.$services.helpers.notification(
					this.$t("articleSalesPriceError"),
					"error",
					this
				);
			}
			if (this.articleObjData.purchase_price !== "") {
				if (
					parseFloat(this.articleObjData.purchase_price) < 0 ||
					this.articleObjData.purchase_price === ""
				) {
					return this.$services.helpers.notification(
						this.$t("enterValidPrice"),
						"error",
						this
					);
				}
			}
			if (
				this.articleObjData.product_video_url &&
				this.articleObjData.product_video_url !== "nan"
			) {
				if (!this.isURL(this.articleObjData.product_video_url)) {
					return this.$services.helpers.notification(
						this.$t("invalidProductURL"),
						"error",
						this
					);
				}
			}

			this.updating = true;
			let category = this.articleCategoryValue.filter(
				(item) => item !== null
			);

			const payload = {
				article_id: this.articleObjData.id,
				product_name: this.articleObjData.description,
				product_video_url: this.articleObjData.product_video_url,
				description: this.articleObjData.description,
				article_number: this.articleObjData.article_number,
				sales_price: Number(this.articleObjData.sales_price),
				discounted_price: this.articleObjData.discounted_price,
				delivery_time: this.articleObjData.delivery_time,
				allow_custom_row_text:
					this.articleObjData.allow_custom_row_text,
				custom_row_text_title:
					this.articleObjData.custom_row_text_title,
				minimum_order_amount:
					this.articleObjData.minimum_order_amount !== ""
						? this.articleObjData.minimum_order_amount
						: 0,
				minimum_order_quantity:
					this.articleObjData.minimum_order_quantity !== ""
						? this.articleObjData.minimum_order_quantity
						: 0,
				consumer_resale_field_title:
					this.articleObjData.consumer_resale_field_title,
				consumer_resale_amount:
					this.articleObjData.consumer_resale_amount,
				is_paired_product: this.articleObjData.is_paired_product,
				product_package_bundle_number:
					this.articleObjData.product_package_bundle_number,
				category: category,
				quantity_in_stock: parseInt(
					this.articleObjData.quantity_in_stock
				),
				price: parseFloat(this.articleObjData.price),
				purchase_price: parseFloat(this.articleObjData.purchase_price),
				unit_type: this.unit_type,
				discount_value: parseFloat(this.articleObjData?.discount_value),
				discount_type: this.articleObjData?.discount_type,
			};
			if (this.showMinimumQty === false) {
				payload["minimum_order_quantity"] = 0;
			} else {
				if (this.articleObjData.minimum_order_quantity <= 1) {
					return this.$services.helpers.notification(
						this.$t("minimumOrderQtyError"),
						"error",
						this
					);
				}
			}
			if (this.dateRangeFilter.length > 0) {
				payload["scheduled_campaign_start_date"] =
					this.dateRangeFilter[0];
				payload["scheduled_campaign_end_date"] =
					this.dateRangeFilter[1];
			}
			if (
				this.articleObjData.product_long_description !== "" &&
				this.articleObjData.product_long_description !== null
			) {
				payload.product_long_description =
					this.articleObjData.product_long_description;
			}
			if (this.articleObjData.has_customer_resale_price === false) {
				payload.consumer_resale_field_title = "";
				payload.consumer_resale_amount = 0;
			}
			this.selectedProductRecommend = [];
			this.selectedProductAccessories = [];
			this.selectedProductVariations = [];
			this.selectedArticle.map((item) => {
				this.selectedProductRecommend.push(item.id);
			});
			this.selectedAccessories.map((item) => {
				this.selectedProductAccessories.push(item.id);
			});
			this.selectedVariationArticle.map((item) => {
				this.selectedProductVariations.push(item.id);
			});
			
			let data = {};

			const isValidNumber = (value) => {
				if (typeof value === "number") {
					return value > 0;
				}
				return true;
			};
			for (const [key, value] of Object.entries(payload)) {
				if (
					value !== "" &&
					value !== null &&
					value !== undefined &&
					isValidNumber(value)
				) {
					data[key] = value;
				}
			}
			this.$store
				.dispatch("portal/updateArticle", data)
				.then((_) => {
					this.updating = false;
					this.$services.helpers.notification(
						this.$t("articleUpdateSuccess"),
						"success",
						this
					);
					// this.modalArticleEdit = false
					const indexObj = this.GET_ARTICLES.data.findIndex(
						(item) => item.id === payload.article_id
					);
					if (indexObj > -1) {
						const obj = this.GET_ARTICLES.data[indexObj];
						obj.description = payload.description;
						obj.product_video_url = payload.product_video_url;
						obj.price = payload.price;
						obj.purchase_price = payload.purchase_price;
					}

					if (this.pdfList.length > 0) {
						let formdata = new FormData();
						formdata.append("article_id", this.articleObjData.id);
						formdata.append(
							"product_name",
							this.articleObjData.product_name
						);
						formdata.append(
							"article_number",
							this.articleObjData.article_number
						);
						formdata.append(
							"quantity_in_stock",
							parseInt(this.articleObjData.quantity_in_stock)
						);
						formdata.append(
							"purchase_price",
							parseFloat(this.articleObjData.purchase_price)
						);
						formdata.append(
							"sales_price",
							this.articleObjData.sales_price
						);
						formdata.append("product_pdf", this.pdfList[0].raw);
						this.$store.dispatch("portal/updateArticlePdf", {
							id: this.articleObjData.id,
							data: formdata,
						});
					}
					this.$store.dispatch("portal/recommendArticle", {
						id: this.articleObjData.id,
						data: {
							recommended_article: this.selectedProductRecommend,
						},
					});
					this.$store.dispatch("portal/accessoriesArticle", {
						id: this.articleObjData.id,
						data: {
							article_accessories:
								this.selectedProductAccessories,
						},
					});
					this.$store.dispatch("portal/setArticleVariations", {
						id: this.articleObjData.id,
						data: {
							article_variations: this.selectedProductVariations,
						},
					});
					// this.articleEditObj = {}
				})
				.catch((err) => {
					this.updating = false;
					window.Bus.$emit("output-error", err);
					// if (err.response.status === 400) {
					// 	this.$services.helpers.notification(err.response.data.error, 'error', this)
					// }
					// if (err.response.status === 500) {
					// 	this.$services.helpers.notification(this.$t('serverError'), 'error', this)
					// }
				});
		},
		isURL(str) {
			var regex =
				/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/;
			var pattern = new RegExp(regex);
			return pattern.test(str);
		},
		productFilter(search = null) {
			let url = `?limit=300`;
			if (search !== null) {
				url += `&search=${search}`;
			}

			this.$store
				.dispatch("portal/getFilterArticles", { url: url })
				.then((resp) => {
					this.productArticle = resp.data.data;
				});
		},
		productAccessoriesFilter(search = null) {
			let url = `?limit=300`;
			if (search !== null) {
				url += `&search=${search}`;
			}
			this.$store
				.dispatch("portal/getFilterArticles", { url: url })
				.then((resp) => {
					this.accessoriesProduct = resp.data.data;
				});
		},
		getArticleAccessories() {
			this.$store
				.dispatch("portal/getArticleAddon", {
					id: this.articleObjData.id,
					addon_type: "accessories",
				})
				.then((resp) => {
					this.selectedAccessories = resp.data.data;
				});
		},
		getArticleRecommendation() {
			this.$store
				.dispatch("portal/getArticleAddon", {
					id: this.articleObjData.id,
					addon_type: "recommended",
				})
				.then((resp) => {
					this.selectedArticle = resp.data.data;
				});
		},
		getArticleVariations() {
			this.$store
				.dispatch("portal/getArticleAddon", {
					id: this.articleObjData.id,
					addon_type: "variations",
				})
				.then((resp) => {
					this.selectedVariationArticle = resp.data.data;
				});
		},
		handlePreview: function () {},
		handleChange(file, fileList) {
			let size = Math.ceil(file.size / 1024);
			if (size <= 10000) {
				this.pdfList.push(file);
			} else {
				this.$services.helpers.notification(
					this.$t("fileToLarge"),
					"error",
					this
				);
			}
		},
		handleRemove: function () {
			this.pdfList.pop();
		},
		beforeRemove: function () {},
		handleExceed: function () {
			this.$services.helpers.notification(
				this.$t("fileExceedLimit"),
				"error",
				this
			);
		},
		getUnitType: function () {
			this.$store
				.dispatch("portal/fetchUnits", { data: "?limit=500" })
				.then((resp) => {
					this.unitTypeList = resp.data.results;
				})
				.catch((err) => {
					window.Bus.$emit("output-error", err);
				});
		},
	},
};
</script>

<style scoped>
.mb-20px {
	margin-bottom: 20px !important;
}
</style>
