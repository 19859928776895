<template>
	<div
		class="app-container bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar"
		id="app-root-container"
	>
		<NavbarDash />
		<div class="app-main single-main">
			<div
				class="absolute bg-red-30 inset-0 z-10"
				style="z-index: 9999999999"
				@click="closeCartDiv"
				v-if="showFullCover"
			></div>
			<SidebarDash />
			<div class="app-main__outer">
				<router-view />
				<FooterDash />
			</div>
		</div>
		<ModalContainer
			v-if="modalProductView"
			width="w-11/12 md:w-1/2"
			:title="$t('articleDetail')"
			@handleClose="modalProductView = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-6 mb-3">
						<Flickity
							ref="main"
							:options="flickityOptions"
							class="carousel-main mb-3"
							style="height: 200px"
						>
							<div
								v-for="(img, index) in productObject.image"
								:key="index"
								class="carousel-cell w-100"
								style="height: 200px"
							>
								<img :src="img.image_url" alt="Left image" />
							</div>
						</Flickity>

						<Flickity
							ref="nav"
							:options="navImgOtions"
							class="carousel-nav"
							style="height: 70px"
						>
							<div
								v-for="img in productObject.image"
								:key="img.id"
								class="carousel-cel w-25"
								style="height: 70px"
							>
								<img :src="img.image_url" alt="Left image" />
							</div>
						</Flickity>
					</div>
					<div class="col-md-6">
						<h3 class="text-4xl font-semibold">
							{{ productObject.description }}
						</h3>
						<span class="text-lg"
							>{{ $t("quantity") }}:
							{{ productObject.quantity_in_stock }}</span
						>
						<br />
						<span class="text-lg"
							>{{ $t("price") }}:
							<span class="text-green-500"
								>{{ productObject.price }} :- </span
							></span
						>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalAddWebshop"
			width="md:w-1/3 w-11/12"
			:title="$t('webshop')"
			@handleClose="modalAddWebshop = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<div class="form-group">
							<label
								for="desc"
								class="col-form-label font-bold whitespace-nowrap text-xs"
								>Add Webshop for</label
							>
							<div>
								<el-select
									v-model="webshopSelect"
									filterable
									:placeholder="$t('select')"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
								>
									<el-option
										v-for="(
											provider, key
										) in GET_WEBSHOP_PROVIDER"
										v-bind:key="key"
										:label="provider.name"
										:value="provider.name"
									>
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="form-group">
							<button
								@click="redirectAddWebshop"
								class="bg-green-500 px-3 rounded shadow-sm no-deco text-white py-1 text-xl"
								v-if="webshopSelect"
							>
								{{ $t("continue") }}
							</button>
							<!-- <button @click="redirectAddWebshop" class="bg-green-500 px-3 rounded shadow-sm no-deco text-white py-1 text-xl" v-if="webshopSelect === 'shopify'">{{ $t('continue') }}</button> -->
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalBankContainer
			v-if="modalBankId"
			width="md:w-3/12 w-11/12"
			:backdropOff="true"
			@handleClose="modalBankId = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<div class="flex justify-center h-12">
							<img
								src="./../../assets/images/bankid/bankid.png"
								alt=""
								class="img-fluid"
							/>
						</div>
						<div class="text-gray-500 text-center mt-1">
							Ange ditt personnummer
						</div>
						<form @submit.prevent="addBankMenu">
							<div
								class="form-group mt-3"
								style="
									margin-bottom: 0.5rem !important;
									margin-top: 0.8rem !important;
								"
							>
								<!-- <div class="text-gray-400 text-center">{{ $t('validBankIDFormat') }}: ÅÅÅÅMMDD-XXXX</div> -->
								<div class="text-gray-400 text-center">
									ÅÅÅÅMMDD-XXXX
								</div>
								<div class="flex justify-center">
									<input
										v-model="bankForm.number"
										type="text"
										class="flex bank-id-form justify-between align-items-center space-x-4 focus:outline-none focus:bordr-blue-600 border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="flex justify-center">
								<button
									type="submit"
									class="bg-blue-500 text-base rounded text-white px-5 py-2 font-bold w-full"
								>
									{{ $t("proceed") }}
								</button>
							</div>
						</form>
						<div class="flex justify-center mt-12">
							<button
								@click="modalBankId = false"
								class="bg-white text-base rounded text-gray-400 px-5 py-2 w-full"
							>
								{{ $t("cancelPurchase") }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalBankContainer>

		<ModalContainer
			v-if="maxLimitModal"
			width="md:w-1/3 w-11/12"
			@handleClose="maxLimitModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12 text-red-400 font-semibold">
						{{ $t("maxLimitErrorMessage") }}
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalUsers"
			width="md:w-1/3 w-11/12"
			:title="$t('updateShopUsers')"
			@handleClose="modalUsers = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateUser">
							<div>
								<div class="form-group unset-margin">
									<label
										for="name"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("firstName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="updateUsers.first_name"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="email"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("lastName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="updateUsers.last_name"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="number"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											type="email"
											v-model="updateUsers.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									:disabled="addingUsers"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
								<!-- <button type="submit" :disabled="addingUsers" class="btn btn-custom btn-wide cursor-not-allowed ">
									<span v-if="addingUsers" class="flex align-items-center space-x-3">
										<svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
										{{ $t('updating') }}
									</span>
									<span v-else>{{ $t('update') }}</span>
								</button> -->
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalAddUsers"
			width="md:w-1/3 w-11/12"
			:title="$t('addUser')"
			@handleClose="modalAddUsers = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="modalAddUser">
							<div>
								<div class="form-group unset-margin">
									<label
										for="name"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("firstName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="addUserForm.firstname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="email"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("lastName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="addUserForm.lastname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="number"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											type="email"
											v-model="addUserForm.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									:disabled="addingUsers"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="deliveryAddressModal"
			width="md:w-1/3 w-11/12"
			:title="$t('addUser')"
			@handleClose="deliveryAddressModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="modalAddUser">
							<div>
								<div class="form-group unset-margin">
									<label
										for="name"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("firstName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="addUserForm.firstname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="email"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("lastName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="addUserForm.lastname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="number"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											type="email"
											v-model="addUserForm.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									:disabled="addingUsers"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalAddDeliveryAddress"
			width="md:w-1/3 w-11/12"
			:title="$t('addDeliveryAddress')"
			@handleClose="modalAddDeliveryAddress = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="createDeliveryAddress">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("locationName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.location_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("streetAddress") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.street_address"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("zipCode") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.zip_code"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("country") }}</label
								>
								<div>
									<el-select
										v-model="deliveryAddress.country"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(item, key) in countries"
											v-bind:key="key"
											:label="item.name"
											:value="item.key"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("city") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.city"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("phoneNumber") }}</label
								>
								<div>
									<input
										type="number"
										v-model="deliveryAddress.phone_number"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("selectCompany") }}</label
								>
								<div>
									<el-select
										v-model="deliveryAddress.company_id"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(
												item, key
											) in GET_CONNECTED_SHOP_LIST"
											v-bind:key="key"
											:label="item.name"
											:value="item.id"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group mb-2 mt-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="is_default_address"
										type="checkbox"
										v-model="
											deliveryAddress.is_default_address
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{ $t("is_default_address") }}
										<span
											class="help-tooltip cursor-pointer"
											:title="$t('is_default_address')"
										></span
									></span>
								</label>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("add") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditDeliveryAddress"
			width="md:w-1/3 w-11/12"
			:title="$t('editDeliveryAddress')"
			@handleClose="modalEditDeliveryAddress = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form
							v-on:submit.prevent="updateCustomerDeliveryAddress"
						>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("locationName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.location_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("streetAddress") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.street_address"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("zipCode") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.zip_code"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("country") }}</label
								>
								<div>
									<el-select
										v-model="deliveryAddress.country"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(item, key) in countries"
											v-bind:key="key"
											:label="item.name"
											:value="item.key"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("city") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.city"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("phoneNumber") }}</label
								>
								<div>
									<input
										type="number"
										v-model="deliveryAddress.phone_number"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group mb-2 mt-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="is_default_address"
										type="checkbox"
										v-model="
											deliveryAddress.is_default_address
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{ $t("is_default_address") }}
										<span
											class="help-tooltip cursor-pointer"
											:title="$t('is_default_address')"
										></span
									></span>
								</label>
							</div>
							<div class="form-group">
								<button
									type="submit"
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center cursor-pointer space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalAddArticleCustomText"
			width="md:w-1/3 w-11/12"
			:title="$t('additionProductItem')"
			@handleClose="addExtraItem"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="addExtraItem($event)">
							<div>
								<div
									class="form-group unset-margin"
									v-for="(item, key) in extraItemList"
									:key="key"
								>
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{
											orderObj.custom_row_text_title
										}}</label
									>
									<div>
										<input
											type="text"
											v-model="item.item"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									:disabled="addingUsers"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalDisabledSetting"
			width="md:w-1/3 w-11/12"
			:title="$t('additionProductItem')"
			@handleClose="modalDisabledSetting = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<p>{{ $t("not_allowed_setting") }}</p>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalNote
			v-if="modalNote"
			width="md:w-1/4 w-11/12"
			:title="$t('note')"
			@handleClose="modalNote = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateNote">
							<div class="form-group unset-margin mb-10">
								<label
									for="number"
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("buyerNote") }}</label
								>
								<div>
									<textarea
										class="w-full focus:outline-none p-2 rounded"
										rows="4"
										:disabled="true"
										v-model="orderObj.message"
										>{{ orderObj.message }}</textarea
									>
								</div>
							</div>
							<div class="form-group unset-margin">
								<label
									for="number"
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("internalNote") }}</label
								>
								<div>
									<textarea
										class="w-full focus:outline-none p-2 rounded"
										rows="4"
										style="border: 1px solid #eaeaea"
										v-model="orderObj.buyer_note"
										>{{ orderObj.buyer_note }}</textarea
									>
								</div>
							</div>
							<div class="form-group unset-margin mb-2">
								<div class="mb-2">
									<el-switch
										v-model="orderObj.is_buyer_handle_note"
										:active-text="$t('tasksHandle')"
									>
									</el-switch>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("save") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalNote>

		<Notification />
		<FlashMessage />
		<el-drawer
			:visible.sync="onOpenCartDrawer"
			class="category-style"
			:size="`${cartSliderWidth}%`"
			:title="`${$t('youHave')} <b>${cartQty}</b> ${$t('itemsInCart')}`"
			custom-class="cart-slider-style"
			:with-header="false"
			:direction="direction"
		>
			<cart-component />
		</el-drawer>
		<v-tour
			name="dashboard"
			:steps="dashboardStep"
			:options="myOptions"
			:callbacks="dashboardTourCallback"
		></v-tour>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Flickity from "vue-flickity";
import "flickity-as-nav-for";
// import { v4 as uuidv4 } from 'uuid'
// import bus from './../../bus'
import NavbarDash from "@/components/CNavbar";
import SidebarDash from "@/components/CSidebar";
import FooterDash from "@/components/Footer";
import FlashMessage from "@/customComponent/FlashMessage";
import ModalContainer from "./../../customComponent/Modal";
import ModalNote from "./../../customComponent/NoteModal";
import ModalBankContainer from "./../../customComponent/BankModal";
import cartComponent from "./component/cartManagement/cartComponent";
import { saveCartItem, getCartItem } from "../../services/storage-window";
import moment from "moment";
import isScreen from "../../services/screen-helper/screenHelper";

export default {
	name: "main-root-dash",
	components: {
		Flickity,
		NavbarDash,
		SidebarDash,
		FooterDash,
		FlashMessage,
		ModalContainer,
		ModalBankContainer,
		ModalNote,
		cartComponent,
		Notification: () => import("./../../customComponent/NotificationAlert"),
	},
	data() {
		return {
			baseURL: window.location.origin,
			direction: "rtl",
			firstname: "",
			lastname: "",
			email: "",
			cartSliderWidth: 25,
			deliveryAddress: {
				location_name: "",
				street_address: "",
				zip_code: "",
				country: "",
				city: "",
				phone_number: "",
				customer_id: "",
				company_id: "",
				is_default_address: false,
			},
			countries: [],
			flickityOptions: {
				initialIndex: 3,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false,
				draggable: ">1",
				fullscreen: true,
			},
			navImgOtions: {
				asNavFor: ".carousel-main",
				freeScroll: false,
				contain: true,
				prevNextButtons: false,
				pageDots: false,
				wrapAround: true,
			},
			bankForm: {
				number: "",
			},
			// accountNumber : '',
			role: "Select role",
			webshopSelect: null,
			cateName: "",
			accountNumber: "",
			modalAddUsers: false,
			modalDisabledSetting: false,
			modal: false,
			modalAddArticleCustomText: false,
			modalNote: false,
			deliveryAddressModal: false,
			modalAddDeliveryAddress: false,
			modalEditDeliveryAddress: false,
			ccValue: "",
			formData: {
				id: null,
				email: "",
				category_id: "",
				customer_name: "",
				customer_number: "",
			},
			customerCategory: [],
			updateUsers: { first_name: "", last_name: "", email: "", id: null },
			modalInviteCustomer: false,
			modalUsers: false,
			creating: false,
			loadingUpload: false,
			adding: false,
			modalVisible: false,
			modalProductView: false,
			modalAddWebshop: false,
			addingUsers: false,
			userObject: null,
			userPhoto: {},
			showCrop: false,
			croppieImage: null,
			initializingCropping: false,
			croppingSuccess: false,
			showPreviewCropping: false,
			uploadingNow: false,
			updating: false,
			subId: null,
			costCenter: [],
			addUserForm: {
				firstname: "",
				lastname: "",
				email: "",
			},
			showFullCover: false,
			modalBankId: false,
			maxLimitModal: false,
			productObject: {},
			myOptions: {
				useKeyboardNavigation: false,
				// startTimeout : 1000,
				labels: {
					buttonNext: this.$t("nextTour"),
					buttonPrevious: this.$t("previousTour"),
					buttonSkip: this.$t("skipTour"),
					buttonStop: this.$t("finishedTour"),
				},
			},
			dashboardStep: [
				{
					target: ".v-step-0",
					content: this.$t("buyerDashboardTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-1",
					content: this.$t("buyerOrdersTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-2",
					content: this.$t("buyerInvoiceTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-3",
					content: this.$t("buyerUsersTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-4",
					content: this.$t("buyerIntegrationTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-5",
					content: this.$t("buyerIntegrationProductTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-6",
					content: this.$t("buyerSubscriptionTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-step-7",
					content: this.$t("buyerLogTourMenu"),
					params: {
						placement: "right",
					},
				},
			],
			dashboardTourCallback: {
				onSkip: this.completeAllTour,
				onFinish: this.completeAllTour,
			},
			orderObj: { message: "", buyer_note: "" },
			extraItemObj: {
				extraItem: "",
			},
			extraItemList: [],
			systemMode: "",
			errKeyName: {
				buyer_note: "Notes",
			},
			productObj: {},
			addingToCart: false,
			cartQty: 0,
		};
	},
	watch: {
		$route(to, from) {
			if (to.name !== from.name) {
				if (to.name !== "addshopify") {
					window.localStorage.removeItem("__shop__d");
				}
				if (to.name !== "addwoo") {
					window.localStorage.removeItem("__woo__d");
				}
				window.localStorage.removeItem("__shopify__data");
				window.localStorage.removeItem("checkonce");
				window.Bus.$emit("reset-dropdown");
			}
		
		},
		modal_photo: function (newVal, _) {
			if (newVal === true) {
				this.loadingUpload = true;
				setTimeout(() => {
					const fileElem = document.getElementById("upload-open");
					if (fileElem) {
						fileElem.click();
						this.loadingUpload = false;
					}
				}, 1000);
			}
		},
	},
	computed: {
		modal_invite: {
			get: function () {
				return this.MODAL_INVITE;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_INVITE_MODAL");
			},
		},
		modal_photo: {
			get: function () {
				return this.MODAL_PHOTO;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_PHOTO_MODAL");
			},
		},
		modal_util: {
			get: function () {
				return this.MODAL_UTIL;
			},
			set: function () {
				// this.accountantCom = []
				return this.$store.commit("utility/OPEN_MODAL_UTIL");
			},
		},
		onOpenCartDrawer: {
			get: function () {
				return this.GET_CART_DRAWER;
			},
			set: function () {
				if (this.GET_CART_DRAWER) {
					return this.$store.commit(
						"utility/UPDATE_CART_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/UPDATE_CART_DRAWER",
						true
					);
				}
			},
		},
		...mapGetters({
			CUSTOMER_TOKEN: "auth/CUSTOMER_TOKEN",
			GET_WEBSHOP_PROVIDER: "customer/GET_WEBSHOP_PROVIDER",
			GET_CONNECTED_SHOP_LIST: "customer/GET_CONNECTED_SHOP_LIST",
			CART_AMOUNT_INC_VAT: "customer/CART_AMOUNT_INC_VAT",
			MODAL_INVITE: "utility/MODAL_INVITE",
			MODAL_PHOTO: "utility/MODAL_PHOTO",
			MODAL_UTIL: "utility/MODAL_UTIL",
			GET_BUYER_TOUR: "customer/GET_BUYER_TOUR",
			GET_COMPANY_SETTING: "customer/GET_COMPANY_SETTINGS",
			CART_PRODUCT: "customer/CART_PRODUCT",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
			GET_CART_DRAWER: "utility/GET_CART_DRAWER",
		}),
	},
	mounted() {
		// this.getCostCenter()
		// this.getCustomerCategory()
		
		let self = this;
		this.resize();
		window.addEventListener("resize", function () {
			self.resize();
		});
		window.Bus.$on("sign-out", (_) => {
			if (this.$route.path !== "/") {
				this.$store.commit("utility/CHANGE_LOCALE", "se");
				this.$store.commit("auth/CLEAR_AUTH_CUS", null);
				// this.$router.push("/admin");
				this.$router.replace({ name: "login" });
			}
		});
		window.Bus.$on("show-cover", (e) => {
			if (e === true) {
				this.showFullCover = true;
			} else {
				this.showFullCover = false;
			}
		});
		window.Bus.$on("open-product", (e) => {
			this.productObject = e;
			this.modalProductView = true;
		});

		window.Bus.$on("open-add-webshop-modal", (_) => {
			this.modalAddWebshop = true;
		});

		window.Bus.$on("open-bank-id", (_) => {
			this.modalBankId = true;
		});

		window.Bus.$on("open-max-error-messag", (_) => {
			this.maxLimitModal = true;
		});

		window.Bus.$on("add-buyer-account", (_) => {
			this.modalAddUsers = true;
		});

		window.Bus.$on("delete-user", (e) => {
			this.deleteUser(e);
		});
		window.Bus.$on("update-users", (e) => {
			this.modalUsers = true;
			this.updateUsers = e;
		});

		window.Bus.$on("remove-integration", (e) => {
			this.deleteIntegration(e);
		});

		window.Bus.$on("open-modal-note", (e) => {
			this.orderObj = e;
			this.modalNote = true;
		});
		window.Bus.$on("open-custom-row-text", (e) => {
			this.productObj = e.data;
			this.extraItemObj.extraItem = "";
			this.extraItemList = [];

			let itemIndex = this.CART_PRODUCT.findIndex(
				(item) => item.id === parseInt(this.productObj.id)
			);
			if (itemIndex > -1) {
				if (this.CART_PRODUCT[itemIndex].hasOwnProperty("extra_item")) {
					this.CART_PRODUCT[itemIndex].extra_item.map((item) => {
						this.extraItemList.push(item);
					});
					for (let i = 1; i <= parseInt(e.qty); i++) {
						this.extraItemList.push({ item: "" });
					}
					this.CART_PRODUCT[itemIndex].extra_item =
						this.extraItemList;
					saveCartItem(this.CART_PRODUCT);
				} else {
					for (let i = 1; i <= parseInt(e.qty); i++) {
						this.extraItemList.push({ item: "" });
					}
				}
			} else {
				for (let k = 1; k <= parseInt(e.qty); k++) {
					this.extraItemList.push({ item: "" });
				}
			}
			this.modalAddArticleCustomText = true;
		});
		window.Bus.$on("start-buyer-dashboard-tour", (_) => {
			setTimeout(() => {
				this.runDashboardTour();
			}, 1000);
		});
		window.Bus.$on("open-add-customer-delivery", (_) => {
			this.deliveryAddress = {
				location_name: "",
				street_address: "",
				zip_code: "",
				country: "SE",
				city: "",
				phone_number: "",
				is_default_address: false,
			};
			let url = `?is_connected=True`;
			this.$store
				.dispatch("customer/getConnectedShopList", { data: url })
				.then((_) => {})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_CUS", null);
						window.Bus.$emit("sign-out");
					}
				});
			this.modalAddDeliveryAddress = true;
		});
		window.Bus.$on("open-edit-customer-delivery", (e) => {
			this.deliveryAddress = e;
			this.modalEditDeliveryAddress = true;
		});
		window.Bus.$on("open-disabled-setting", (e) => {
			this.modalDisabledSetting = true;
			let self = this;
			setTimeout(function () {
				self.$router.replace({ name: "customerdash" });
				this.modalDisabledSetting = false;
			}, 500);
		});
		this.countries = this.$services.helpers.getCountryList();
		window.Bus.$on("open-cart-slider", (e) => {
			this.cartQty = e.qty;
			this.$store.commit("utility/UPDATE_CART_DRAWER", true);
		});
		window.Bus.$on("close-cart-slider", (e) => {
			this.$store.commit("utility/UPDATE_CART_DRAWER", false);
		});
		window.Bus.$on("add-to-cart", (e) => {
			if (this.addingToCart === true) {
				return false;
			}
			/**
			 * This method handles adding product to cart and performing computation on the product
			 */
			let currentDate = moment().format("YYYY-MM-DD");
			let product = e.product;
			let qty = e.qty;
			let productLoad = {
				product: product,
				qty: qty,
				apply_discount: false,
			};
			// check if stock threshold is to be applied
			if (this.GET_COMPANY_SETTING.apply_stock_threshold === true) {
				if (
					parseFloat(product.quantity_in_stock) <=
					parseFloat(
						this.GET_COMPANY_SETTING.threshold_product_visibility
					)
				) {
					this.$services.helpers.notification(
						this.$t("outOfStock"),
						"error",
						this
					);
					return false;
				}
			}
			const totalPrice = parseFloat(
				parseFloat(product.sales_price) * parseFloat(qty)
			);
			try {
				// apply minimum order quantity rule
				if (parseFloat(product.minimum_order_quantity) > 0) {
					if (
						parseFloat(qty) <
						parseFloat(product.minimum_order_quantity)
					) {
						return this.$services.helpers.notification(
							`${this.$t("customerMininmumOrderQtyError")} ${
								product.minimum_order_quantity
							}`,
							"error",
							this
						);
					}
				}
			} catch (e) {}
			// apply minimum order amount rule
			if (parseFloat(product.minimum_order_amount) > 0) {
				if (
					parseFloat(totalPrice) <
					parseFloat(product.minimum_order_amount)
				) {
					return this.$services.helpers.notification(
						`${this.$t("customerMinimumOrderAmountError")} ${
							product.minimum_order_amount
						}`,
						"error",
						this
					);
				}
			}
			// check if discount is to be applied to the product
			if (parseFloat(product.discounted_price) > 0) {
				if (
					product.scheduled_campaign_start_date !== null &&
					product.scheduled_campaign_end_date !== null &&
					product.scheduled_campaign_start_date !== "" &&
					product.scheduled_campaign_end_date !== ""
				) {
					let startDate = moment(
						product.scheduled_campaign_start_date
					).format("YYYY-MM-DD");
					let endDate = moment(
						product.scheduled_campaign_end_date
					).format("YYYY-MM-DD");
					if (currentDate >= startDate && currentDate <= endDate) {
						productLoad["apply_discount"] = true;
					}
				}
			}
			// // check if custom row to be added and if true emit signal to open the modal
			if (product.allow_custom_row_text === true) {
				window.Bus.$emit("open-custom-row-text", {
					data: product,
					qty: qty,
				});
			}

			// // add product to cart and emit success
			if (productLoad?.qty) {
				this.$store.commit("customer/ADD_CART_PRODUCT", productLoad);
			}

			window.Bus.$emit("message", {
				data: `${qty} ${this.$t("itemAdd")}`,
			});

			// emit signal to revert all input field back to zero
			window.Bus.$emit("revert-input-to-zero");
			this.addingToCart = false;
		});

	},
	beforeMount() {
		if (!this.CUSTOMER_TOKEN) {
			this.$store.commit("utility/CHANGE_LOCALE", "se");
			this.$store.commit("auth/CLEAR_AUTH_CUS", null);
			if (this.$route.path !== "/") {
				this.$router.replace({ name: "login" });
			}
		} else {
			this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
		}
	},
	methods: {
		resize() {
			if (isScreen("xs") || isScreen("sm") || isScreen("md")) {
				this.cartSliderWidth = 100;
			} else {
				this.cartSliderWidth = 25;
			}
		},
		runDashboardTour() {
			if (!this.GET_BUYER_TOUR.dashboard) {
				this.$tours.dashboard.start();
			}
		},
		completeAllTour() {
			const payload = {
				dashboard: true,
			};
			this.$store.commit("customer/UPDATE_BUYER_TOUR_ALL", payload);
		},
		addBankMenu() {
			if (this.bankForm.number === "") {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				window.Bus.$emit("switch-order-processing");
				this.$store.commit(
					"customer/STORE_BANK_ID",
					this.bankForm.number
				);
				this.$store
					.dispatch("customer/verifyBankId", {
						data: `company_id=${this.GET_COMPANY_SETTING.id}&bank_id=${this.bankForm.number}`,
					})
					.then((res) => {
						window.Bus.$emit("dispatch-order-request", {
							bank_id: this.bankForm.number,
							signature: res.data.signature,
							name: res.data.name,
						});
						this.modalBankId = false;
					})
					.catch(() => {
						window.Bus.$emit("switch-order-processing");
						this.$services.helpers.notification(
							"Bank-id-valideringsfel",
							"error",
							this
						);
					});
			}
		},
		modalAddUser() {
			if (
				this.addUserForm.firstname === "" ||
				this.addUserForm.lastname === "" ||
				this.addUserForm.email === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (
				this.addUserForm.email.length > 50 ||
				this.addUserForm.firstname.length > 50 ||
				this.addUserForm.lastname.length > 50
			) {
				this.$services.helpers.notification(
					this.$t("fieldValue"),
					"error",
					this
				);
			} else if (
				!this.$services.helpers.validateEmailAddress(
					this.addUserForm.email
				)
			) {
				this.$services.helpers.notification(
					this.$t("invalidEmail"),
					"error",
					this
				);
			} else {
				this.addingUsers = true;
				const payload = {
					email: this.addUserForm.email,
					first_name: this.addUserForm.firstname,
					last_name: this.addUserForm.lastname,
					user_type: "Employee",
				};
				this.$store
					.dispatch("customer/addUsers", payload)
					.then((_) => {
						this.addingUsers = false;
						this.$services.helpers.notification(
							this.$t("invitedUserSuccess"),
							"success",
							this
						);
						Object.keys(this.addUserForm).forEach((item) => {
							this.addUserForm[item] = "";
						});
						window.Bus.$emit("fetch-users");
						this.modalAddUsers = false;
					})
					.catch((err) => {
						this.addingUsers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_CUS", null);
							window.Bus.$emit("sign-out");
						} else {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						}
					});
			}
		},
		updateUser() {
			if (
				this.updateUsers.first_name === "" ||
				this.updateUsers.last_name === "" ||
				this.updateUsers.email === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (
				this.updateUsers.email.length > 50 ||
				this.updateUsers.first_name.length > 50 ||
				this.updateUsers.last_name.length > 50
			) {
				this.$services.helpers.notification(
					this.$t("fieldValue"),
					"error",
					this
				);
			} else if (
				!this.$services.helpers.validateEmailAddress(
					this.updateUsers.email
				)
			) {
				this.$services.helpers.notification(
					this.$t("invalidEmail"),
					"error",
					this
				);
			} else {
				this.addingUsers = true;
				const payload = {
					first_name: this.updateUsers.first_name,
					last_name: this.updateUsers.last_name,
					email: this.updateUsers.email,
					id: this.updateUsers.id,
				};
				this.$store
					.dispatch("customer/updateUser", payload)
					.then((_) => {
						this.addingUsers = false;
						this.$services.helpers.notification(
							this.$t("updateUserSuccess"),
							"success",
							this
						);
						window.Bus.$emit("fetch-users");
						setTimeout(() => {
							this.modalUsers = false;
						}, 1000);
					})
					.catch((err) => {
						this.addingUsers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		updateNote() {
			this.addingUsers = true;
			let data = {
				is_buyer_handle_note: this.orderObj.is_buyer_handle_note,
			};
			if (this.orderObj.buyer_note) {
				data["buyer_note"] = this.orderObj.buyer_note;
			}
			const payload = {
				data: data,
				URL: `/${this.orderObj.id}/notes/`,
			};
			this.$store
				.dispatch("customer/updateOrderNote", payload)
				.then((_) => {
					this.addingUsers = false;
					this.$services.helpers.notification(
						this.$t("updateSuccess"),
						"success",
						this
					);
					setTimeout(() => {
						this.modalNote = false;
					}, 1000);
				})
				.catch((err) => {
					this.addingUsers = false;
					window.Bus.$emit("output-error", err);
					// if (err.response.status === 401) {
					// 	this.$store.commit('auth/CLEAR_AUTH_USER', null)
					// 	window.Bus.$emit('sign-out')
					// } else {
					// 	this.$services.helpers.notification(err.response.data.message, 'error', this)
					// }
				});
			// if (this.orderObj.buyer_note === '') {
			// 	this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			// } else {
			//
			// }
		},
		deleteIntegration(integration) {
			const self = this;

			this.$confirm(`${this.$t("remove")}?`, `${this.$t("warning")}`, {
				confirmButtonText: this.$t("yes"),
				cancelButtonText: this.$t("cancel"),
				type: "warning",
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = "Loading...";

						self.$store
							.dispatch("customer/deleteConnectedIntegration", {
								id: integration.id,
							})
							.then((res) => {
								window.Bus.$emit("get-connected-integration");
								this.$services.helpers.notification(
									`${this.$t("removeSuccessful")}`,
									"success",
									this
								);
								done();
								instance.confirmButtonLoading = false;
							})
							.catch((err) => {
								done();
								instance.confirmButtonLoading = false;
								if (err.response.status === 400) {
									this.$services.helpers.notification(
										err.response.data,
										"error",
										this
									);
								}
							});
					} else {
						done();
					}
				},
			})
				.then(() => {
					window.Bus.$emit("get-connected-integration");
				})
				.catch(() => {});
		},
		deleteUser(user) {
			const self = this;
			let name = "";
			if (user.user) {
				name = `${user.user.groups[0].name}`;
			}
			this.$confirm(
				`${this.$t("removeUser")} ${
					user.first_name + " " + user.last_name + " som " + name
				}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";
							self.$store
								.dispatch("customer/deleteUser", {
									id: user.id,
								})
								.then((_) => {
									window.Bus.$emit("fetch-users");
									done();
									instance.confirmButtonText = this.$t("yes");
									this.$services.helpers.notification(
										`${this.$t("removeSuccessful")}`,
										"success",
										this
									);
									instance.confirmButtonLoading = false;
								})
								.catch((err) => {
									done();
									instance.confirmButtonLoading = false;
									if (err.response.status === 400) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									}
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {
					window.Bus.$emit("fetch-users");
				})
				.catch(() => {});
		},
		closeCartDiv() {
			window.Bus.$emit("close-cart");
			this.showFullCover = false;
		},
		redirectAddWebshop() {
			if (this.webshopSelect === "Shopify") {
				this.$router.push({ name: "addshopify" });
			}
			if (this.webshopSelect === "WooCommerce") {
				this.$router.push({ name: "addwoo" });
			}
			setTimeout(() => {
				this.webshopSelect = null;
			}, 100);
			this.modalAddWebshop = false;
		},
		addExtraItem() {
			for (let i = 0; i < this.extraItemList.length; i++) {
				let item = this.extraItemList[i];
				if (
					item.item === "" ||
					item.item === null ||
					item.item === undefined
				) {
					this.$services.helpers.notification(
						this.$t("fieldRequired"),
						"error",
						this
					);
					return false;
				}
			}
			this.addingUsers = true;
			let payload = {
				id: this.productObj.id,
				extra_item: this.extraItemList,
			};
			this.$store.commit("customer/UPDATE_CART_WITH_EXTRA_ITEM", payload);
			this.addingUsers = false;
			this.$services.helpers.notification(
				this.$t("itemUpdated"),
				"success",
				this
			);
			this.modalAddArticleCustomText = false;
		},
		createDeliveryAddress() {
			this.updating = true;
			this.$store
				.dispatch(
					"customer/saveCustomerDeliveryAddress",
					this.deliveryAddress
				)
				.then((res) => {
					this.updating = false;
					window.Bus.$emit(
						"reload-customer-delivery-address",
						res.data.data
					);
					this.modalAddDeliveryAddress = false;
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				});
		},
		updateCustomerDeliveryAddress() {
			this.updating = true;
			if (this.deliveryAddress.hasOwnProperty("customer")) {
				this.deliveryAddress["customer_id"] =
					this.deliveryAddress.customer;
				delete this.deliveryAddress["customer"];
			}
			this.$store
				.dispatch("customer/updateCustomerDeliveryAddress", {
					id: this.deliveryAddress.id,
					data: this.deliveryAddress,
				})
				.then((res) => {
					this.updating = false;
					this.modalEditDeliveryAddress = false;
					window.Bus.$emit(
						"reload-customer-delivery-address",
						res.data.data
					);
					this.$services.helpers.notification(
						this.$t("addressUpdated"),
						"success",
						this
					);
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
					}
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	padding-top: unset !important;
}

.form-control {
	height: unset !important;
	padding: 0.275rem 0.75rem !important;
}

.app-theme-gray.app-container {
	background: #f1f4f6 !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control,
.form-select {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	// cursor: pointer;
}

.bank-id-form {
	&:focus {
		border: 1px solid rgb(10, 116, 158) !important;
	}
}
</style>
