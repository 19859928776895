<template>
	<div
		class="app-container df bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar"
		id="app-root-container"
	>
		<NavbarDash />
		<div class="app-main single-main">
			<div
				class="absolute bg-red-30 inset-0 z-100"
				style="z-index: 9999999999"
				@click="closeNotifyDiv"
				v-if="showFullCover"
			></div>
			<SidebarDash />
			<div class="app-main__outer">
				<router-view />
				<FooterDash />
			</div>
		</div>

		<ModalContainer
			v-if="modalUsers"
			width="md:w-1/3 w-11/12"
			:title="$t('updateShopUsers')"
			@handleClose="modalUsers = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateUser">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("firstName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="updateUsers.first_name"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("lastName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="updateUsers.last_name"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="number"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											type="email"
											v-model="updateUsers.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									:disabled="addingUsers"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalAddUsers"
			width="md:w-1/3 w-11/12"
			:title="$t('addUser')"
			@handleClose="modalAddUsers = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="modalAddUser">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("firstName") }}</label
									>
									<div>
										<input
											data-cy="user-firstname"
											type="text"
											v-model="addUserForm.firstname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("lastName") }}</label
									>
									<div>
										<input
											data-cy="user-lastname"
											type="text"
											v-model="addUserForm.lastname"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										for="number"
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											data-cy="user-email"
											type="email"
											v-model="addUserForm.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									data-cy="user-add-btn"
									type="submit"
									:disabled="addingUsers"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalInviteCustomer"
			width="md:w-1/3 w-11/12"
			:title="$t('inviteEndCustomer')"
			@handleClose="modalInviteCustomer = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="setupInvitation">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("customerName") }}</label
									>
									<div>
										<input
											type="text"
											v-model="formData.customer_name"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("email") }}</label
									>
									<div>
										<input
											type="email"
											v-model="formData.email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											required
										/>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-3">
								<button
									type="submit"
									:disabled="addingUsers"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("inviting") }}
									</span>
									<span v-else>{{ $t("invite") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalVisible"
			width="md:w-1/3 w-11/12"
			:title="$t('createCustomerCategory')"
			@handleClose="modalVisible = false"
			:close="!true"
		>
			<div class="container height-increase" id="height-inc" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="createCustomerCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("customerCategoryName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="cateName"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<div
									style="z-index: 99999"
									class="w-1/2 absolute bg-white shadow border rounded mt-16"
									v-if="modal && filteredAccount.length > 0"
								>
									<ul>
										<li
											class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="(
												item, i
											) in filteredAccount.slice(0, 5)"
											:key="i"
											@click="setAccount(item)"
										>
											{{
												item.number +
												" (" +
												item.description +
												")"
											}}
										</li>
									</ul>
								</div>
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("accountNumber") }}</label
								>
								<div>
									<input
										type="text"
										v-model="accountNumber"
										autocomplete="off"
										@focus="modal = true"
										@input="filterAccnt"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("selectCostCenter")
									}}<span class="text-gray-400">
										({{ $t("optional") }})</span
									>
								</label>
								<div>
									<el-select
										v-model="ccValue"
										filterable
										clearable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(
												item, key
											) in GET_COST_CENTER.results"
											v-bind:key="key"
											:label="item.description"
											:value="item.id"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("creating") }}
									</span>
									<span v-else>{{ $t("create") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<!-- <div slot="footer" class="flex space-x-5">

            </div> -->
		</ModalContainer>

		<ModalContainer
			v-if="modal_photo"
			width="md:w-1/3 w-11/12"
			:title="$t('uploadLogo')"
			@handleClose="modal_photo = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<div class="form-group">
							<label
								class="custom-file-upload disabled:opacity-50"
								:class="
									loadingUpload
										? 'cursor-wait'
										: 'cursor-pointer'
								"
							>
								<input
									type="file"
									id="upload-open"
									class="inputfile form-control-file disabled:opacity-50 cursor-wait"
									@change="croppie"
									accept="image/png, image/jpeg, image/jpg"
								/>
								<i class="iconly-brokenUpload"></i>
								{{ $t("chooseFile") }}
							</label>
						</div>
						<div class="row"></div>
						<div v-show="showCrop">
							<vue-croppie
								ref="croppieRef"
								:enableOrientation="true"
								:enableResize="false"
								:boundary="{ width: 250, height: 250 }"
								:viewport="{
									width: 150,
									height: 150,
									type: 'circle',
								}"
							>
							</vue-croppie>
						</div>
					</div>
					<!-- <div class="col-md-12">

                    </div> -->
				</div>
			</div>
			<div slot="footer" class="flex space-x-5" v-show="showCrop">
				<button
					class="btn hover:text-white text-base btn-custom"
					@click="crop"
					:disabled="initializingCropping"
				>
					<span v-if="initializingCropping">{{
						$t("cropping")
					}}</span>
					<span v-else>{{ $t("crop") }}</span>
				</button>

				<button
					class="btn focus:outline-none text-white bg-green-500"
					@click="finalLogoCropped"
					v-if="croppingSuccess"
				>
					<span>{{ $t("finishUpload") }}</span>
				</button>
			</div>
		</ModalContainer>

		<ModalContainer
			v-if="modalArticleImages"
			width="md:w-1/3 w-11/12"
			:title="$t('updateProductImage')"
			@handleClose="modalArticleImages = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<!-- <el-upload
                        class="upload-demo"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        list-type="picture">
                        <el-button size="small" type="primary">Click to upload</el-button>
                        <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div>
                        </el-upload> -->
						<!-- :http-request="uploadProductImages" -->
						<el-upload
							class="upload-demo"
							drag
							:action="this.$services.endpoints.ARTICLE_IMAGES"
							:on-preview="handlePreview"
							:limit="4"
							:on-exceed="handleExceed"
							:on-change="productUploadOnchange"
							:auto-upload="false"
							list-type="picture"
							:on-remove="handleRemove"
							:file-list="productImages"
							ref="productUpload"
							multiple
						>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								{{ $t("dropImageFiles")
								}}<em>{{ $t("clickToUpload") }}</em>
							</div>
							<!--							<div class="el-upload__tip" slot="tip">jpg/png/jpeg {{ $t('fileFormat') }}</div>-->
							<div class="el-upload__tip" slot="tip">
								{{ $t("maximumImages") }}
							</div>
							<div slot="tip" class="el-upload__tip">
								{{ $t("maxArticleImageUploadMessage") }}
							</div>
						</el-upload>
					</div>
					<!-- <div class="col-md-12">

                    </div> -->
				</div>
			</div>
			<div slot="footer" class="flex space-x-5">
				<button
					:disabled="updating"
					:style="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? 'background:' + GET_CUSTOMIZATION.button
							: ''
					"
					:class="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? ''
							: 'bg-blue-900'
					"
					class="btn focus:outline-none text-white"
					@click="uploadArticlePictures"
				>
					<span v-if="updating">{{ $t("uploading") }}</span>
					<span v-else>{{ $t("finishUpload") }}</span>
				</button>
			</div>
		</ModalContainer>
		<ModalContainer
			v-if="modalArticleBulkUpload"
			width="md:w-1/3 w-11/12"
			:title="$t('bulk_article_upload')"
			@handleClose="modalArticleBulkUpload = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<el-upload
							class="upload-excel"
							drag
							:action="this.$services.endpoints.ARTICLE_IMAGES"
							:limit="1"
							:on-change="handleArticleChange"
							:auto-upload="false"
							accept=".xlsx"
							:on-remove="handleArticleRemove"
							:file-list="excelFile"
							ref="excelUpload"
							:multiple="false"
						>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								{{ $t("dropImageFiles") }}
								<em>{{ $t("clickToUpload") }}</em>
							</div>
							<div class="el-upload__tip" slot="tip">
								{{ $t("excel_file_format_text") }}
							</div>
						</el-upload>
					</div>
					<div class="col-md-12">
						<el-upload
							style="margin-top: 28px"
							class="upload--demo upload-zip"
							:action="this.$services.endpoints.ARTICLE_IMAGES"
							:on-change="handleZipImageChange"
							:on-remove="handleZipImageRemove"
							:limit="1"
							accept=".zip"
							:auto-upload="false"
							:multiple="false"
							:file-list="fileList"
						>
							<el-button size="small" type="primary">{{
								$t("clickUploadZipText")
							}}</el-button>
							<div slot="tip" class="el-upload__tip">
								{{ $t("zipTextMsg") }}
							</div>
							<div slot="tip" class="el-upload__tip">
								{{ $t("zipTextDimensionMsg") }}
							</div>
							<div
								slot="tip"
								class="el-upload__tip"
								style="color: #ff40da !important"
								v-if="totalImagesToUpload > 0"
							>
								{{ $t("totalImageToUpload")
								}}{{ totalImagesToUpload }}
								{{ $t("estimatedTime") }}: {{ estimatedTime }}
							</div>
						</el-upload>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5">
				<button
					:disabled="updating"
					v-if="updating === false"
					:style="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? 'background:' + GET_CUSTOMIZATION.button
							: ''
					"
					:class="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? ''
							: 'bg-blue-900'
					"
					class="btn focus:outline-none text-white"
					@click="uploadBulkExcelArticleData"
				>
					<span>{{ $t("finishUpload") }}</span>
				</button>
				<progress-bar
					v-else
					:options="options"
					:value="progressPercentage"
				/>
			</div>
		</ModalContainer>

		<ModalContainer
			v-if="modalArticleEdit"
			width="md:w-1/3 w-11/12"
			:title="$t('updateArticle')"
			@handleClose="modalArticleEdit = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateArticle">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("articleName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="articleEditObj.product_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("articleVideoURL") }}</label
								>
								<div>
									<input
										type="text"
										v-model="
											articleEditObj.product_video_url
										"
										placeholder="https:// "
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("disposableQty") }}</label
								>
								<div>
									<input
										type="text"
										v-model="
											articleEditObj.quantity_in_stock
										"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("costPrice") }}</label
								>
								<div>
									<input
										type="text"
										v-model="articleEditObj.purchase_price"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("price") }}</label
								>
								<div>
									<input
										type="text"
										v-model="articleEditObj.price"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("articleLongDescription") }}</label
								>
								<div>
									<textarea
										v-model="
											articleEditObj.product_long_description
										"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full h-20"
										required
									></textarea>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalAssignProductCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('assignSelectedProducts')"
			@handleClose="modalAssignProductCategory = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="assignMassProduct">
							<div>
								<!-- <label  class="col-form-label font-bold whitespace-nowrap text-lg">You are about to assign {{ GET_ARTICLES_CHECKED.data.length }} products to {{ $t('category') }}</label> -->
								<label
									class="col-form-label font-bold whitespace-nowrap text-lg"
									>{{ $t("assignProduct") }}</label
								>
							</div>
							<div class="form-group">
								<!-- <label  class="col-form-label font-bold whitespace-nowrap text-">{{ $t('productCategory') }}</label> -->
								<div class="row mb-5">
									<div class="col-md-6">
										<span class="text-base">{{
											$t("parentCategory")
										}}</span
										><br />
										<ul>
											<li
												class="flex cursor-pointer hover:text-blue-500"
												v-for="i in parentCategory"
												:key="i"
											>
												-
												<span
													class="flex align-items-center space-x-5"
												>
													{{
														showParentCategoryName(
															i
														)
													}}
													<span
														@click="
															removeParentCategoryFromList(
																i
															)
														"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-4 w-4 text-gray-500"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
															/>
														</svg>
													</span>
												</span>
											</li>
										</ul>
									</div>
									<div class="col-md-6">
										<span class="text-base">{{
											$t("childCategory")
										}}</span
										><br />
										<ul>
											<li
												class="flex cursor-pointer hover:text-blue-500"
												v-for="sub in childCategory"
												:key="sub.id"
											>
												- &nbsp;
												<span
													class="flex align-items-center space-x-5"
												>
													{{
														showSubCategoryName(sub)
													}}
													<span
														@click="
															selectAndRemoveSubCategory(
																sub
															)
														"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-4 w-4 text-gray-500"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
															/>
														</svg>
													</span>
												</span>
											</li>
										</ul>
									</div>
								</div>
								<span class="mb-2 text-lg font-semibold">{{
									$t("selectCategoryBelow")
								}}</span
								><br />
								<div class="row mt-3">
									<div
										class="col-md-4 mb-4"
										v-for="category in GET_PRODUCT_CATEGORY.data"
										:key="category.parent_category_id"
									>
										<h2
											:class="
												checkParentCategory(category)
													? 'text-blue-600 font-semibold'
													: ''
											"
											class="font-semibold cursor-pointer"
											@click="
												selectAndRemoveParentCategory(
													category
												)
											"
										>
											{{ category.parent_category_name }}
										</h2>
										<ul
											v-for="sub in category.sub_category"
											:key="sub.id"
										>
											<li
												:class="
													checkSubCategory(sub)
														? 'text-blue-600 font-semibold'
														: ''
												"
												class="cursor-pointer hover:text-blue-500"
												@click="
													selectAndRemoveSubCategory(
														sub
													)
												"
											>
												-
												{{ sub.name | capitalizeNames }}
											</li>
										</ul>
									</div>
								</div>
								<div>
									<!-- <el-select v-model="productCategoryMassAssignForm.category" multiple placeholder="Select">
                                        <el-option-group
                                        v-for="category in GET_PRODUCT_CATEGORY.data"
                                        :key="category.parent_category_id"
                                        :value='category.parent_category_id'
                                        :label="category.parent_category_name">
                                        <el-option
                                            v-for="subCategory in category.sub_category"
                                            :key="subCategory.id"
                                            :label="subCategory.name"
                                            :value="subCategory.id">
                                        </el-option>
                                        </el-option-group>
                                    </el-select> -->
									<!-- <select v-model="productCategoryMassAssignForm.category" class="form-control input-shadow-box" required>
                                        <option :value="category.id" v-for="category in GET_PRODUCT_CATEGORY" :key="category.id">{{ category.name }}</option>
                                        <option :value="category.parent_category_id" v-for="category in GET_PRODUCT_CATEGORY.data" :key="category.parent_category_id">{{ category.parent_category_name }}</option>
                                    </select> -->
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="assigningProduct"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="assigningProduct"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{
										$t("assignProduct")
									}}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditAssignProductCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('assignSelectedProducts')"
			@handleClose="modalEditAssignProductCategory = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateAssignMassProduct">
							<div>
								<!-- <label  class="col-form-label font-bold whitespace-nowrap text-lg">You are about to assign {{ GET_ARTICLES_CHECKED.data.length }} products to {{ $t('category') }}</label> -->
								<label
									class="col-form-label font-bold whitespace-nowrap text-lg"
									>{{ $t("assignProduct") }}</label
								>
							</div>
							<div class="form-group">
								<!-- <label  class="col-form-label font-bold whitespace-nowrap text-">{{ $t('productCategory') }}</label> -->
								<div class="row mb-5">
									<div class="col-md-6">
										<span class="text-base">{{
											$t("parentCategory")
										}}</span
										><br />
										<ul>
											<li
												class="flex cursor-pointer hover:text-blue-500"
												v-for="(
													i, key
												) in parentCategory"
												:key="key"
											>
												-
												<span
													class="flex align-items-center space-x-5"
												>
													{{
														showParentCategoryName(
															i
														)
													}}
													<span
														@click="
															removeParentCategoryFromList(
																i
															)
														"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-4 w-4 text-gray-500"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
															/>
														</svg>
													</span>
												</span>
											</li>
										</ul>
									</div>
									<div class="col-md-6">
										<span class="text-base">{{
											$t("childCategory")
										}}</span
										><br />
										<ul>
											<li
												class="flex cursor-pointer hover:text-blue-500"
												v-for="(
													sub, key
												) in childCategory"
												:key="key"
											>
												- &nbsp;
												<span
													class="flex align-items-center space-x-5"
												>
													{{
														showSubCategoryName(sub)
													}}
													<span
														@click="
															selectAndRemoveSubCategory(
																sub
															)
														"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-4 w-4 text-gray-500"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
															/>
														</svg>
													</span>
												</span>
											</li>
										</ul>
									</div>
								</div>
								<span class="mb-2 text-lg font-semibold">{{
									$t("selectCategoryBelow")
								}}</span
								><br />
								<div class="row mt-3">
									<div
										class="col-md-4 mb-4"
										v-for="(
											category, key
										) in GET_PRODUCT_CATEGORY.data"
										:key="key"
									>
										<h2
											:class="
												checkParentCategory(category)
													? 'text-blue-600 font-semibold'
													: ''
											"
											class="font-semibold cursor-pointer"
											@click="
												selectAndRemoveParentCategory(
													category
												)
											"
										>
											{{ category.parent_category_name }}
										</h2>
										<ul
											v-for="(
												sub, key
											) in category.sub_category"
											:key="key"
										>
											<li
												:class="
													checkSubCategory(sub)
														? 'text-blue-600 font-semibold'
														: ''
												"
												class="cursor-pointer hover:text-blue-500"
												@click="
													selectAndRemoveSubCategory(
														sub
													)
												"
											>
												-
												{{ sub.name | capitalizeNames }}
											</li>
										</ul>
									</div>
								</div>
								<div>
									<!-- <el-select v-model="productCategoryMassAssignForm.category" multiple placeholder="Select">
                                        <el-option-group
                                        v-for="category in GET_PRODUCT_CATEGORY.data"
                                        :key="category.parent_category_id"
                                        :value='category.parent_category_id'
                                        :label="category.parent_category_name">
                                        <el-option
                                            v-for="subCategory in category.sub_category"
                                            :key="subCategory.id"
                                            :label="subCategory.name"
                                            :value="subCategory.id">
                                        </el-option>
                                        </el-option-group>
                                    </el-select> -->
									<!-- <select v-model="productCategoryMassAssignForm.category" class="form-control input-shadow-box" required>
                                        <option :value="category.id" v-for="category in GET_PRODUCT_CATEGORY" :key="category.id">{{ category.name }}</option>
                                        <option :value="category.parent_category_id" v-for="category in GET_PRODUCT_CATEGORY.data" :key="category.parent_category_id">{{ category.parent_category_name }}</option>
                                    </select> -->
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="assigningProduct"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="assigningProduct"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("updateBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="singleProductCategoryAssign"
			width="md:w-1/2 w-11/12"
			:title="$t('assignArticleCategory')"
			@handleClose="singleProductCategoryAssign = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<article-category-assign-component
					:article-id="articleId"
					:key="articleCategoryKey"
					:select-state="articleCategorySelectState"
				/>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalSubCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('addSubCategory')"
			@handleClose="modalSubCategory = false"
			:close="!true"
		>
			<div class="container height-increae" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="addSubCategoryForm">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("selectParentCategory") }}</label
								>
								<div>
									<select
										v-model="subCategoryForm.parentCategory"
										class="form-control input-shadow-box"
										required
									>
										<option value="" selected disabled>
											--------
										</option>
										<option
											:value="category.parent_category_id"
											v-for="category in GET_PRODUCT_CATEGORY.data"
											:key="category.parent_category_id"
										>
											{{ category.parent_category_name }}
										</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("subCategoryName") }}</label
								>
								<div>
									<input
										v-model="subCategoryForm.name"
										type="text"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>

							<div class="form-group">
								<button
									type="submit"
									:disabled="addingSubCategory"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingSubCategory"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalProductCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('addProductCategory')"
			@handleClose="modalProductCategory = false"
			:close="!true"
		>
			<div class="container height-increae" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="addProductCategoryForm">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("productCategoryName") }}</label
								>
								<div>
									<input
										v-model="productCategoryForm.name"
										type="text"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<!-- <div class="form-group">
                                <div style='z-index:99999' class="w-1/2 absolute bg-white shadow border rounded mt-16" v-if="modal && filteredAccount.length > 0">
                                    <ul>
                                        <li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer" v-for="(item, i) in filteredAccount.slice(0, 5)" :key="i" @click="setAccount(item)">{{ item.number + ' (' + item.description + ')' }}</li>
                                    </ul>
                                </div>
                                <label  class="col-form-label font-bold whitespace-nowrap text-xs">{{ $t('accountNumber') }} <span class="text-gray-400"> ({{ $t('optional') }})</span></label>
                                <div>
                                    <input type="text" v-model="accountNumber" autocomplete="off" @focus="modal = true" @input="filterAccnt" class="form-control input-shadow-box">
                                </div>
                            </div>
                            <div class="form-group">
                                <label  class="col-form-label font-bold whitespace-nowrap text-xs">{{ $t('selectCostCenter') }}<span class="text-gray-400"> ({{ $t('optional') }})</span> </label>
                                <div>
                                    <select v-model="productCategoryForm.costCenter" class="form-control input-shadow-box">
                                        <option value="">--------</option>
                                        <option :value="cc.id" v-for="cc in GET_COST_CENTER" :key="cc.id">{{ cc.description }}</option>
                                    </select>
                                </div>
                            </div> -->
							<div class="form-group">
								<button
									:disabled="addingProductCategory"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingProductCategory"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('updateProductCategory')"
			@handleClose="modalEditCategory = false"
			:close="!true"
		>
			<div class="container height-increae" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateParentCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("productCategoryName") }}</label
								>
								<div>
									<input
										v-model="
											categoryForm.parent_category_name
										"
										type="text"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updateCategoryBtn"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updateCategoryBtn"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("updateBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditChildCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('updateSubProductCategory')"
			@handleClose="modalEditChildCategory = false"
			:close="!true"
		>
			<div class="container height-increae" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateChildCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("subCategoryName") }}</label
								>
								<div>
									<input
										v-model="childCategoryForm.name"
										type="text"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updateChildCategoryBtn"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updateChildCategoryBtn"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("updateBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditCustomerCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('update') + ' ' + $t('category')"
			@handleClose="modalEditCustomerCategory = false"
			:close="!true"
		>
			<div class="container height-increae" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateCustomerCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
								></label>
								<div>
									<input
										v-model="EditcustomerCategory.name"
										type="text"
										class="form-control input-shadow-box"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updateBtn"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("updateBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalRelevantCustomerEdit"
			width="md:w-1/3 w-11/12"
			:title="$t('updateCustomerInfo')"
			@handleClose="modalRelevantCustomerEdit = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form
							@submit.prevent="updateRelevantCustomer"
							v-if="!multipleAssign"
						>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("relevantCustomer") }}?</label
								>
								<div>
									<select
										v-model="
											relevantCustomerObj.is_relevant
										"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									>
										<option :value="true">
											{{ $t("yes") }}
										</option>
										<option :value="false">
											{{ $t("no") }}
										</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
						<form
							@submit.prevent="updateMultiRelevantCustomer"
							v-else
						>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("relevantCustomer") }}?</label
								>
								<div>
									<select
										v-model="
											relevantCustomerObj.is_relevant
										"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									>
										<option :value="true">
											{{ $t("yes") }}
										</option>
										<option :value="false">
											{{ $t("no") }}
										</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="assignMultipleCustomerCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('massAssign') + ' ' + $t('customers')"
			@handleClose="assignMultipleCustomerCategory = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form
							v-on:submit.prevent="
								updateAssignMassCustomerCategory
							"
						>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<select
										v-model="assignedCustomerCategory"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									>
										<option
											:value="cate.id"
											v-for="cate in GET_CUSTOMER_CATEGORY.results"
											:key="cate.id"
										>
											{{ cate.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="openBlogCategory"
			width="md:w-1/3 w-11/12"
			title="Add Blog Category"
			@handleClose="openBlogCategory = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="addBlogCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<input
										type="text"
										v-model="blogForm.name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openArticleSpecificationData"
			width="md:w-1/3 w-11/12"
			:title="$t('updateArticleSpecification')"
			@handleClose="openArticleSpecificationData = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateArticleSpecification">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("title") }}</label
								>
								<div>
									<input
										type="text"
										v-model="articleSpecificationData.title"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("text") }}</label
								>
								<div>
									<input
										type="text"
										v-model="articleSpecificationData.text"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openEditBlogCategory"
			width="md:w-1/3 w-11/12"
			:title="$t('updateBlogCategory')"
			@handleClose="openEditBlogCategory = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateBlogCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<input
										type="text"
										v-model="blogForm.name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("updateBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="CustomerEditModal"
			width="md:w-1/3 w-11/12"
			:title="$t('updateCustomerInfo')"
			@handleClose="CustomerEditModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="updateCustomer">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("customerName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="customerObj.customer_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("customerEmail") }}</label
								>
								<div>
									<input
										type="email"
										v-model="customerObj.email"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<div
									style="z-index: 99999"
									class="w-4/5 absolute bg-white shadow border rounded mt-16"
									v-if="modal && filteredAccount.length > 0"
								>
									<ul>
										<li
											class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
											v-for="(
												item, i
											) in filteredAccount.slice(0, 5)"
											:key="i"
											@click="setAccount(item)"
										>
											{{
												item.number +
												" (" +
												item.description +
												")"
											}}
										</li>
									</ul>
								</div>
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("accountNumber") }}</label
								>
								<div>
									<!-- <input v-if="customerObj.category && customerObj.category.account_number" type="text" v-model="accountNumber" autocomplete="off" @focus="modal = true" @input="filterAccnt" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required> -->
									<input
										type="text"
										v-model="accountNumber"
										autocomplete="off"
										@focus="modal = true"
										@input="filterAccnt"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>

							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("customerNumber") }}</label
								>
								<div>
									<input
										type="text"
										disabled="true"
										v-model="customerObj.customer_number"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>

							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("selectCostCenter")
									}}<span class="text-gray-400">
										({{ $t("optional") }})</span
									>
								</label>
								<div>
									<el-select
										v-model="costCenterNumber"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="costCenter in GET_COST_CENTER.results"
											v-bind:key="costCenter.id"
											:clearable="true"
											:label="costCenter.description"
											:value="costCenter.id"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<el-select
										v-model="customerObj.category_id"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="cate in GET_CUSTOMER_CATEGORY.results"
											v-bind:key="cate.id"
											:clearable="false"
											:label="cate.name"
											:value="cate.id"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("maxCreditLimit") }}</label
								>
								<div>
									<input
										type="num"
										min="0"
										v-model="customerObj.max_credit_limit"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group mb-0 mt-1">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="render-only-customer-specific-article"
										type="checkbox"
										id="render-only-customer-specific-article"
										v-model="
											customerObj.render_only_customer_specific_articles
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"renderOnlyCustomerSpecificArticle"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'renderOnlyCustomerSpecificArticle'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="render-only-customer-specific-categories"
										type="checkbox"
										v-model="
											customerObj.render_only_customer_specific_categories
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"renderOnlyCustomerSpecificCategories"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'renderOnlyCustomerSpecificCategories'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="render-only-customer-specific-categories"
										type="checkbox"
										v-model="
											customerObj.customer_specific_reference_field
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"renderOnlyCustomerSpecificReferenceField"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'renderOnlyCustomerSpecificReferenceField'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="self_allowed_create_delivery_addresses"
										type="checkbox"
										v-model="
											customerObj.self_allowed_create_delivery_addresses
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"self_allowed_create_delivery_addresses"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'self_allowed_create_delivery_addresses'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="self_allowed_create_delivery_addresses"
										type="checkbox"
										v-model="
											customerObj.apply_customer_specific_user_profiles
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"apply_customer_specific_user_profiles"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'apply_customer_specific_user_profiles'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="apply_english_on_all_templates"
										type="checkbox"
										v-model="
											customerObj.apply_english_on_all_templates
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"apply_english_on_all_templates"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'apply_english_on_all_templates'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="apply_customer_currency_on_orders"
										type="checkbox"
										v-model="
											customerObj.apply_customer_currency_on_orders
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{
											$t(
												"apply_customer_currency_on_orders"
											)
										}}
										<span
											class="help-tooltip cursor-pointer"
											:title="
												$t(
													'apply_customer_currency_on_orders'
												)
											"
										></span
									></span>
								</label>
							</div>
							<div class="form-group mb-0">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="appConnectModal"
			width="md:w-1/3 w-11/12"
			@handleClose="appConnectModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<!--				<div class="absolute inset-0 z-40" @click="appConnectModal = false" v-if="appConnectModal"></div>-->
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<div class="w-full mx-auto">
							<img
								src="./../../assets/images/icons/notification_bell.png"
								alt="notification for integrations"
								class="h-[100px] mx-auto"
							/>
						</div>
						<div class="py-[10px] px-[2px]">
							<p class="font-semibold text-[17px] pt-[9px]">
								{{ $t("yet_to_connect_to_integration") }}
							</p>
							<span
								v-on:click="goToIntegration"
								:style="
									GET_CUSTOMIZATION &&
									GET_CUSTOMIZATION.button
										? 'background:' +
										  GET_CUSTOMIZATION.button
										: ''
								"
								:class="
									GET_CUSTOMIZATION &&
									GET_CUSTOMIZATION.button
										? ''
										: 'bg-blue-900'
								"
								class="mt-[10px] flex justify-center cursor-pointer align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
							>
								<span>{{ $t("getStarted") }}</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalAddDeliveryAddress"
			width="md:w-1/3 w-11/12"
			:title="$t('addDeliveryAddress')"
			@handleClose="modalAddDeliveryAddress = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="createDeliveryAddress">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("locationName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.location_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("streetAddress") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.street_address"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("zipCode") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.zip_code"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("country") }}</label
								>
								<div>
									<el-select
										v-model="deliveryAddress.country"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(item, key) in countries"
											v-bind:key="key"
											:clearable="true"
											:label="item.name"
											:value="item.key"
										>
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("city") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.city"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("phoneNumber") }}</label
								>
								<div>
									<input
										type="number"
										v-model="deliveryAddress.phone_number"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group mb-2 mt-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="is_default_address"
										type="checkbox"
										v-model="
											deliveryAddress.is_default_address
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{ $t("is_default_address") }}
										<span
											class="help-tooltip cursor-pointer"
											:title="$t('is_default_address')"
										></span
									></span>
								</label>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("add") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalEditDeliveryAddress"
			width="md:w-1/3 w-11/12"
			:title="$t('editDeliveryAddress')"
			@handleClose="modalEditDeliveryAddress = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form
							v-on:submit.prevent="updateCustomerDeliveryAddress"
						>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("locationName") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.location_name"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("streetAddress") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.street_address"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("zipCode") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.zip_code"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("country") }}</label
								>
								<div>
									<el-select
										v-model="deliveryAddress.country"
										filterable
										:placeholder="$t('select')"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
									>
										<el-option
											v-for="(item, key) in countries"
											v-bind:key="key"
											:clearable="true"
											:label="item.name"
											:value="item.key"
										>
										</el-option>
									</el-select>
									<!--									<select v-model="deliveryAddress.country"-->
									<!--											class=" flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
									<!--										<option v-for="(item,key) in countries" :key="key" :value="item.key">-->
									<!--											{{ item.name }}-->
									<!--										</option>-->
									<!--									</select>-->
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("city") }}</label
								>
								<div>
									<input
										type="text"
										v-model="deliveryAddress.city"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("phoneNumber") }}</label
								>
								<div>
									<input
										type="number"
										v-model="deliveryAddress.phone_number"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										required
									/>
								</div>
							</div>
							<div class="form-group mb-2 mt-0">
								<label
									class="label-w-input col-form-label font-light whitespace-nowrap"
								>
									<input
										data-cy="is_default_address"
										type="checkbox"
										v-model="
											deliveryAddress.is_default_address
										"
										class="checkbox-height h-4 w-4"
									/>
									<span class="ml-2"
										>{{ $t("is_default_address") }}
										<span
											class="help-tooltip cursor-pointer"
											:title="$t('is_default_address')"
										></span
									></span>
								</label>
							</div>
							<div class="form-group">
								<button
									type="submit"
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center cursor-pointer space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="modalRelevantArticle"
			width="md:w-1/3 w-11/12"
			:title="$t('relevantArticleCustomers')"
			@handleClose="modalRelevantArticle = false"
			:close="!true"
		>
			<div
				class="container"
				slot="body"
				v-loading="loadingRelevantArticle"
			>
				<div
					class="absolute inset-0 z-40"
					@click="openCustomersDropdown = false"
					v-if="openCustomersDropdown"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="setRelevantCustomerArticles">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("customerName") }}</label
									>
									<div>
										<input
											v-model="customerSearchName"
											type="text"
											@input="filterCustomers"
											@focus="
												openCustomersDropdown = true
											"
											:placeholder="$t('searchCustomers')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
									<div
										style="z-index: 99999"
										class="w-10/12 absolute bg-white shadow border rounded mt-1"
										v-if="
											openCustomersDropdown &&
											filteredCustomer.length > 0
										"
									>
										<ul>
											<li
												class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredCustomer.slice(
													0,
													7
												)"
												:key="item.id"
												@click="addCustomerToList(item)"
											>
												{{ item.customer_name }}
												{{
													$services.helpers.cleanData(
														item.organization_number
													)
												}}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-6 mb-1 mt-2"
										v-for="customer in selectedCustomers"
										:key="customer.id"
									>
										<div
											class="flex justify-between bg-white border rounded-md shadow-sm p-1"
										>
											<div>
												{{ customer.customer_name }}
											</div>
											<div>
												<span
													class="cursor-pointer"
													@click="
														removeUserFromSelectedList(
															customer
														)
													"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-40">
								<button
									type="submit"
									:disabled="addingRelevant"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingRelevant"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalRelevantBlog"
			width="md:w-1/3 w-11/12"
			:title="$t('relevantArticleCustomers')"
			@handleClose="modalRelevantBlog = false"
			:close="!true"
		>
			<div class="container" slot="body" v-loading="loadingRelevantBlog">
				<div
					class="absolute inset-0 z-40"
					@click="openBlogsDropdown = false"
					v-if="openBlogsDropdown"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="setRelevantCustomerBlog">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("customerName") }}</label
									>
									<div>
										<input
											v-model="customerSearchName"
											type="text"
											@input="filterCustomers"
											@focus="
												openCustomersDropdown = true
											"
											:placeholder="$t('searchCustomers')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
									<div
										style="z-index: 99999"
										class="w-1/2 absolute bg-white shadow border rounded mt-1"
										v-if="
											openCustomersDropdown &&
											filteredCustomer.length > 0
										"
									>
										<ul>
											<li
												class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredCustomer.slice(
													0,
													7
												)"
												:key="item.id"
												@click="addCustomerToList(item)"
											>
												{{ item.customer_name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-6 mb-1 mt-2"
										v-for="customer in selectedCustomers"
										:key="customer.id"
									>
										<div
											class="flex justify-between bg-white border rounded-md shadow-sm p-1"
										>
											<div>
												{{ customer.customer_name }}
											</div>
											<div>
												<span
													class="cursor-pointer"
													@click="
														removeUserFromSelectedList(
															customer
														)
													"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-40">
								<button
									type="submit"
									:disabled="addingRelevant"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingRelevant"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="modalBlogProductRecommend"
			width="md:w-1/3 w-11/12"
			:title="$t('assignProductBlog')"
			@handleClose="modalBlogProductRecommend = false"
			:close="!true"
		>
			<div
				class="container"
				slot="body"
				v-loading="loadingBlogProductRecommend"
			>
				<div
					class="absolute inset-0 z-40"
					@click="openBlogProductDropdown = false"
					v-if="openBlogProductDropdown"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form @submit.prevent="setRelevantCustomerBlog">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("articleName") }}</label
									>
									<div>
										<input
											v-model="productSearchName"
											type="text"
											@input="filterProducts"
											@focus="
												openBlogProductDropdown = true
											"
											:placeholder="$t('searchProduct')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
									<div
										style="z-index: 99999; width: 93%"
										class="w-1/2 absolute bg-white shadow border rounded mt-1"
										v-if="
											openBlogProductDropdown &&
											filteredProducts.length > 0
										"
									>
										<ul>
											<li
												class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredProducts.slice(
													0,
													7
												)"
												:key="item.id"
												@click="addCustomerToList(item)"
											>
												{{ item.description }}
												{{ item.article_number }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-6 mb-1 mt-2"
										v-for="product in selectedProducts"
										:key="product.id"
									>
										<div
											class="flex justify-between bg-white border rounded-md shadow-sm p-1"
										>
											<div>{{ product.description }}</div>
											<div>
												<span
													class="cursor-pointer"
													@click="
														removeUserFromSelectedList(
															product
														)
													"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-40">
								<button
									type="submit"
									:disabled="addingRelevant"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingRelevant"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("addBtn") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openNewUnitModal"
			width="md:w-1/3 w-11/12"
			:title="$t('addNewUnitType')"
			@handleClose="openNewUnitModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<add-unit-type-component />
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openUpdateUnitModal"
			width="md:w-1/3 w-11/12"
			:title="$t('updateUnitType')"
			@handleClose="openUpdateUnitModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<update-unit-type-component :unit="unitInfo" />
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>

		<ModalContainer
			v-if="openFaqAddModal"
			width="md:w-2/3 w-11/12"
			:title="$t('addFaq')"
			@handleClose="openFaqAddModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="createWhiteLabelFaq">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("question") }}</label
								>
								<div>
									<input
										type="text"
										v-model="faqObj.question"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("answer") }}</label
								>
								<div>
									<vue-editor
										@selection-change="onSelectChangeEditor"
										class="h-custom-screen"
										v-model="faqObj.answer"
									></vue-editor>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<el-select
										class="flex justify-between align-items-center rounded-md w-full"
										v-model="faqObj.category"
										multiple
										filterable
										default-first-option
										:placeholder="$t('selectCategory')"
									>
										<el-option
											v-for="(
												item, key
											) in companyCategoryObj"
											:key="key"
											:label="item.title"
											:value="item.id"
											>{{ item.title }}
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("add") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openFaqEditModal"
			width="md:w-2/3 w-11/12"
			:title="$t('editFaq')"
			@handleClose="openFaqEditModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateWhiteLabelFaq">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("question") }}</label
								>
								<div>
									<input
										type="text"
										v-model="faqObj.question"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("answer") }}</label
								>
								<div>
									<vue-editor
										class="h-custom-screen"
										v-model="faqObj.answer"
									></vue-editor>
								</div>
							</div>
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("category") }}</label
								>
								<div>
									<el-select
										class="flex justify-between align-items-center rounded-md w-full"
										v-model="faqObj.category"
										multiple
										filterable
										default-first-option
										:placeholder="$t('selectCategory')"
									>
										<el-option
											v-for="(
												item, key
											) in companyCategoryObj"
											:key="key"
											:label="item.title"
											:value="item.id"
											>{{ item.title }}
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openFaqCategoryAddModal"
			width="md:w-1/3 w-11/12"
			:title="$t('addCategory')"
			@handleClose="openFaqCategoryAddModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="createWhiteLabelCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("title") }}</label
								>
								<div>
									<input
										type="text"
										v-model="catObj.title"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("add") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openFaqCategoryEditModal"
			width="md:w-1/3 w-11/12"
			:title="$t('updateCategory')"
			@handleClose="openFaqCategoryEditModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateWhiteLabelCategory">
							<div class="form-group">
								<label
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("title") }}</label
								>
								<div>
									<input
										type="text"
										v-model="catObj.title"
										class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									/>
								</div>
							</div>
							<div class="form-group">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openCategoryTreeStatus"
			width="md:w-1/2 w-11/12"
			@handleClose="openCategoryTreeStatus = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<!-- <edit-component-tree :category="categoryTree" /> -->

						<div class="">
							<div class="form-group w-full mt-2 flex flex-col">
								<span class="text-sm text-gray-600 w-1/3 mb-2"
									>{{ $t("categoryName") }}
								</span>
								<input
									type="text"
									v-model="categoryTree.name"
									class="w-full max-w-[260px] rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
									style="outline: currentcolor none medium"
									required
								/>
							</div>
						</div>
					</div>
					<br />
					<br />
					<div class="col-md-4 w-full">
						<span class="text-sm text-gray-600 mb-2">{{
							$t("discountType")
						}}</span>
						<div class="pr-3 md:pr-0 w-full">
							<el-select
								v-model="categoryTree.discount_type"
								filterable
								:placeholder="`${$t('search')}`"
								class="w-full"
							>
								<el-option
									v-for="item in DISCOUNT_TYPES"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-4 -mt-1">
						<div class="form-group w-full mt-2">
							<span class="text-sm text-gray-600 w-1/3 mb-2"
								>{{ $t("discountedValue") }}
							</span>

							<input
								type="number"
								v-model="categoryTree.discount_value"
								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								style="outline: currentcolor none medium"
								required
							/>
						</div>
					</div>
				</div>
				<div class="flex justify-left">
					<button
						v-on:click="updateCategoryForProduct(categoryTree)"
						class="text-white px-3 w-full md:w-1/4 bg-green-500 py-2 rounded-md whitespace-nowrap md:mt-7"
					>
						<span v-if="updating" class="flex justify-center">
							<svg
								class="animate-spin h-6 w-6 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									class="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									stroke-width="4"
								></circle>
								<path
									class="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						</span>
						<span class="text-xs" v-else>
							{{ $t("save") }}
						</span>
					</button>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ModalContainer
			v-if="openBlogRecommendedProduct"
			width="md:w-1/2 w-11/12"
			:title="$t('openBlogRecommendedProduct')"
			@handleClose="openBlogRecommendedProduct = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<linked-product />
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
		<ImageModal
			v-if="modalImage"
			width="w-1/3"
			:title="$t('articleImage')"
			@handleClose="modalImage = false"
			:close="!true"
		>
			<div slot="body">
				<flickity
					ref="flickity"
					:options="flickityOptions"
					v-if="articleImageView.images.length > 0"
				>
					<div
						class="carousel-cell"
						v-for="(img, key) in articleImageView.images"
						:key="img.id"
					>
						<img
							class="carousel-cell-image"
							:src="img.image"
							alt="article product image"
						/>
						<span
							title="Delete"
							class="absolute bottom-0 ml-60 z-100 cursor-pointer"
							@click="removeProductImage(img.id, key)"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
								/>
							</svg>
						</span>
					</div>
				</flickity>
			</div>
		</ImageModal>
		<ModalNote
			v-if="modalNote"
			width="md:w-1/4 w-11/12"
			:title="$t('Note')"
			@handleClose="modalNote = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateNote">
							<div class="form-group unset-margin mb-10">
								<label
									for="number"
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("buyerNote") }}</label
								>
								<div>
									<textarea
										class="w-full focus:outline-none p-2 rounded"
										rows="4"
										:disabled="true"
										v-model="orderObj.message"
									></textarea>
									<!--{{ orderObj.message}} this mustache was remove from here. Not good practice-->
								</div>
							</div>
							<div class="form-group unset-margin">
								<label
									for="number"
									class="col-form-label font-bold whitespace-nowrap text-xs"
									>{{ $t("internalNote") }}</label
								>
								<div>
									<textarea
										class="w-full focus:outline-none p-2 rounded"
										rows="4"
										v-model="orderObj.seller_note"
									></textarea>
									<!--{{ orderObj.seller_note}} this mustache was remove from here. Not good practice-->
								</div>
							</div>
							<div class="form-group unset-margin mb-2">
								<div class="mb-2">
									<el-switch
										v-model="orderObj.is_seller_handle_note"
										:active-text="$t('tasksHandle')"
									>
									</el-switch>
								</div>
							</div>
							<div class="form-group flex space-x-5 mt-2">
								<button
									type="submit"
									class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="addingUsers"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("adding") }}
									</span>
									<span v-else>{{ $t("save") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalNote>

		<el-drawer
			:title="$t('categoryManagement')"
			class="category-style category-drawer-style"
			:visible.sync="categoryDrawer"
			:size="`${articleCategoryTreeSize}%`"
			custom-class="notifiy-drawer-style"
			:direction="direction"
		>
			<article-category-structure :key="articleCategoryKeyLocker" />
		</el-drawer>

		<el-drawer
			:visible.sync="customerSettingDrawer"
			class="category-style"
			:size="`${customerSettingSize}%`"
			custom-class="notifiy-drawer-style"
			:direction="direction"
		>
			<div class="flex flex-col dm-sans-font">
				<div class="container flex justify-between mb-1">
					<div class="font-semibold text-2xl">
						{{ $t("customerManagement") }}
					</div>
				</div>

				<form
					class="flex-none container"
					v-if="addNewManagement"
					@submit.prevent="updateCustomer"
				>
					<div class="row mb-4 align-items-center bg-white m-2 p-2">
						<div class="col-md-10 flex flex-col">
							<div class="row">
								<div class="col-md-2">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("customerName") }}</label
										>
										<div>
											<input
												type="text"
												v-model="
													customerObj.customer_name
												"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												required
											/>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("customerNumber") }}</label
										>
										<div>
											<input
												type="text"
												disabled="true"
												v-model="
													customerObj.customer_number
												"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												required
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("customerEmail") }}</label
										>
										<div>
											<input
												type="email"
												v-model="customerObj.email"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												required
											/>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("category") }}</label
										>
										<div>
											<select
												v-model="
													customerObj.category_id
												"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											>
												<option
													:value="cate.id"
													v-for="cate in GET_CUSTOMER_CATEGORY"
													:key="cate.id"
												>
													{{ cate.name }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("maxCreditLimit") }}</label
										>
										<div>
											<input
												type="num"
												min="0"
												v-model="
													customerObj.max_credit_limit
												"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-2 flex justify-end">
							<button
								:disabled="updating"
								:style="
									GET_CUSTOMIZATION &&
									GET_CUSTOMIZATION.button
										? 'background:' +
										  GET_CUSTOMIZATION.button
										: ''
								"
								:class="
									GET_CUSTOMIZATION &&
									GET_CUSTOMIZATION.button
										? ''
										: 'bg-green-500'
								"
								class="w-full flex justify-center text-white align-items-center font-semibold focus:bg-gray-800 text-base px-3 py-2 rounded shadow-sm"
							>
								<span
									v-if="updating"
									class="flex justify-center"
								>
									<svg
										class="animate-spin h-6 w-6 text-white"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											class="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											stroke-width="4"
										></circle>
										<path
											class="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
								</span>
								<span v-else>
									{{ $t("save") }}
								</span>
							</button>
						</div>
					</div>
				</form>
				<div class="flex-1 container">
					<div
						class="row overflow-y-scroll"
						v-infinite-scroll="loadShopCustomerSettings"
						style="height: 80vh"
					>
						<div class="col-md-12">
							<h3 class="mb-1 text-base">
								{{ $t("customerCategoryList") }}
							</h3>
							<div
								class="drawer-category-management table-responsive rounded-lg bg-white border"
							>
								<table
									style="width: 100%"
									id="exampledestroy"
									class="table table-hover table-bordered"
								>
									<thead>
										<tr>
											<th>
												<input
													type="checkbox"
													name="checkboxAll"
													class="font-xs checkbox-height"
												/>
											</th>
											<th>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													{{ $t("customerName")
													}}<span
														class="flex flex-col"
														><i
															@click="
																sortCusNameDrawerAsc
															"
															class="lnr-chevron-up cursor-pointer small"
														></i
														><i
															@click="
																sortCusNameDrawerDsc
															"
															class="lnr-chevron-down cursor-pointer small"
														></i
													></span>
												</div>
											</th>
											<th>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													{{ $t("email")
													}}<span
														class="flex flex-col"
														><i
															@click="
																sortEmailDrawerAsc
															"
															class="lnr-chevron-up cursor-pointer small"
														></i
														><i
															@click="
																sortEmailDrawerDsc
															"
															class="lnr-chevron-down cursor-pointer small"
														></i
													></span>
												</div>
											</th>
											<th>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													{{ $t("category")
													}}<span
														class="flex flex-col"
														><i
															@click="
																sortCateDrawerAsc
															"
															class="lnr-chevron-up cursor-pointer small"
														></i
														><i
															@click="
																sortCateDrawerDsc
															"
															class="lnr-chevron-down cursor-pointer small"
														></i
													></span>
												</div>
											</th>
											<th>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													{{ $t("maxCreditLimit")
													}}<span
														class="flex flex-col"
														><i
															@click="
																sortMaxLimitDrawerAsc
															"
															class="lnr-chevron-up cursor-pointer small"
														></i
														><i
															@click="
																sortMaxLimitDrawerDsc
															"
															class="lnr-chevron-down cursor-pointer small"
														></i
													></span>
												</div>
											</th>
											<th class="text-xs">Relevant ÅF</th>
										</tr>
									</thead>
									<tbody>
										<tr
											class="currsor-pointer"
											:title="$t('clickToEdit')"
											v-for="(
												customer, i
											) in GET_SHOP_CUSTOMERS_DRAWER_SETTINGS.data"
											:key="i"
											@click="rowUpdateClick(customer)"
										>
											<td>
												<input
													type="checkbox"
													class="font-xs checkbox-height"
												/>
											</td>
											<!-- <td>
                                                <span v-if="customer.organization_number === ''">---</span>
                                                <span v-else>{{ customer.organization_number }}</span>
                                            </td> -->
											<td>
												{{ customer.customer_name }}
											</td>
											<td>{{ customer.email }}</td>
											<td>
												<span
													v-if="
														customer.category ===
														null
													"
													>---</span
												><span v-else>{{
													customer.category.name
												}}</span>
											</td>
											<td>
												{{ customer.max_credit_limit }}
											</td>
											<td>{{ customer.is_relevant }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p
								class="mb-3 mt-1 flex justify-center space-x-3 text-center text-gray-500"
								v-if="loadingShopCustomers"
							>
								<span>
									<svg
										class="animate-spin mr-2 h-5 w-5 text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											class="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											stroke-width="4"
										></circle>
										<path
											class="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
								</span>
								Loading...
							</p>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>

		<el-drawer
			:visible.sync="articleDrawarOpener"
			class="category-style"
			:size="`${articleSize}%`"
			custom-class="notifiy-drawer-style"
			:direction="direction"
		>
			<div class="flex flex-col dm-sans-font">
				<div class="container flex justify-between mb-1">
					<div class="font-semibold text-2xl">
						<span v-if="articleTab === 1">{{
							$t("specification")
						}}</span>
						<span v-if="articleTab === 2">{{
							$t("articleDetail")
						}}</span>
					</div>
					<div>
						<div
							class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl"
						>
							<span
								data-cy="tab1"
								@click="articleTab = 1"
								class="text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
								:class="
									articleTab === 1
										? 'bg-blue-900 text-white'
										: 'bg-white text-gray-900'
								"
								>{{ $t("specification") }}</span
							>
							<span
								data-cy="tab2"
								@click="articleTab = 2"
								class="v-article-7 text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2"
								:class="
									articleTab === 2
										? 'bg-blue-900 text-white'
										: 'bg-white text-gray-900'
								"
								>{{ $t("articleDetail") }}</span
							>
							<span
								data-cy="tab2"
								@click="articleTab = 3"
								class="v-article-7 text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2"
								:class="
									articleTab === 3
										? 'bg-blue-900 text-white'
										: 'bg-white text-gray-900'
								"
								>{{ $t("productArticleCategoryAssign") }}</span
							>
							<span
								data-cy="tab2"
								@click="articleTab = 4"
								class="v-article-7 text-xs h-10 flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
								:class="
									articleTab === 4
										? 'bg-blue-900 text-white'
										: 'bg-white text-gray-900'
								"
								>{{ $t("productImages") }}</span
							>
						</div>
					</div>
				</div>
				<article-specification
					v-if="articleTab === 1"
					@editArticleSpecification="editArticleSpecification"
					:article_id="articleObjData.id"
					:key="articleObjData.id"
				/>
				<article-edit-component
					:article-specification-obj="articleObjData"
					v-if="articleTab === 2"
					:article_id="articleObjData.id"
					:key="articleObjData.id"
				/>
				<div class="container mt-3" v-if="articleTab === 3">
					<article-category-assign-component
						:article-id="articleObjData.id"
						:key="new Date().getMilliseconds()"
						:select-state="'single'"
						class="bg-white"
					/>
				</div>
				<div v-if="articleTab === 4" class="container mt-3">
					<article-images
						:article="articleObjData"
						:key="articleObjData.id"
					/>
				</div>
			</div>
		</el-drawer>
		<el-drawer
			:visible.sync="customerDrawerOpener"
			class="category-style"
			:size="`${customerCategorySize}%`"
			custom-class="notifiy-drawer-style"
			:direction="direction"
		>
			<div class="flex flex-col dm-sans-font">
				<div class="container flex justify-between mb-1">
					<div class="font-semibold text-2xl">
						<span>{{ $t("customerDeliveryAddress") }}</span>
					</div>
				</div>
				<customer-delivery-address
					:customer_id="customerObjData.id"
					:key="customerObjData.id"
					:customer="customerObjData"
				/>
			</div>
		</el-drawer>

		<el-drawer
			:title="$t('customerCategoryManagement')"
			class="category-style"
			:visible.sync="customerCategoryDrawer"
			:size="`${customerCategorySize}%`"
			custom-class="notifiy-drawer-style"
			:direction="direction"
		>
			<div class="flex flex-col">
				<div
					class="absolute inset-0 z-40"
					@click="modal = false"
					v-if="modal"
				></div>
				<!-- <div class="absolute bg-red-30 inset-0 z-10" ></div> -->
				<form
					class="flex-none container"
					v-on:submit.prevent="createCustomerCategory"
				>
					<div class="row mb-4 align-items-center bg-white m-2 p-2">
						<div class="col-md-9 flex flex-col">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{
												$t("customerCategoryName")
											}}</label
										>
										<div>
											<input
												type="text"
												v-model="cateName"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												required
											/>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<div
											style="z-index: 99999"
											class="w-4/5 absolute bg-white shadow border rounded mt-16"
											v-if="
												modal &&
												filteredAccount.length > 0
											"
										>
											<ul>
												<li
													class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
													v-for="(
														item, i
													) in filteredAccount.slice(
														0,
														5
													)"
													:key="i"
													@click="setAccount(item)"
												>
													{{
														item.number +
														" (" +
														item.description +
														")"
													}}
												</li>
											</ul>
										</div>
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("accountNumber") }}</label
										>
										<div>
											<input
												type="text"
												v-model="accountNumber"
												autocomplete="off"
												@focus="modal = true"
												@input="filterAccnt"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												required
											/>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label
											class="col-form-label font-bold whitespace-nowrap text-xs"
											>{{ $t("selectCostCenter")
											}}<span class="text-gray-400">
												({{ $t("optional") }})</span
											>
										</label>
										<div>
											<el-select
												v-model="ccValue"
												filterable
												:placeholder="$t('select')"
												class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
											>
												<el-option
													v-for="(
														item, key
													) in GET_COST_CENTER.results"
													v-bind:key="key"
													:clearable="true"
													:label="item.description"
													:value="item.id"
												>
												</el-option>
											</el-select>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-3">
							<div class="form-group mt-4">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-green-500'
									"
									class="w-full text-white px-3 py-2 rounded-md whitespace-nowrap"
								>
									<span
										v-if="updating"
										class="flex justify-center"
									>
										<svg
											class="animate-spin h-6 w-6 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
									</span>
									<span v-else class="text-xs">
										{{ $t("addCategory") }}
									</span>
								</button>
							</div>
						</div>
					</div>
				</form>
				<div class="flex-1 container">
					<div class="row overflow-y-scroll" style="height: 67vh">
						<div class="col-md-12">
							<!-- <h3 class="mb-1">{{ $t('customerCategoryList') }}</h3> {{}}-->
							<div
								class="drawer-category-management table-responsive rounded-lg bg-white border"
							>
								<table
									style="width: 100%"
									id="exampledestroy"
									class="table table-hover table-bordered"
								>
									<thead>
										<tr>
											<th>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													{{ $t("category")
													}}<span
														class="flex flex-col"
														><i
															v-on:click="
																sortCustomerAsc
															"
															class="lnr-chevron-up cursor-pointer small"
														></i
														><i
															v-on:click="
																sortCustomerDsc
															"
															class="lnr-chevron-down cursor-pointer small"
														></i
													></span>
												</div>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												category, i
											) in GET_CUSTOMER_CATEGORY.results"
											:key="i"
										>
											<td>
												<span v-if="category">{{
													category.name
												}}</span>
											</td>
											<td>
												<div
													class="flex justify-center space-x-2"
												>
													<span
														v-on:click="
															getSingleCustomerCategory(
																category
															)
														"
														class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer"
													>
														<img
															src="./../../assets/images/icons/edit.png"
															alt=""
													/></span>
													<span
														v-on:click="
															deleteCustomerCategory(
																category
															)
														"
														class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer"
													>
														<img
															src="./../../assets/images/icons/delete.png"
															alt=""
														/>
													</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>

		<el-drawer
			:title="$t('orderManagement')"
			class="category-style"
			:visible.sync="editOrderDrawerOpener"
			:size="orderSize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<order-edit-form :key="componentID" :order="orderInfo" />
		</el-drawer>
		<el-drawer
			:title="$t('createOrder')"
			class="category-style"
			:visible.sync="createOrderDrawerOpener"
			:size="orderSize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<order-create-form :key="componentID" />
		</el-drawer>
		<el-drawer
			:title="$t('createArticle')"
			class="category-style"
			:visible.sync="createArticleDrawerOpener"
			:size="articleSize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<!--			<order-create-form :key="componentID"/>-->
			<create-article-component :key="componentID" />
		</el-drawer>
		<el-drawer
			:title="$t('updateCustomer')"
			class="category-style"
			:visible.sync="updateCustomerDrawerOpener"
			:size="articleSize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<edit-customer :customer="customerObj" :key="componentID" />
		</el-drawer>
		<el-drawer
			:title="$t('createCustomer')"
			class="category-style"
			:visible.sync="createCustomerDrawerOpener"
			:size="articleSize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<create-customer :key="componentID" />
		</el-drawer>
		<el-drawer
			:title="$t('relevantArticleCustomers')"
			class="category-style"
			:visible.sync="openCustomerSpecificDrawer"
			:size="customerCategorySize + '%'"
			custom-class="notifiy-drawer-style bg-white"
			:direction="direction"
		>
			<assign-customer-to-article
				:article_id="modalRelevantArticleNumber"
				:key="componentID"
			/>
		</el-drawer>

		<el-drawer
			:title="$t('blogPreviewTitle')"
			:visible="blogPostPreview"
			@close="beforeCloseBlogPreview"
			custom-class="notifiy-drawer-style"
			:direction="direction"
			:modal="false"
			size="40%"
		>
			<div>
				<div class="container pl-3">
					<div
						class="absolute inset-0 z-40"
						@click="modal = false"
						v-if="modal"
					></div>
					<div class="row">
						<div class="col-md-12 col-lg-12">
							<div
								class="flex justify-center"
								v-if="blogPostPreviewImage !== ''"
							>
								<el-image
									v-if="blogPostPreviewImage !== ''"
									style="width: 300px; height: 300px"
									:src="blogPostPreviewImage"
								>
								</el-image>
							</div>
							<div class="flex flex-col mt-3 mb-2">
								<span
									class="font-semibold text-gray-800 text-lg previewTitle"
									v-if="blogPostContent.title"
									>{{ blogPostContent.title }}</span
								><br />
								<div v-html="blogPostContent.content"></div>
							</div>
							<span
								class="mt-3"
								v-if="blogPostContent.published !== ''"
								>{{ $t("published") }}:
								<span>{{
									blogPostContent.published
								}}</span></span
							>
							<br /><br />
							<span
								class="mt-2 mr-2"
								v-if="blogPostContent.category !== ''"
								>Category:
								<span
									class="ml-2"
									v-html="
										renderArrays(blogPostContent.category)
									"
								></span
							></span>
							<br /><br />
							<span
								class="mt-1"
								v-if="blogPostContent.tags !== ''"
								>Tags:
								<span
									class="ml-2"
									v-html="renderArrays(blogPostContent.tags)"
								></span
							></span>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>

		<FlashMessage />
		<v-tour
			name="dashboard"
			:steps="dashboardStep"
			:options="myOptions"
			:callbacks="dashboardTourCallback"
		></v-tour>
		<v-tour
			name="article"
			:steps="ArticleStep"
			:options="myOptions"
			:callbacks="articleCallback"
		></v-tour>
		<v-tour
			name="invoice"
			:steps="invoiceStep"
			:options="myOptions"
			:callbacks="invoiceCallback"
		></v-tour>
		<v-tour
			name="customer"
			:steps="customerStep"
			:options="myOptions"
			:callbacks="customerCallback"
		></v-tour>
		<!-- <v-tour name="users" :steps="usersStep" :options="myOptions" :callbacks="usersCallback"></v-tour> -->
		<v-tour
			name="company_setting"
			:steps="tab1"
			:options="myOptions"
			:callbacks="callbackTab1"
		></v-tour>
		<v-tour
			name="companyTab2"
			:steps="companySettingStep.tab2"
			:options="myOptions"
			:callbacks="companySettingStep.callbackTab2"
		></v-tour>
		<v-tour
			name="companyTab3"
			:steps="companySettingStep.tab3"
			:options="myOptions"
			:callbacks="companySettingStep.callbackTab3"
		></v-tour>
		<v-tour
			name="companyTab4"
			:steps="companySettingStep.tab4"
			:options="myOptions"
			:callbacks="companySettingStep.callbackTab4"
		></v-tour>
		<!-- <v-tour name="subscription" :steps="subscriptionStep" :options="myOptions" :callbacks="subscriptionCallback"></v-tour> -->
		<!-- <v-tour name="logs" :steps="logsStep" :options="myOptions" :callbacks="logsCallback"></v-tour> -->
	</div>
</template>

<script>
import Vue, { reactive, computed } from "vue";
import { mapGetters } from "vuex";
import { DatePicker } from "element-ui";
import { v4 as uuidv4 } from "uuid";
import Flickity from "vue-flickity";
import bus from "./../../bus";
import http from "@/services/vue-axios/axios";
import endpoints from "@/services/endpoints";
import NavbarDash from "@/components/Navbar";
import SidebarDash from "@/components/Sidebar";
import FooterDash from "@/components/Footer";
import FlashMessage from "@/customComponent/FlashMessage";
import ModalContainer from "./../../customComponent/Modal";
import ImageModal from "./../../customComponent/ImageModal";
import CategoryParentTableBody from "./components/CategoryParentTableBody";
import CategoryChildTableBody from "./components/CategoryChildTableBody";
import * as JSZip from "jszip";
import { EXCEL_HEADER_FORMAT } from "@/services/constant";
import ArticleSpecification from "@/views/portal/ArticleSpecification";
import ModalNote from "./../../customComponent/NoteModal";
import articleEditComponent from "@/views/portal/articleEditComponent";
import CustomerDeliveryAddress from "@/views/portal/CustomerDeliveryAddress";
import ArticleCategoryStructure from "./ArticleCategoryStructure";
import EditComponentTree from "./components/EditComponentTree";
import ArticleCategoryAssignComponent from "./components/ArticleCategoryAssignComponent";
import { getCompanyId } from "../../services/storage-window";
import orderCreateForm from "./components/orderComponent/orderCreateForm";
import isScreen from "../../services/screen-helper/screenHelper";
import orderEditForm from "./components/orderComponent/orderEditForm";
import addUnitTypeComponent from "./components/unitTypeComponent/addUnitTypeComponent";
import updateUnitTypeComponent from "./components/unitTypeComponent/updateUnitTypeComponent";
import linkedProduct from "./components/blogComponent/linkedProduct";
import createArticleComponent from "./components/article/createArticleComponent";
import createCustomer from "./components/customerComponent/createCustomer";
import editCustomer from "./components/customerComponent/editCustomer";
import articleImages from "./components/article/articleImages";
import assignCustomerToArticle from "./components/article/assignCustomerToArticle";
import { VueEditor, Quill } from "vue2-editor";

Vue.use(DatePicker);

export default {
	name: "main-root-dash",
	components: {
		NavbarDash,
		SidebarDash,
		FooterDash,
		ModalContainer,
		ImageModal,
		Flickity,
		articleEditComponent,
		CategoryParentTableBody,
		CategoryChildTableBody,
		FlashMessage,
		CustomerDeliveryAddress,
		ModalNote,
		ArticleSpecification,
		ArticleCategoryStructure,
		EditComponentTree,
		ArticleCategoryAssignComponent,
		orderCreateForm,
		orderEditForm,
		addUnitTypeComponent,
		linkedProduct,
		updateUnitTypeComponent,
		createArticleComponent,
		createCustomer,
		editCustomer,
		articleImages,
		VueEditor,
		assignCustomerToArticle,
		Notification: () => import("./../../customComponent/NotificationAlert"),
	},
	data() {
		const discount_types = reactive({
			types: computed(() => {
				return [
					{
						label: this.$t("discountAmount"),
						id: "AMOUNT",
					},
					{
						label: this.$t("discountPercentage"),
						id: "PERCENT",
					},
				];
			}),
		});
		return {
			onlyMyDiscount: {},
			DISCOUNT_TYPES: discount_types.types,
			imageBaseServerURL: "https://api.b2bportalen.se/media/",
			showFullCover: false,
			modalArticleBulkUpload: false,
			CustomerEditModal: false,
			appConnectModal: false,
			CustomerCreateModal: false,
			fileList: [],
			modal: false,
			addNewManagement: false,
			articleTab: 2,
			articleCategoryKeyLocker: "",
			modalRelevantArticleNumber: "",

			flickityOptions: {
				initialIndex: 3,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false,
				draggable: ">1",
				fullscreen: true,
				// any options from Flickity can be used
			},
			customToolbar: [
				["bold", "italic", "underline"],
				[{ list: "ordered" }, { list: "bullet" }],
				["code-block"],
			],
			baseURL: window.location.origin,
			firstname: "",
			lastname: "",
			systemMode: "",
			email: "",
			articleCategoryValue: [],
			// accountNumber : '',
			role: "Select role",
			cateName: "",
			accountNumber: "",
			costCenterNumber: "",
			ccValue: "",
			formData: {
				id: null,
				email: "",
				category_id: "",
				customer_name: "",
				customer_number: "",
				organization_number: "",
			},
			customerCategory: [],
			updateUsers: { first_name: "", last_name: "", email: "", id: null },
			modalInviteCustomer: false,
			modalProductCategory: false,
			modalAssignProductCategory: false,
			modalEditAssignProductCategory: false,
			modalEditCategory: false,
			modalEditChildCategory: false,
			modalEditCustomerCategory: false,
			addingSubCategory: false,
			modalNote: false,
			articleImageView: {},
			relevantCustomerObj: {},
			categoryForm: {},
			childCategoryForm: {},
			filteredAccount: [],
			dateRangeFilter: "",
			parentCategory: [],
			parentCategoryDict: [],
			childCategory: [],
			childCategoryDict: [],
			subCategoryForm: {
				name: "",
				parentCategory: "",
			},
			assignMultipleCustomerCategory: false,
			productCategoryMassAssignForm: {
				category: [],
			},
			productCategoryForm: {
				name: "",
				account: null,
				costCenter: null,
			},
			addingProductCategory: false,
			loadingShopCustomers: false,
			loadingMoreCategory: false,
			selectAccount: "",
			modalUsers: false,
			modalArticleImages: false,
			modalArticleEdit: false,
			modalCustomerEdit: false,
			modalRelevantCustomerEdit: false,
			customerSearchName: "",
			productSearchName: "",
			creating: false,
			loadingUpload: false,
			adding: false,
			modalVisible: false,
			modalImage: false,
			addingUsers: false,
			modalAddUsers: false,
			assigningProduct: false,
			userObject: null,
			userPhoto: {},
			EditcustomerCategory: {},
			showCrop: false,
			croppieImage: null,
			initializingCropping: false,
			croppingSuccess: false,
			showPreviewCropping: false,
			orderSlider: false,
			uploadingNow: false,
			updating: false,
			subId: null,
			multipleAssign: false,
			productImages: [],
			excelFile: [],
			dialogImageUrl: false,
			modalSubCategory: false,
			modalRelevantArticle: false,
			modalRelevantBlog: false,
			modalRelevantBlogNumber: null,
			modalRelevantArticleObject: { allowed_customer_numbers: [] },
			modalRelevantBlogObject: { allowed_customer_numbers: [] },
			loadingRelevantArticle: true,
			loadingRelevantBlog: true,
			loadingBlogProductRecommend: true,
			selectedCustomers: [],
			filteredCustomer: [],
			filteredProducts: [],
			openCustomersDropdown: false,
			openBlogProductDropdown: false,
			openBlogsDropdown: false,
			articleObj: {},
			articleEditObj: {},
			customerObj: {},
			direction: "rtl",
			cateForm: {
				category: "",
				value: "",
				parent: null,
			},
			addUserForm: {
				firstname: "",
				lastname: "",
				email: "",
			},
			openBlogCategory: false,
			blogForm: {
				name: "",
			},
			checkboxAll: false,
			statusUpdate: false,
			addingRelevant: false,
			updateCategoryBtn: false,
			updateChildCategoryBtn: false,
			openArticleDrawer: false,
			openCustomerDrawer: false,
			openOrderDrawer: false,
			myOptions: {
				useKeyboardNavigation: false,
				// startTimeout : 1000,
				labels: {
					buttonNext: this.$t("nextTour"),
					buttonPrevious: this.$t("previousTour"),
					buttonSkip: this.$t("skipTour"),
					buttonStop: this.$t("finishedTour"),
				},
			},
			dashboardStep: [
				{
					target: ".v-step-0",
					content: this.$t("dashboardTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-article-1",
					content: this.$t("articleTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-invoice-1",
					content: this.$t("invoiceTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-customer-1",
					content: this.$t("customerTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-invite-1",
					content: this.$t("inviteTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-user-1",
					content: this.$t("userTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-blog-1",
					content: this.$t("blogTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-company-setting-1",
					content: this.$t("companySettingTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-subscription-1",
					content: this.$t("subscriptionTourMenu"),
					params: {
						placement: "right",
					},
				},
				{
					target: ".v-logs-1",
					content: this.$t("logsTourMenu"),
					params: {
						placement: "right",
					},
				},
			],
			dashboardTourCallback: {
				onSkip: this.completeDashboardTour,
				onFinish: this.completeDashboardTour,
			},
			ArticleStep: [
				{
					target: ".v-article-2",
					content: this.$t("articleTourTableAction"),
				},
				{
					target: ".v-article-3",
					content: this.$t("articleTourTableProductImg"),
				},
				{
					target: ".v-article-4",
					content: this.$t("articleTourTableRelevantProduct"),
				},
				{
					target: ".v-article-5",
					content: this.$t("articleTourTableCheckAll"),
				},
				{
					target: ".v-article-6",
					content: this.$t("articleTourProductCategory"),
				},
				{
					target: ".v-article-7",
					content: this.$t("articleTourPriceManagement"),
				},
			],
			invoiceStep: [
				{
					target: ".v-invoice-2",
					content: this.$t("articleTourTableAction"),
				},
				{
					target: ".v-invoice-3",
					content: this.$t("articleTourTableProductImg"),
				},
			],
			customerStep: [
				{
					target: ".v-customer-2",
					content: this.$t("relevantCustomerTour"),
				},
				{
					target: ".v-customer-3",
					content: this.$t("customerTourTableAction"),
				},
				{
					target: ".v-customer-4",
					content: this.$t("customerTourTableAction"),
				},
				{
					target: ".v-customer-5",
					content: this.$t("customerTourTopCategory"),
				},
			],
			companySettingStep: {
				tab1: [
					{
						target: ".v-company-setting-2",
						content: this.$t("companySettingTourCustomization"),
					},
				],
				tab2: [
					{
						target: ".v-company-setting-3",
						content: this.$t("companySettingTourSetting"),
					},
				],
				tab3: [
					{
						target: ".v-company-setting-4",
						content: this.$t("companySettingTourBilling"),
					},
				],
				tab4: [
					{
						target: ".v-company-setting-5",
						content: this.$t("companySettingTourAccount"),
					},
				],
				callbackTab1: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour,
				},
				callbackTab2: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour,
				},
				callbackTab3: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour,
				},
				callbackTab4: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour,
				},
			},
			articleCallback: {
				onSkip: this.completeArticleTour,
				onFinish: this.completeArticleTour,
			},
			tab1: [
				{
					target: ".v-company-setting-2",
					content: this.$t("companySettingTourCustomization"),
				},
				{
					target: ".v-company-setting-3",
					content: this.$t("companySettingTourSetting"),
				},
				{
					target: ".v-company-setting-4",
					content: this.$t("companySettingTourBilling"),
				},
				{
					target: ".v-company-setting-5",
					content: this.$t("companySettingTourAccount"),
				},
			],
			callbackTab1: {
				onSkip: this.completecompanyTab1Tour,
				onFinish: this.completecompanyTab1Tour,
			},
			customerCallback: {
				onSkip: this.completeCustomerTour,
				onFinish: this.completeCustomerTour,
			},
			usersCallback: {
				onSkip: this.completeUserTour,
				onFinish: this.completeUserTour,
			},
			invoiceCallback: {
				onSkip: this.completeInvoiceTour,
				onFinish: this.completeInvoiceTour,
			},
			subCategoryObj: [],
			assignedCustomerCategory: "",
			blogPostPreview: false,
			updateBtn: false,
			openEditBlogCategory: false,
			blogPostContent: {},
			blogPostPreviewImage: "",
			progressPercentage: 0,
			totalImagesToUpload: 0,
			estimatedTime: 0,
			articleContent: {},
			articleObjData: {},
			customerObjData: {},
			options: {
				text: {
					color: "#FFFFFF",
					shadowEnable: true,
					shadowColor: "#000000",
					fontSize: 14,
					fontFamily: "Helvetica",
					dynamicPosition: false,
					hideText: false,
				},
				progress: {
					color: "#2dbd2d",
					backgroundColor: "#333333",
					inverted: false,
				},
				layout: {
					height: 35,
					width: 140,
					verticalTextAlign: 61,
					horizontalTextAlign: 43,
					zeroOffset: 0,
					strokeWidth: 30,
					progressPadding: 0,
					type: "line",
				},
			},
			allImages: [],
			allZipImages: [],
			articleSpecificationData: {},
			modalAddDeliveryAddress: false,
			modalEditDeliveryAddress: false,
			deliveryAddress: {
				location_name: "",
				street_address: "",
				zip_code: "",
				country: "",
				city: "",
				phone_number: "",
				customer_id: "",
			},
			openArticleSpecificationData: false,
			orderObj: { message: "", seller_note: "" },
			countries: [],
			openFaqAddModal: false,
			openFaqEditModal: false,
			openFaqCategoryAddModal: false,
			openFaqCategoryEditModal: false,
			faqID: "",
			catID: "",
			faqObj: {
				question: "",
				answer: "",
				category: [],
				seller_relevant: false,
				buyer_relevant: false,
			},
			catObj: {
				title: "",
				seller_relevant: false,
				buyer_relevant: false,
			},
			companySettingObj: {},
			companyCategoryObj: [],
			CategoryTree: {},
			openCategoryTreeStatus: false,
			singleProductCategoryAssign: false,
			articleId: null,
			articleCategoryKey: null,
			articleCategorySelectState: null,
			selectedProducts: [],
			modalBlogProductRecommend: false,
			componentID: "",
			orderInfo: {},
			unitInfo: {},
			orderSize: 95,
			articleSize: 70,
			customerCategorySize: 60,
			customerSettingSize: 80,
			articleCategoryTreeSize: 94,
			openNewUnitModal: false,
			openUpdateUnitModal: false,
			openBlogRecommendedProduct: false,
			openCreateArticleDrawer: false,
			blogRecommendedProduct: {},
		};
	},
	watch: {
		$route(to, from) {
			const { key } = to.query;
			if (key !== undefined && key !== null) {
				this.subId = key;
	
			}
		},
		GET_LOCALE_LANG: function (newVal, oldVal) {},
		modal_photo: function (newVal, _) {
			if (newVal === true) {
				this.loadingUpload = true;
				setTimeout(() => {
					const fileElem = document.getElementById("upload-open");
					if (fileElem) {
						fileElem.click();
						this.loadingUpload = false;
					}
				}, 1000);
			}
		},
		modalVisible: function (newVal, _) {
			if (newVal === false) {
				this.clearCategoryData();
			}
		},
		modal: function (newV, _) {
			if (newV === true && this.filteredAccount.length > 0) {
				if (document.getElementById("height-inc")) {
					document
						.getElementById("height-inc")
						.classList.add("height-increase");
				}
				if (document.getElementById("height-inc-product")) {
					document
						.getElementById("height-inc-product")
						.classList.add("height-increase");
				}
			} else {
				if (!newV) {
					if (document.getElementById("height-inc")) {
						document
							.getElementById("height-inc")
							.classList.remove("height-increase");
					}
					if (document.getElementById("height-inc-product")) {
						document
							.getElementById("height-inc-product")
							.classList.remove("height-increase");
					}
				}
			}
		},
	},
	computed: {
		modal_invite: {
			get: function () {
				return this.MODAL_INVITE;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_INVITE_MODAL");
			},
		},
		modal_photo: {
			get: function () {
				return this.MODAL_PHOTO;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_PHOTO_MODAL");
			},
		},
		modal_util: {
			get: function () {
				return this.MODAL_UTIL;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_MODAL_UTIL");
			},
		},
		categoryDrawer: {
			get: function () {
				return this.GET_CATEGORY_DRAWER;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_SIDEDRAWER");
			},
		},
		customerSettingDrawer: {
			get: function () {
				return this.GET_CUSTOMER_SETTING_DRAWER;
			},
			set: function () {
				return this.$store.commit(
					"utility/OPEN_CUSTOMER_SETTING_DRAWER"
				);
			},
		},
		customerCategoryDrawer: {
			get: function () {
				return this.GET_CUSTOMER_CATEGORY_DRAWER;
			},
			set: function () {
				return this.$store.commit(
					"utility/OPENE_CUSTOMER_CATEGRY_DRAWER"
				);
			},
		},
		blogDrawer: {
			get: function () {
				return this.GET_BLOG_DRAWER;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_BLOG_DRAWER", {
					status: false,
					content: {},
				});
			},
		},
		articleDrawarOpener: {
			get: function () {
				return this.openArticleDrawer;
			},
			set: function () {
				this.openArticleDrawer = !this.openArticleDrawer;
			},
		},
		customerDrawerOpener: {
			get: function () {
				return this.openCustomerDrawer;
			},
			set: function () {
				this.openCustomerDrawer = !this.openCustomerDrawer;
			},
		},
		orderDrawerOpener: {
			get: function () {
				return this.GET_ORDER_DETAIL_DRAWER;
			},
			set: function () {
				return this.$store.commit("utility/OPEN_ORDER_DETAIL_DRAWER");
			},
		},
		createOrderDrawerOpener: {
			get: function () {
				return this.GET_CREATE_ORDER_DRAWER;
			},
			set: function () {
				if (this.GET_CREATE_ORDER_DRAWER) {
					return this.$store.commit(
						"utility/OPEN_CREATE_ORDER_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/OPEN_CREATE_ORDER_DRAWER",
						true
					);
				}
			},
		},
		createArticleDrawerOpener: {
			get: function () {
				return this.GET_CREATE_ARTICLE_DRAWER;
			},
			set: function () {
				if (this.GET_CREATE_ARTICLE_DRAWER) {
					return this.$store.commit(
						"utility/OPEN_CREATE_ARTICLE_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/OPEN_CREATE_ARTICLE_DRAWER",
						true
					);
				}
			},
		},
		createCustomerDrawerOpener: {
			get: function () {
				return this.GET_CREATE_CUSTOMER_DRAWER;
			},
			set: function () {
				if (this.GET_CREATE_CUSTOMER_DRAWER) {
					return this.$store.commit(
						"utility/OPEN_CREATE_CUSTOMER_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/OPEN_CREATE_CUSTOMER_DRAWER",
						true
					);
				}
			},
		},
		updateCustomerDrawerOpener: {
			get: function () {
				return this.GET_EDIT_CUSTOMER_DRAWER;
			},
			set: function () {
				if (this.GET_EDIT_CUSTOMER_DRAWER) {
					return this.$store.commit(
						"utility/OPEN_EDIT_CUSTOMER_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/OPEN_EDIT_CUSTOMER_DRAWER",
						true
					);
				}
			},
		},
		editOrderDrawerOpener: {
			get: function () {
				return this.GET_EDIT_ORDER_DRAWER;
			},
			set: function () {
				if (this.GET_EDIT_ORDER_DRAWER) {
					return this.$store.commit(
						"utility/OPEN_EDIT_ORDER_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/OPEN_EDIT_ORDER_DRAWER",
						true
					);
				}
			},
		},
		openCustomerSpecificDrawer: {
			get: function () {
				return this.GET_CUSTOMER_SPECIFIC_SLIDER;
			},
			set: function () {
				if (this.GET_CUSTOMER_SPECIFIC_SLIDER) {
					return this.$store.commit(
						"utility/UPDATE_CUSTOMER_SPECIFIC_DRAWER",
						false
					);
				} else {
					return this.$store.commit(
						"utility/UPDATE_CUSTOMER_SPECIFIC_DRAWER",
						true
					);
				}
			},
		},
		...mapGetters({
			USER_TOKEN: "auth/USER_TOKEN",
			GET_COST_CENTER: "portal/GET_COST_CENTER",
			GET_CUSTOMER_CATEGORY: "portal/GET_CUSTOMER_CATEGORY",
			GET_ACTIVE_ACCOUNTS: "portal/GET_ACTIVE_ACCOUNTS",
			GET_PRODUCT_CATEGORY: "portal/GET_PRODUCT_CATEGORY",
			GET_PRODUCT_CATEGORY_CHECK: "portal/GET_PRODUCT_CATEGORY_CHECK",
			GET_ARTICLES_CHECKED: "portal/GET_ARTICLES_CHECKED",
			GET_SHOP_CUSTOMERS: "portal/GET_SHOP_CUSTOMERS",
			GET_SHOP_CUSTOMERS_DRAWER: "portal/GET_SHOP_CUSTOMERS_DRAWER",
			GET_SHOP_CUSTOMERS_DRAWER_SETTINGS:
				"portal/GET_SHOP_CUSTOMERS_DRAWER_SETTINGS",
			GET_SHOP_CUSTOMERS_CHECKED: "portal/GET_SHOP_CUSTOMERS_CHECKED",
			MODAL_INVITE: "utility/MODAL_INVITE",
			MODAL_PHOTO: "utility/MODAL_PHOTO",
			MODAL_UTIL: "utility/MODAL_UTIL",
			GET_ORDER_DETAIL_DRAWER: "utility/GET_ORDER_DETAIL_DRAWER",
			GET_CUSTOMER_SETTING_DRAWER: "utility/GET_CUSTOMER_SETTING_DRAWER",
			GET_CUSTOMER_CATEGORY_DRAWER:
				"utility/GET_CUSTOMER_CATEGORY_DRAWER",
			GET_CREATE_CUSTOMER_DRAWER: "utility/GET_CREATE_CUSTOMER_DRAWER",
			GET_EDIT_CUSTOMER_DRAWER: "utility/GET_EDIT_CUSTOMER_DRAWER",
			GET_CATEGORY_DRAWER: "utility/GET_CATEGORY_DRAWER",
			GET_ALL_CUSTOMERS: "portal/GET_ALL_CUSTOMERS",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
			GET_SHOPOWNER_TOUR: "portal/GET_SHOPOWNER_TOUR",
			GET_LOCALE_LANG: "utility/GET_LOCALE_LANG",
			GET_ARTICLES: "portal/GET_ARTICLES",
			GET_COPY_ARTICLE: "portal/GET_COPY_ARTICLES",
			GET_SHOPOWNER_TOUR_ALL: "portal/GET_SHOPOWNER_TOUR_ALL",
			GET_COMPANY_SETTING: "portal/GET_COMPANY_SETTING",
			GET_CREATE_ORDER_DRAWER: "utility/GET_CREATE_ORDER_DRAWER",
			GET_EDIT_ORDER_DRAWER: "utility/GET_ORDER_EDIT_DRAWER",
			GET_CREATE_ARTICLE_DRAWER: "utility/GET_CREATE_ARTICLE_DRAWER",
			GET_CUSTOMER_SPECIFIC_SLIDER:
				"utility/GET_CUSTOMER_SPECIFIC_SLIDER",
		}),
	},
	mounted() {

		this.blogForm = { name: "" };
		
		this.countries = this.$services.helpers.getCountryList();
		this.getCompanyDetails();
		this.getCustomerCategory();
		this.getCostCenter();
		// this.getAllArticles()
		this.getAccounts();
		this.getCostCenters();
		let self = this;
		window.addEventListener("resize", function () {
			self.resize();
		});
		bus.$on("open-modal", (e) => {
			this.modalVisible = true;
		});

		bus.$on("delete-user", (e) => {
			this.deleteUser(e);
		});
		window.Bus.$emit("change-article-category-key", (e) => {
			this.articleCategoryKeyLocker = new Date().getMilliseconds();
		});
		bus.$on("update-users", (e) => {
			this.modalUsers = true;
			this.updateUsers = e;
		});

		window.Bus.$on("add-shop-users", (_) => {
			this.modalAddUsers = true;
		});

		bus.$on("open-invite-customer", (e) => {
			this.modalInviteCustomer = true;
			this.formData = e;
			this.formData.category_id = e.category;
		});
		bus.$on("open-article-modal", (e) => {
			this.modalArticleImages = true;
			this.articleObj = e;
		});
		window.Bus.$on("open-article-edit-modal", (e) => {
			this.modalArticleEdit = true;
			this.articleEditObj = e;
			if (
				e.product_name === "" ||
				e.product_name === undefined ||
				e.product_name === null
			) {
				this.articleEditObj.product_name = e.description;
			}
		});
		window.Bus.$on("open-customer-modal", (e) => {
			const eCopy = e;
			if (eCopy.max_credit_limit === 0) {
				eCopy.max_credit_limit = "";
			}
			this.customerObj = eCopy;
			if (this.customerObj.category !== null) {
				this.customerObj.category_id = this.customerObj.category.id;
				if (this.customerObj.category.cost_center !== null) {
					this.costCenterNumber =
						this.customerObj.category.cost_center.id;
				}
			}
			// this.CustomerEditModal = true
			this.componentID = new Date().getMilliseconds();
			this.$store.commit("utility/OPEN_EDIT_CUSTOMER_DRAWER", true);
		});
		window.Bus.$on("sign-out", (_) => {
			if (this.$route.path !== "/") {
				// this.$router.push("/admin");
				this.$store.commit("utility/CHANGE_LOCALE", "se");
				this.$store.commit("auth/CLEAR_AUTH_USER", null);
				this.$router.replace({ name: "login" });
			}
		});

		window.Bus.$on("start-dashboard-tour", (_) => {
			setTimeout(() => {
				this.runDashboardTour();
			}, 1000);
		});

		window.Bus.$on("start-article-tour", (_) => {
			this.runArticleTour();
		});

		window.Bus.$on("start-invoice-tour", (_) => {
			this.runInvoiceTour();
		});

		window.Bus.$on("start-customers-tour", (_) => {
			this.runCustomerTour();
		});
		window.Bus.$on("start-company-tour", (_) => {
			this.runCompanySettingsTour1();
		});
		window.Bus.$on("start-company-tab1-tour", (_) => {
			this.runCompanySettingsTour1();
			this.$tours.appSettingTab2.stop();
			this.$tours.appSettingTab3.stop();
			this.$tours.appSettingTab4.stop();
		});

		window.Bus.$on("start-company-tab2-tour", (_) => {
			this.runCompanySettingsTour2();
			this.$tours.companyTab1.stop();
			this.$tours.companyTab3.stop();
			this.$tours.companyTab4.stop();
		});

		window.Bus.$on("start-company-tab3-tour", (_) => {
			this.runCompanySettingsTour3();
			this.$tours.companyTab1.stop();
			this.$tours.companyTab2.stop();
			this.$tours.companyTab4.stop();
		});

		window.Bus.$on("start-company-tab4-tour", (_) => {
			this.runCompanySettingsTour4();
			this.$tours.companyTab1.stop();
			this.$tours.companyTab2.stop();
			this.$tours.companyTab3.stop();
		});

		window.Bus.$on("start-users-tour", (_) => {
			this.runUsersTour();
		});

		window.Bus.$on("start-subscription-tour", (_) => {
			this.runSubscriptionTour();
		});

		window.Bus.$on("start-logs-tour", (_) => {
			this.runLogsTour();
		});

		window.Bus.$on("open-add-sub-product", (_) => {
			this.modalSubCategory = true;
		});

		window.Bus.$on("open-add-product", (_) => {
			this.modalProductCategory = true;
		});

		window.Bus.$on("show-cover", (e) => {
			if (e === true) {
				this.showFullCover = true;
			} else {
				this.showFullCover = false;
			}
		});

		window.Bus.$on("open-relevant-article-modal", (e) => {
			// this.modalRelevantArticle = true
			this.modalRelevantArticleNumber = e.article;
			this.componentID = new Date().getMilliseconds();
			this.$store.commit("utility/UPDATE_CUSTOMER_SPECIFIC_DRAWER", true);
			// this.getArticleDetail(e.article)
			// this.loadingRelevantArticle = false
		});
		window.Bus.$on("open-relevant-article-filter-modal", (e) => {
			this.modalRelevantArticle = true;
		});

		window.Bus.$on("show-relevant-customer", (e) => {
			if (e.multiple) {
				this.multipleAssign = e.multiple;
				this.relevantCustomerObj.is_relevant = null;
			} else {
				this.relevantCustomerObj = e.data;
				this.multipleAssign = e.multiple;
			}
			this.modalRelevantCustomerEdit = true;
		});

		window.Bus.$on("open-assign-product-category", (e) => {
			// this.modalAssignProductCategory = true
			// this.parentCategory = []
			// this.childCategory = []
			this.articleId = null;
			this.articleCategoryKey = e.key;
			this.articleCategorySelectState = "multiple";
			this.singleProductCategoryAssign = true;
		});
		window.Bus.$on("open-article-edit-category-modal", (e) => {
			this.articleId = e.data.id;
			this.articleCategoryKey = e.key;
			this.articleCategorySelectState = e.state;
			// this.parentCategory = e.parent_category_id.filter(item => item !== null)
			// const childCategory = e.sub_category_id.filter(item => item !== null)
			this.singleProductCategoryAssign = true;
			// const subCAT = []
			// this.GET_PRODUCT_CATEGORY.data.map(item => {
			// 	item.sub_category.map(item => {
			// 		if (childCategory.includes(item.id)) {
			// 			subCAT.push(item)
			// 		}
			// 	})
			// })
			// this.childCategory = subCAT
		});

		window.Bus.$on("open-image-modal", (e) => {
			this.articleImageView = e;
			this.modalImage = true;
		});

		window.Bus.$on("accept-request", (e) => {
			this.acceptUserRequest(e, "accept");
		});

		window.Bus.$on("reject-request", (e) => {
			this.acceptUserRequest(e, "reject");
		});

		window.Bus.$on("update-category-backdrop", (e) => {
			this.statusUpdate = !this.statusUpdate;
		});

		window.Bus.$on("update_delete_checkbox", (e) => {
			if (e.value === true) {
				this.subCategoryObj.push(e);
			} else {
				const ItemIndex = this.subCategoryObj.findIndex(
					(el) => el.id === e.id
				);
				this.subCategoryObj.splice(ItemIndex, 1);
			}
		});

		window.Bus.$on("multiple-assign-category", (_) => {
			this.assignMultipleCustomerCategory =
				!this.assignMultipleCustomerCategory;
		});

		window.Bus.$on("add-blog-category", (_) => {
			this.openBlogCategory = true;
		});
		window.Bus.$on("delete-category-subcategory-event", (e) => {
			if (e.type === "category") {
				this.deleteParentCategory(e.data);
			} else {
				this.deleteChildCategory(e.data);
			}
		});
		window.Bus.$on("update-category-subcategory-event", (e) => {
			this.categoryForm = {};
			this.childCategoryForm = {};
			this.modalEditCategory = false;
			this.modalEditChildCategory = false;
			if (e.type === "category") {
				this.categoryForm = e.data;
				this.modalEditCategory = true;
			} else {
				this.childCategoryForm = e.data;
				this.modalEditChildCategory = true;
			}
		});
		window.Bus.$on("blog-post-preview", (e) => {
			this.blogPostContent = e.data;
			this.blogPostPreview = e.status;
			if (e.section === "create") {
				if (e.data.file) {
					this.getPreviewImage(e.data.file);
				}
			} else {
				if (e.data.file_state === "raw") {
					this.getPreviewImage(e.data.file);
				} else {
					this.blogPostPreviewImage = e.data.file;
				}
			}
		});
		window.Bus.$on("open-edit-blog-category", (e) => {
			this.blogForm = e;
			this.openEditBlogCategory = true;
		});
		window.Bus.$on("open-excel-bulk-upload", (_) => {
			this.totalImagesToUpload = [];
			this.estimatedTime = "";
			this.modalArticleBulkUpload = true;
		});

		bus.$on("open-article-update", (e) => {
			this.openArticleDrawer = true;
			this.articleObjData = e;
			this.articleTab = 2;
			// this.articleCategoryValue = e.parent_category_id.filter(item => item !== null)
			// this.dateRangeFilter = []
			// if (e.scheduled_campaign_start_date && e.scheduled_campaign_end_date) {
			// 	this.dateRangeFilter = [e.scheduled_campaign_start_date, e.scheduled_campaign_end_date]
			// }
			this.getProductCategory();
		});
		window.Bus.$on("open-customer-drawer", (e) => {
			this.openCustomerDrawer = true;
			this.customerObjData = e;
		});
		window.Bus.$on("open-add-customer-delivery", (e) => {
			this.deliveryAddress = {
				location_name: "",
				street_address: "",
				zip_code: "",
				country: "",
				city: "",
				phone_number: "",
				is_default_address: false,
				customer_id: e,
			};
			this.modalAddDeliveryAddress = true;
		});
		window.Bus.$on("open-edit-customer-delivery", (e) => {
			this.deliveryAddress = e;
			this.modalEditDeliveryAddress = true;
		});
		window.Bus.$on("open-modal-seller-note", (e) => {
			this.orderObj = e;
			this.modalNote = true;
		});

		window.Bus.$on("open-add-faq-modal", (e) => {
			this.getCompanyDetails();
			this.faqObj = {
				question: "",
				answer: "",
				category: [],
				seller_relevant: false,
				buyer_relevant: false,
			};
			this.openFaqAddModal = true;
		});
		window.Bus.$on("open-edit-faq-modal", (e) => {
			this.getCompanyDetails();
			this.faqID = e.id;
			let category = [];
			e.data.category.map((item) => {
				category.push(item.id);
			});
			this.faqObj = {
				question: e.data.question,
				answer: e.data.answer,
				category: category,
				seller_relevant: false,
				buyer_relevant: false,
			};
			this.openFaqEditModal = true;
		});
		window.Bus.$on("open-add-faq-category-modal", (e) => {
			this.getCompanyDetails();
			this.catObj.title = "";
			this.openFaqCategoryAddModal = true;
		});
		window.Bus.$on("open-edit-faq-category-modal", (e) => {
			this.getCompanyDetails();
			this.catID = e.id;
			this.catObj.title = e.data.title;
			this.openFaqCategoryEditModal = true;
		});
		window.Bus.$on("edit-category-tree", (e) => {
			this.categoryTree = e;
			this.openCategoryTreeStatus = true;
		});
		window.Bus.$on("customer-specific-blog", (e) => {
			this.modalRelevantBlog = true;
			this.modalRelevantBlogNumber = e.blog;
			this.modalRelevantBlogObject = e.blog;
			this.selectedCustomers = [];
			this.selectedCustomers =
				this.modalRelevantBlogObject.allowed_customers;
			this.loadingRelevantBlog = false;
		});
		window.Bus.$on("blog-product-recommend", (e) => {
			this.modalRelevantBlogObject = e.blog;
			this.selectedProducts = e.blog.recommended_products;
			this.modalBlogProductRecommend = true;
			this.loadingBlogProductRecommend = false;
		});
		window.Bus.$on("open-edit-order", (e) => {
			this.componentID = new Date().getMilliseconds();
			this.orderInfo = e;
			this.$store.commit("utility/OPEN_EDIT_ORDER_DRAWER", true);
		});
		window.Bus.$on("open-create-order", (e) => {
			this.componentID = new Date().getMilliseconds();
			this.$store.commit("utility/OPEN_CREATE_ORDER_DRAWER", true);
		});
		window.Bus.$on("open-add-new-unit", (e) => {
			this.openNewUnitModal = true;
		});
		window.Bus.$on("open-update-new-unit", (e) => {
			this.unitInfo = e;
			this.openUpdateUnitModal = true;
		});
		window.Bus.$on("open-linked-product", (e) => {
			this.blogRecommendedProduct = e;
			this.openBlogRecommendedProduct = true;
		});
		window.Bus.$on("open-create-article", (e) => {
			this.componentID = new Date().getMilliseconds();
			this.$store.commit("utility/OPEN_CREATE_ARTICLE_DRAWER", true);
		});
		window.Bus.$on("open-create-customer", (e) => {
			this.componentID = new Date().getMilliseconds();
			this.$store.commit("utility/OPEN_CREATE_CUSTOMER_DRAWER", true);
		});
	},
	beforeMount() {
		if (!this.USER_TOKEN) {
			this.$store.commit("utility/CHANGE_LOCALE", "se");
			this.$store.commit("auth/CLEAR_AUTH_USER", null);
			window.Bus.$emit("sign-out");
		} else {
			this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
		}
	},
	methods: {
		updateCategoryForProduct: function (category) {
			if (category?.id) {
				this.updating = true;
				this.$store
					.dispatch("portal/updateProductCategory", {
						discount_type: this.categoryTree.discount_type,
						discount_value: Number(
							this.categoryTree.discount_value
						),
						id: category?.id,
						name: this.categoryTree.name
					})

					.then((_) => {
						this.updating = false;
						this.openCategoryTreeStatus = false;
						this.$services.helpers.notification(
							this.$t("save"),
							"success",
							this
						);
						this.getCompanyCategory();
						this.articleCategoryKeyLocker =
							new Date().getMilliseconds();
					})
					.catch((err) => {
						this.updating = false;
						this.$services.helpers.notification(
							err.response.data.error ||
								err.response.data.message,
							"error",
							this
						);
					});
			}
		},
		goToIntegration: function () {
			this.appConnectModal = false;
			this.$router.push({
				name: "portal-integrations",
			});
		},
		onSelectChangeEditor: function (range) {},
		resize() {
			if (isScreen("xs") || isScreen("sm") || isScreen("md")) {
				this.orderSize = 100;
				this.articleSize = 100;
				this.customerCategorySize = 100;
				this.customerSettingSize = 100;
				this.articleCategoryTreeSize = 100;
			} else {
				this.orderSize = 95;
				this.articleSize = 70;
				this.customerCategorySize = 60;
				this.customerSettingSize = 80;
				this.articleCategoryTreeSize = 94;
			}
		},
		getCompanyDetails() {
			this.$store
				.dispatch("portal/getCompanyDetails")
				.then((res) => {
					this.companySettingObj = this.GET_COMPANY_SETTING;
					if (this.systemMode !== "main") {
						this.getCompanyCategory();
					}
					if (
						res.data.integration === null &&
						this.$route.name !== "portal-integrations"
					) {
						this.appConnectModal = true;
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		getCompanyCategory() {
			this.$store
				.dispatch("portal/getCompanyFaqCategory", {
					company_id: getCompanyId(),
				})
				.then((res) => {
					this.companyCategoryObj = res.data.data;
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		runDashboardTour() {
			if (!this.GET_SHOPOWNER_TOUR.dashboard) {
				this.$tours.dashboard.start();
			}
		},
		runArticleTour() {
			if (!this.GET_SHOPOWNER_TOUR.article) {
				this.$tours.article.start();
			}
		},
		runInvoiceTour() {
			if (!this.GET_SHOPOWNER_TOUR.invoice) {
				this.$tours.invoice.start();
			}
		},
		runCustomerTour() {
			if (!this.GET_SHOPOWNER_TOUR.customer) {
				this.$tours.customer.start();
			}
		},
		runUsersTour() {
			if (!this.GET_SHOPOWNER_TOUR.users) {
				this.$tours.users.start();
			}
		},
		runCompanySettingsTour1() {
			if (!this.GET_SHOPOWNER_TOUR.companyTab1) {
				this.$tours.company_setting.start();
			}
		},
		runCompanySettingsTour2() {
			if (!this.GET_SHOPOWNER_TOUR.companyTab2) {
				this.$tours.companyTab2.start();
			}
		},
		runCompanySettingsTour3() {
			if (!this.GET_SHOPOWNER_TOUR.companyTab3) {
				this.$tours.companyTab3.start();
			}
		},
		runCompanySettingsTour4() {
			if (!this.GET_SHOPOWNER_TOUR.companyTab4) {
				this.$tours.companyTab4.start();
			}
		},
		runSubscriptionTour() {
			if (!this.GET_SHOPOWNER_TOUR.subscription) {
				this.$tours.subscription.start();
			}
		},
		runLogsTour() {
			if (!this.GET_SHOPOWNER_TOUR.log) {
				this.$tours.logs.start();
			}
		},
		completeAllTour() {
			const payload = {
				dashboard: true,
				article: true,
				invoice: true,
				customer: true,
				users: true,
				companyTab1: true,
				companyTab2: true,
				companyTab3: true,
				companyTab4: true,
				subscription: true,
				log: true,
			};
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
		},
		completeDashboardTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.dashboard = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeArticleTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.article = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeInvoiceTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.invoice = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeLogTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.log = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeSubscriptionTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.subscription = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeUserTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.users = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completeCustomerTour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.customer = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completecompanyTab1Tour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.companyTab1 = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completecompanyTab2Tour() {
			let dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			if (dashboard === null) {
				const payload = {
					dashboard: false,
					article: false,
					invoice: false,
					customer: false,
					users: false,
					companyTab1: false,
					companyTab2: false,
					companyTab3: false,
					companyTab4: false,
					subscription: false,
					log: false,
				};
				this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", payload);
				dashboard = JSON.parse(
					window.localStorage.getItem("__b2bShopOwnerTourGuide__")
				);
			}
			dashboard.companyTab2 = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completecompanyTab3Tour() {
			const dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			dashboard.companyTab3 = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		completecompanyTab4Tour() {
			const dashboard = JSON.parse(
				window.localStorage.getItem("__b2bShopOwnerTourGuide__")
			);
			dashboard.companyTab4 = true;
			this.$store.commit("portal/UPDATE_SHOPOWNER_TOUR_ALL", dashboard);
		},
		selectAndRemoveParentCategory(data) {
			if (
				this.parentCategory.includes(parseInt(data.parent_category_id))
			) {
				// Remove Parent category
				const newParentCategory = this.parentCategory.filter(
					(i) => i !== parseInt(data.parent_category_id)
				);
				this.parentCategory = newParentCategory;
				// Remove Parent category from parent category dictionary
				const newParentCategoryDict = this.parentCategoryDict.filter(
					(item) => item.id !== parseInt(data.parent_category_id)
				);
				this.parentCategoryDict = newParentCategoryDict;
			} else {
				// Add Parent category
				this.parentCategory.push(parseInt(data.parent_category_id));
				this.parentCategoryDict.push({
					id: data.parent_category_id,
					name: data.parent_category_name,
				});
			}
		},
		selectAndRemoveSubCategory(data) {
			const filter = this.childCategory.filter(
				(item) => item.id === parseInt(data.id)
			);
			if (filter.length > 0) {
				const newData = this.childCategory.filter(
					(item) => item.id !== parseInt(data.id)
				);
				this.childCategory = newData;
			} else {
				this.childCategory.push(data);
			}
			// if (this.childCategory.includes(parseInt(data.id))) {
			//     // Remove sub category
			//     const newSubCategory = this.childCategory.filter(i => i !== parseInt(data.id))
			//     this.childCategory = newSubCategory
			// } else {
			//     // Add sub categorydata.id
			//     this.childCategory.push(parseInt(data.id))
			// }
		},
		addBlogCategory() {
			if (this.blogForm.name === "") {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.updating = true;
				const payload = {
					name: this.blogForm.name,
				};
				this.$store
					.dispatch("portal/addBlogCategory", payload)
					.then((_) => {
						this.updating = false;
						this.$services.helpers.notification(
							this.$t("save"),
							"success",
							this
						);
						window.Bus.$emit("reload-category-edit");
						this.blogForm.name = "";
						this.openBlogCategory = false;
					})
					.catch((err) => {
						this.updating = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
						if (err.response.status === 400) {
							this.$services.helpers.notification(
								err.response.data.error,
								"error",
								this
							);
						}
					});
			}
		},
		updateBlogCategory() {
			if (this.blogForm.name === "") {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.updating = true;
				const payload = {
					name: this.blogForm.name,
					id: this.blogForm.id,
				};
				this.$store
					.dispatch("portal/updateBlogCategory", payload)
					.then((_) => {
						this.updating = false;
						this.$services.helpers.notification(
							this.$t("save"),
							"success",
							this
						);
						// window.Bus.$emit('get-blog-category')
						this.openEditBlogCategory = false;
					})
					.catch((err) => {
						this.updating = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
						if (
							err.response.status === 400 ||
							err.response.status === 403
						) {
							this.$services.helpers.notification(
								err.response.data.error,
								"error",
								this
							);
						}
					});
			}
		},
		removeParentCategoryFromList(id) {
			const newParentCategory = this.parentCategory.filter(
				(i) => i !== parseInt(id)
			);
			this.parentCategory = newParentCategory;
		},
		removeChildCategryFromList(id) {
			const newSubCategory = this.childCategory.filter(
				(i) => i !== parseInt(id)
			);
			this.childCategory = newSubCategory;
		},
		showParentCategoryName(id) {
			const filter = this.GET_PRODUCT_CATEGORY.data.filter(
				(item) => parseInt(item.parent_category_id) === parseInt(id)
			);
			return filter[0].parent_category_name;
		},
		showSubCategoryName(data) {
			const filter = this.childCategory.filter(
				(item) => parseInt(item.id) === parseInt(data.id)
			);
			return filter[0].name;
		},
		showEditSubCategoryName(data) {
			if (data !== null) {
				const filter = this.childCategory.filter(
					(item) => parseInt(item.id) === parseInt(id)
				);
				return filter[0].name;
			}
		},
		checkParentCategory(data) {
			const filter = this.parentCategory.filter(
				(i) => i === parseInt(data.parent_category_id)
			);
			if (filter.length > 0) {
				return true;
			} else {
				return false;
			}
		},
		checkSubCategory(data) {
			const filter = this.childCategory.filter(
				(item) => parseInt(item.id) === parseInt(data.id)
			);
			if (filter.length > 0) {
				return true;
			} else {
				return false;
			}
		},
		removeUpdate() {
			this.addNewManagement = false;
		},
		rowUpdateClick(data) {
			this.addNewManagement = true;
			this.customerObj = data;
		},
		rowUpdateCategoryClick(data) {},
		addCustomerManagement() {
			// this.addNewManagement = true
		},
		addCategory() {
			if (this.cateForm.category === "" || this.cateForm.value === "") {
				// this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (
				this.cateForm.category === "child" &&
				!this.cateForm.parent
			) {
				this.$services.helpers.notification(
					this.$t("selectCategoryParent"),
					"error",
					this
				);
			} else {
				this.addingProductCategory = true;
				if (this.cateForm.category === "parent") {
					const payload = {
						name: this.cateForm.value,
						account_number: 0,
						cost_center: 0,
					};
					this.$store
						.dispatch("portal/addProductCategory", payload)
						.then((_) => {
							this.addingProductCategory = false;
							this.$services.helpers.notification(
								this.$t("productCategorySuccess"),
								"success",
								this
							);
							this.cateForm.category = "";
							this.cateForm.value = "";
						})
						.catch((err) => {
							this.addingProductCategory = false;
							if (err.response.status === 401) {
								this.$store.commit(
									"auth/CLEAR_AUTH_USER",
									null
								);
								window.Bus.$emit("sign-out");
							} else {
								if (
									err.response.data.hasOwnProperty(
										"message"
									) &&
									err.response.data.hasOwnProperty(
										"message_swe"
									)
								) {
									if (this.GET_LOCALE_LANG === "se") {
										this.$services.helpers.notification(
											err.response.data.message_swe,
											"error",
											this
										);
									} else {
										this.$services.helpers.notification(
											err.response.data.message,
											"error",
											this
										);
									}
								} else {
									this.$services.helpers.notification(
										err.response.data.message,
										"error",
										this
									);
								}
							}
						});
				} else {
					const payload = {
						name: this.cateForm.value,
						category: parseInt(this.cateForm.parent),
					};
					this.$store
						.dispatch("portal/addSubCategory", payload)
						.then((_) => {
							this.addingProductCategory = false;
							this.$services.helpers.notification(
								this.$t("subCategorySuccess"),
								"success",
								this
							);
							this.cateForm.category = "";
							this.cateForm.value = "";
							this.cateForm.parent = null;
						})
						.catch((err) => {
							this.addingProductCategory = false;
							if (err.response.status === 401) {
								this.$store.commit(
									"auth/CLEAR_AUTH_USER",
									null
								);
								window.Bus.$emit("sign-out");
							}
						});
				}
			}
		},
		removeParentCategory() {
			if (this.GET_PRODUCT_CATEGORY_CHECK.data.length > 0) {
				this.GET_PRODUCT_CATEGORY_CHECK.data.map((item) => {
					this.$store
						.dispatch("portal/deleteProductCategory", {
							id: item.parent_category_id,
						})
						.catch(() => {
							this.$services.helpers.notification(
								this.$t("selectCategoryParent"),
								"error",
								this
							);
						});
				});
				this.$store.dispatch("portal/getProductCategory");
			}
		},
		removeChildCategory() {
			if (this.subCategoryObj.length > 0) {
				this.subCategoryObj.map((item) => {
					this.$store.dispatch("portal/deleteSubCategory", {
						id: item.id,
					});
				});
				this.subCategoryObj = [];
				this.$store.dispatch("portal/getProductCategory");
			}
		},
		hideStatus() {
			window.Bus.$emit("remove-category-update");
			this.statusUpdate = false;
		},
		toggleAllCheckbox(e) {
			this.$store.commit(
				"portal/TOGGLE_ALL_CATEGORY_CHECKBOX",
				this.checkboxAll
			);
		},
		filterAccnt() {
			this.filteredAccount = this.GET_ACTIVE_ACCOUNTS.results.filter(
				(account) => {
					return String(account.number).includes(this.accountNumber);
				}
			);
		},
		filterCostCenter() {
			this.filteredAccount = this.GET_COST_CENTER.results.filter(
				(account) => {
					return String(account.number).includes(this.accountNumber);
				}
			);
		},
		setAccount(account) {
			this.accountNumber = account.number;
			this.selectAccount = account.number;
			this.productCategoryForm.account = account.number;
			this.modal = false;
		},
		filterCustomers() {
			let URL = `?limit=50&search=${this.customerSearchName}`;
			if (getCompanyId()) {
				URL += `&company_id=${getCompanyId()}`;
			}
			this.$store
				.dispatch("portal/getCompanyEndCustomers", {
					page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + URL,
				})
				.then((res) => {
					this.filteredCustomer = res.data.data;
				})
				.catch((err) => {
					this.loading = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		filterProducts() {
			this.$store
				.dispatch("portal/getFilterArticles", {
					url: `?limit=50&search=${this.productSearchName}`,
				})
				.then((res) => {
					this.filteredProducts = res.data.data;
				})
				.catch((err) => {
					this.loading = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		addCustomerToList(customer) {
			// Check if customer exist already
			const filter = this.selectedCustomers.filter(
				(item) => parseInt(item.id) === parseInt(customer.id)
			);
			if (filter.length < 1) {
				this.selectedCustomers.push(customer);
			}
			this.openCustomersDropdown = false;
		},
		addProductToList(product) {
			// Check if customer exist already
			const filter = this.selectedProducts.filter(
				(item) => parseInt(item.id) === parseInt(product.id)
			);
			if (filter.length < 1) {
				this.selectedProducts.push(product);
			}
			this.openBlogProductDropdown = false;
		},
		removeUserFromSelectedList(customer) {
			this.selectedCustomers = this.selectedCustomers.filter(
				(item) => item.id !== customer.id
			);
		},
		setRelevantCustomerArticles() {
			this.addingRelevant = true;
			const payload = {
				is_hidden_update: true,
				articles: [],
				customer_ids: [],
			};
			if (this.GET_ARTICLES_CHECKED.data.length > 0) {
				this.GET_ARTICLES_CHECKED.data.forEach((element) => {
					payload.articles.push({ article_id: parseInt(element.id) });
				});
			} else {
				payload.articles.push({
					article_id: parseInt(this.modalRelevantArticleNumber),
				});
			}
			let self = this;

			if (this.selectedCustomers.length < 1) {
				if (
					this.GET_ARTICLES_CHECKED.data.length === 0 &&
					this.modalRelevantArticleNumber
				) {
					this.$confirm(
						`${this.$t("removeAllCustomerFromArticle")}`,
						`${this.$t("warning")}`,
						{
							confirmButtonText: this.$t("yes"),
							cancelButtonText: this.$t("cancel"),
							type: "warning",
							beforeClose: (action, instance, done) => {
								if (action === "confirm") {
									instance.confirmButtonLoading = true;
									instance.confirmButtonText = "Loading...";
									if (getCompanyId()) {
										payload["company_id"] = getCompanyId();
									}
									self.$store
										.dispatch(
											"portal/updateRelevantBulkData",
											{ data: payload }
										)
										.then((_) => {
											window.Bus.$emit(
												"fetch-article",
												"bulk"
											);
											instance.confirmButtonText =
												this.$t("yes");
											instance.confirmButtonLoading = false;
											done();
											this.$services.helpers.notification(
												`${this.$t(
													"removeSuccessful"
												)}`,
												"success",
												this
											);
											this.modalRelevantArticle = false;
											this.addingRelevant = false;
										})
										.catch((err) => {
											done();
											this.addingRelevant = false;
											instance.confirmButtonLoading = false;
											window.Bus.$emit(
												"output-error",
												err
											);
										});
								} else {
									done();
									this.addingRelevant = false;
								}
							},
						}
					)
						.then(() => {
							window.Bus.$emit("fetch-users");
						})
						.catch(() => {
							this.addingRelevant = false;
						});
				} else {
					this.addingRelevant = false;
					this.$services.helpers.notification(
						this.$t("customerSelectionEmpty"),
						"error",
						this
					);
				}
			} else {
				this.addingRelevant = true;
				const payload = {
					is_hidden_update: true,
					articles: [],
					customer_ids: [],
				};
				if (this.GET_ARTICLES_CHECKED.data.length > 0) {
					this.GET_ARTICLES_CHECKED.data.forEach((element) => {
						payload.articles.push({
							article_id: parseInt(element.id),
						});
					});
				} else {
					payload.articles.push({
						article_id: parseInt(this.modalRelevantArticleNumber),
					});
				}
				this.selectedCustomers.forEach((element) => {
					payload.customer_ids.push(element.id);
				});
				if (getCompanyId()) {
					payload["company_id"] = getCompanyId();
				}
				this.$store
					.dispatch("portal/updateRelevantBulkData", {
						data: payload,
					})
					.then((_) => {
						this.addingRelevant = false;
						this.$services.helpers.notification(
							this.$t("articleUpdateSuccess"),
							"success",
							this
						);
						this.selectedCustomers = [];
						this.customerSearchName = "";
						window.Bus.$emit("fetch-article", "bulk");
						this.modalRelevantArticle = false;
					})
					.catch((err) => {
						this.addingRelevant = false;
						window.Bus.$emit("output-error", err);
					});
			}
		},
		setRelevantCustomerBlog() {
			this.addingRelevant = true;
			let self = this;
			if (this.selectedCustomers.length < 1) {
				this.$confirm(
					`${this.$t("removeAllCustomerFromArticle")}`,
					`${this.$t("warning")}`,
					{
						confirmButtonText: this.$t("yes"),
						cancelButtonText: this.$t("cancel"),
						type: "warning",
						beforeClose: (action, instance, done) => {
							if (action === "confirm") {
								instance.confirmButtonLoading = true;
								instance.confirmButtonText = "Loading...";
								self.$store
									.dispatch(
										"portal/updateRelevantBlogCustomer",
										{
											id: payload.id,
											data: { customers: [] },
										}
									)
									.then((_) => {
										window.Bus.$emit("fetch-blogs");
										instance.confirmButtonText =
											this.$t("yes");
										instance.confirmButtonLoading = false;
										done();
										window.Bus.$emit("fetch-blogs");
										this.$services.helpers.notification(
											`${this.$t("removeSuccessful")}`,
											"success",
											this
										);
										this.modalRelevantBlog = false;
										this.addingRelevant = false;
									})
									.catch((err) => {
										done();
										instance.confirmButtonLoading = false;
										if (err.response.status === 400) {
											this.$services.helpers.notification(
												this.$t("serverError"),
												"error",
												this
											);
										}
									});
							} else {
								done();
								this.addingRelevant = false;
							}
						},
					}
				)
					.then(() => {
						window.Bus.$emit("fetch-blogs");
					})
					.catch(() => {
						this.addingRelevant = false;
					});
			} else {
				this.addingRelevant = true;
				const payload = {
					id: this.modalRelevantBlogObject.id,
					customers: [],
				};
				this.selectedCustomers.forEach((element) => {
					payload.customers.push(element.id);
				});

				this.$store
					.dispatch("portal/updateRelevantBlogCustomer", {
						id: payload.id,
						data: { customers: payload.customers },
					})
					.then((_) => {
						this.addingRelevant = false;
						this.$services.helpers.notification(
							this.$t("blogDataSuccess"),
							"success",
							this
						);
						this.selectedCustomers = [];
						this.customerSearchName = "";
						window.Bus.$emit("fetch-blogs");
						this.modalRelevantBlog = false;
					})
					.catch((err) => {
						this.addingRelevant = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		next() {
			this.$refs.flickity.next();
		},
		closeNotifyDiv() {
			window.Bus.$emit("close-cart");
			this.showFullCover = false;
		},
		previous() {
			this.$refs.flickity.previous();
		},
		automaticSignOut() {
			this.$router.replace({ name: "login" });
		},
		handleClose() {
			this.$store.commit("utility/OPEN_INVITE_MODAL");
		},
		finalLogoCropped() {
			window.Bus.$emit("logo-image", {
				logo: this.userPhoto,
				base64: this.croppieImage,
			});
			this.userPhoto = null;
			this.croppieImage = null;
			this.showCrop = false;
			this.croppingSuccess = false;
			this.showPreviewCropping = false;

			return this.$store.commit("utility/OPEN_PHOTO_MODAL");
		},
		deleteParentCategory(data) {
			this.$confirm(
				`${this.$t("removeParentCategory")} ${
					data.parent_category_name
				}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";
							this.$store
								.dispatch("portal/deleteProductCategory", {
									id: data.parent_category_id,
								})
								.then((_) => {
									instance.confirmButtonLoading = false;
									done();
									this.$store.dispatch(
										"portal/getProductCategory"
									);
								})
								.catch((error) => {
									done();
									instance.confirmButtonLoading = false;
									if (
										error.response.status === 400 ||
										error.response.status === 500
									) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									}
								});
						} else {
							done();
							instance.confirmButtonLoading = false;
						}
					},
				}
			)
				.then(() => {
					this.$store.dispatch("portal/getProductCategory");
				})
				.catch(() => {});
		},
		deleteChildCategory(data) {
			this.$confirm(
				`${this.$t("removeChildCategory")} ${data.name}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";
							this.$store
								.dispatch("portal/deleteSubCategory", {
									id: data.id,
								})
								.then((_) => {
									instance.confirmButtonLoading = false;
									done();
									this.$store.dispatch(
										"portal/getProductCategory"
									);
								})
								.catch((error) => {
									done();
									instance.confirmButtonLoading = false;
									if (
										error.response.status === 400 ||
										error.response.status === 500
									) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									}
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {
					this.$store.dispatch("portal/getProductCategory");
				})
				.catch(() => {});
		},
		updateParentCategory(e) {
			if (this.categoryForm.parent_category_name === "") {
				return this.$services.helpers.notification(
					this.$t("selectCategoryParent"),
					"error",
					this
				);
			}
			this.updateCategoryBtn = true;
			this.$store
				.dispatch("portal/updateProductCategory", {
					name: this.categoryForm.parent_category_name,
					id: this.categoryForm.parent_category_id,
				})
				.then((response) => {
					this.$services.helpers.notification(
						this.$t("productCategorySuccess"),
						"success",
						this
					);
					this.updateCategoryBtn = false;
					this.modalEditCategory = false;
					this.modalEditChildCategory = false;
				})
				.catch((err) => {
					this.updateCategoryBtn = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				});
			window.Bus.$emit("update-category-backdrop");
		},
		updateChildCategory(e) {
			if (this.childCategoryForm.name === "") {
				return this.$services.helpers.notification(
					this.$t("selectCategoryParent"),
					"error",
					this
				);
			}
			this.updateChildCategoryBtn = true;
			this.$store
				.dispatch("portal/updateSubCategory", {
					name: this.childCategoryForm.name,
					id: this.childCategoryForm.id,
					category: { id: this.childCategoryForm.category_id },
				})
				.then((response) => {
					this.$services.helpers.notification(
						this.$t("productCategorySuccess"),
						"success",
						this
					);
					this.updateChildCategoryBtn = false;
					this.modalEditCategory = false;
					this.modalEditChildCategory = false;
				})
				.catch((err) => {
					this.updateChildCategoryBtn = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				});
			window.Bus.$emit("update-category-backdrop");
		},
		deleteUser(user) {
			const self = this;
			this.$confirm(
				`${this.$t("removeUser")} ${
					user.first_name + " " + user.last_name
				}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";

							self.$store
								.dispatch("portal/deleteUser", { id: user.id })
								.then((_) => {
									window.Bus.$emit("fetch-users");
									done();
									this.$services.helpers.notification(
										`${
											user.first_name +
											" " +
											user.last_name
										} ${this.$t("removeSuccessful")}`,
										"success",
										this
									);
									instance.confirmButtonLoading = false;
								})
								.catch((err) => {
									done();
									instance.confirmButtonLoading = false;
									if (err.response.status === 400) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									} else if (err.response.status === 403) {
										this.$services.helpers.notification(
											this.$t("permissionDenied"),
											"error",
											this
										);
									}
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {
					bus.$emit("fetch-users");
				})
				.catch(() => {});
		},
		acceptUserRequest(data, option) {
			const self = this;
			const statement =
				option === "accept"
					? this.$t("acceptStatement")
					: this.$t("rejectStatement");
			this.$confirm(statement, `${this.$t("warning")}`, {
				confirmButtonText: this.$t("yes"),
				cancelButtonText: this.$t("cancel"),
				type: "warning",
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = "Loading...";

						self.$store
							.dispatch("portal/userRequestOption", {
								customer_id: data.id,
								status: option,
							})
							.then((res) => {
								window.Bus.$emit("get-invited-customers");
								done();
								this.$services.helpers.notification(
									this.$t("accepted"),
									"success",
									this
								);
								instance.confirmButtonLoading = false;
							})
							.catch((err) => {
								done();
								instance.confirmButtonLoading = false;
								if (err.response.status === 400) {
									this.$services.helpers.notification(
										this.$t("serverError"),
										"error",
										this
									);
								}
							});
					} else {
						done();
					}
				},
			})
				.then(() => {
					window.Bus.$emit("get-invited-customers");
				})
				.catch(() => {});
		},
		modalAddUser() {
			if (
				this.addUserForm.firstname === "" ||
				this.addUserForm.lastname === "" ||
				this.addUserForm.email === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (
				this.addUserForm.email.length > 50 ||
				this.addUserForm.firstname.length > 50 ||
				this.addUserForm.lastname.length > 50
			) {
				this.$services.helpers.notification(
					this.$t("fieldValue"),
					"error",
					this
				);
			} else if (
				!this.$services.helpers.validateEmailAddress(
					this.addUserForm.email
				)
			) {
				this.$services.helpers.notification(
					this.$t("invalidEmail"),
					"error",
					this
				);
			} else {
				this.addingUsers = true;
				const payload = {
					email: this.addUserForm.email,
					first_name: this.addUserForm.firstname,
					last_name: this.addUserForm.lastname,
					user_type: "Employee",
				};
				this.$store
					.dispatch("portal/addUsers", payload)
					.then((_) => {
						this.addingUsers = false;
						this.$services.helpers.notification(
							this.$t("invitedUserSuccess"),
							"success",
							this
						);
						Object.keys(this.addUserForm).forEach((item) => {
							this.addUserForm[item] = "";
						});
						this.modalAddUsers = false;
						window.Bus.$emit("fetch-users");
					})
					.catch((err) => {
						this.addingUsers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
						if (err.response.status === 400) {
							if (
								err.response.data.hasOwnProperty(
									"message_swe"
								) &&
								err.response.data.hasOwnProperty("message")
							) {
								if (this.GET_LOCALE_LANG === "se") {
									this.$services.helpers.notification(
										err.response.data.message_swe,
										"error",
										this
									);
								} else {
									this.$services.helpers.notification(
										err.response.data.message,
										"error",
										this
									);
								}
							} else {
								this.$services.helpers.notification(
									err.response.data.error,
									"error",
									this
								);
							}
						}
					});
			}
		},
		updateUser() {
			if (
				this.updateUsers.first_name === "" ||
				this.updateUsers.last_name === "" ||
				this.updateUsers.email === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (
				this.updateUsers.email.length > 50 ||
				this.updateUsers.first_name.length > 50 ||
				this.updateUsers.last_name.length > 50
			) {
				this.$services.helpers.notification(
					this.$t("fieldValue"),
					"error",
					this
				);
			} else if (
				!this.$services.helpers.validateEmailAddress(
					this.updateUsers.email
				)
			) {
				this.$services.helpers.notification(
					this.$t("invalidEmail"),
					"error",
					this
				);
			} else {
				this.addingUsers = true;
				const payload = {
					first_name: this.updateUsers.first_name,
					last_name: this.updateUsers.last_name,
					email: this.updateUsers.email,
					id: this.updateUsers.id,
				};
				this.$store
					.dispatch("portal/updateUser", payload)
					.then((_) => {
						this.addingUsers = false;
						this.$services.helpers.notification(
							this.$t("updateUserSuccess"),
							"success",
							this
						);
						bus.$emit("fetch-users");
						setTimeout(() => {
							this.modalUsers = false;
						}, 1000);
					})
					.catch((err) => {
						this.addingUsers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						} else if (err.response.status === 403) {
							this.$services.helpers.notification(
								this.$t("permissionDenied"),
								"error",
								this
							);
						} else {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						}
					});
			}
		},

		updateArticle() {
			if (this.articleEditObj.price !== "") {
				if (parseFloat(this.articleEditObj.price) < 0) {
					return this.$services.helpers.notification(
						this.$t("enterValidPrice"),
						"error",
						this
					);
				}
			}
			if (this.articleEditObj.purchase_price !== "") {
				if (
					parseFloat(this.articleEditObj.purchase_price) < 0 ||
					this.articleEditObj.purchase_price === ""
				) {
					return this.$services.helpers.notification(
						this.$t("enterValidPrice"),
						"error",
						this
					);
				}
			}
			if (
				this.articleEditObj.product_video_url !== "" &&
				this.articleEditObj.product_video_url !== null
			) {
				if (!this.isURL(this.articleEditObj.product_video_url)) {
					return this.$services.helpers.notification(
						this.$t("invalidProductURL"),
						"error",
						this
					);
				}
			}
			this.updating = true;
			const payload = {
				article_id: this.articleEditObj.id,
				product_name: this.articleEditObj.product_name,
				product_video_url: this.articleEditObj.product_video_url,
				description: this.articleEditObj.description,
				article_number: this.articleEditObj.article_number,
				quantity_in_stock: parseInt(
					this.articleEditObj.quantity_in_stock
				),
				price: parseFloat(this.articleEditObj.price),
				purchase_price: parseFloat(this.articleEditObj.purchase_price),
			};
			if (
				this.articleEditObj.product_long_description !== "" &&
				this.articleEditObj.product_long_description !== null
			) {
				payload.product_long_description =
					this.articleEditObj.product_long_description;
			}
			this.$store
				.dispatch("portal/updateArticle", payload)
				.then((_) => {
					this.updating = false;
					this.$services.helpers.notification(
						this.$t("articleUpdateSuccess"),
						"success",
						this
					);
					this.modalArticleEdit = false;
					const indexObj = this.GET_ARTICLES.data.findIndex(
						(item) => item.id === payload.article_id
					);
					if (indexObj > -1) {
						const obj = this.GET_ARTICLES.data[indexObj];
						obj.description = payload.product_name;
						obj.product_video_url = payload.product_video_url;
						obj.price = payload.price;
						obj.purchase_price = payload.purchase_price;
					}
					this.articleEditObj = {};
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 400) {
						this.$services.helpers.notification(
							err.response.data.error,
							"error",
							this
						);
					}
					if (err.response.status === 500) {
						this.$services.helpers.notification(
							this.$t("serverError"),
							"error",
							this
						);
					}
				});
		},
		updateArticleDataModal() {
			if (this.articleObjData.purchase_price !== "") {
				if (
					parseFloat(this.articleObjData.purchase_price) < 0 ||
					this.articleObjData.purchase_price === ""
				) {
					return this.$services.helpers.notification(
						this.$t("enterValidPrice"),
						"error",
						this
					);
				}
			}
			if (
				this.articleObjData.product_video_url &&
				this.articleObjData.product_video_url !== "nan"
			) {
				if (!this.isURL(this.articleObjData.product_video_url)) {
					return this.$services.helpers.notification(
						this.$t("invalidProductURL"),
						"error",
						this
					);
				}
			}
			this.updating = true;
			let category = this.articleCategoryValue.filter(
				(item) => item !== null
			);
			const payload = {
				article_id: this.articleObjData.id,
				product_name: this.articleObjData.product_name,
				product_video_url: this.articleObjData.product_video_url,
				description: this.articleObjData.description,
				article_number: this.articleObjData.article_number,
				sales_price: this.articleObjData.sales_price,
				discounted_price: this.articleObjData.discounted_price,
				delivery_time: this.articleObjData.delivery_time,
				allow_custom_row_text:
					this.articleObjData.allow_custom_row_text,
				custom_row_text_title:
					this.articleObjData.custom_row_text_title,
				category: category,
				quantity_in_stock: parseInt(
					this.articleObjData.quantity_in_stock
				),
				price: parseFloat(this.articleObjData.price),
				purchase_price: parseFloat(this.articleObjData.purchase_price),
			};
			if (this.dateRangeFilter.length > 0) {
				payload["scheduled_campaign_start_date"] =
					this.dateRangeFilter[0];
				payload["scheduled_campaign_end_date"] =
					this.dateRangeFilter[1];
			}
			if (
				this.articleObjData.product_long_description !== "" &&
				this.articleObjData.product_long_description !== null
			) {
				payload.product_long_description =
					this.articleObjData.product_long_description;
			}
			let data = {};
			const isValidNumber = (value) => {
				if (typeof value === "number") {
					return value > 0;
				}
				return true;
			};
			for (const [key, value] of Object.entries(payload)) {
				if (
					value !== "" &&
					value !== null &&
					value !== undefined &&
					isValidNumber(value)
				) {
					data[key] = value;
				}
			}

			this.$store
				.dispatch("portal/updateArticle", data)
				.then((_) => {
					this.updating = false;
					this.openCategoryTreeStatus = false;
					this.$services.helpers.notification(
						this.$t("articleUpdateSuccess"),
						"success",
						this
					);
					// this.modalArticleEdit = false
					const indexObj = this.GET_ARTICLES.data.findIndex(
						(item) => item.id === payload.article_id
					);
					if (indexObj > -1) {
						const obj = this.GET_ARTICLES.data[indexObj];
						obj.description = payload.product_name;
						obj.product_video_url = payload.product_video_url;
						obj.price = payload.price;
						obj.purchase_price = payload.purchase_price;
					}
					// this.articleEditObj = {}
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 400) {
						this.$services.helpers.notification(
							err.response.data.error,
							"error",
							this
						);
					}
					if (err.response.status === 500) {
						this.$services.helpers.notification(
							this.$t("serverError"),
							"error",
							this
						);
					}
				});
		},
		removeProductImage(data, key) {
			const self = this;
			this.$confirm(
				`${this.$t("removeImage")}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";
							let payload = {
								article_id: this.articleImageView.id,
								image_id: data,
							};
							if (getCompanyId()) {
								payload["company_id"] = getCompanyId();
							}
							self.$store
								.dispatch("portal/deleteImage", payload)
								.then((_) => {
									done();
									// window.Bus.$emit('fetch-article')
									this.$services.helpers.notification(
										this.$t("imageRemoveSuccess"),
										"success",
										this
									);
									try {
										const ImagesFile =
											this.GET_ARTICLES.data[
												this.articleImageView.index
											].images;
										ImagesFile.splice(key, 1);
										const article =
											this.GET_ARTICLES.data[
												this.articleImageView.index
											];
										article.images = ImagesFile;
										this.GET_ARTICLES.data[
											this.articleImageView.index
										] = article;
										this.modalImage = false;
										instance.confirmButtonLoading = false;
									} catch (e) {}
								})
								.catch((err) => {
									done();
									instance.confirmButtonLoading = false;
									window.Bus.$emit("output-error", err);
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {
					// window.Bus.$emit('fetch-article')
				})
				.catch(() => {});
		},
		isURL(str) {
			var regex =
				/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/;
			var pattern = new RegExp(regex);
			return pattern.test(str);
		},
		updateCustomer() {
			this.updating = true;
			const payload = {
				id: this.customerObj.id,
				// organization_number : this.customerObj.organization_number,
				customer_name: this.customerObj.customer_name,
				email: this.customerObj.email,
				accountNumber: this.accountNumber,
				customer_number: this.customerObj.customer_number,
				render_only_customer_specific_articles:
					this.customerObj.render_only_customer_specific_articles,
				render_only_customer_specific_categories:
					this.customerObj.render_only_customer_specific_categories,
				customer_specific_reference_field:
					this.customerObj.customer_specific_reference_field,
				self_allowed_create_delivery_addresses:
					this.customerObj.self_allowed_create_delivery_addresses,
				apply_customer_currency_on_orders:
					this.customerObj.apply_customer_currency_on_orders,
				category: { id: this.customerObj.category_id },
				max_credit_limit: parseInt(this.customerObj.max_credit_limit),
			};
			if (this.costCenterNumber !== "") {
				const costIndex = this.GET_COST_CENTER.results.findIndex(
					(item) => item.id === this.costCenterNumber
				);
				const costCenterInfo = this.GET_COST_CENTER.results[costIndex];
				payload.category.cost_center = {
					id: this.costCenterNumber,
					code: costCenterInfo.code,
					description: costCenterInfo.description,
				};
			}
			this.$store
				.dispatch("portal/updateCustomer", payload)
				.then((res) => {
					this.updating = false;
					this.$services.helpers.notification(
						this.$t("customerInfoUpdated"),
						"success",
						this
					);

					try {
						const ItemIndex =
							this.GET_SHOP_CUSTOMERS.data.findIndex(
								(item) => item.id === payload.id
							);
						this.GET_SHOP_CUSTOMERS.data[ItemIndex] = res.data.data;
						this.$store.commit("portal/UPDATE_NEW_SHOP_CUSTOMERS", {
							data: this.GET_SHOP_CUSTOMERS.data,
						});
						if (res.data.hasOwnProperty("customer_error")) {
							this.$services.helpers.notification(
								"Kunde inte uppdatera kundinformation på fortnox",
								"info",
								this
							);
						}
					} catch (e) {
						console.log(e);
					}
					this.modalCustomerEdit = false;
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data[0],
						"error",
						this
					);
				});
		},
		loadShopCustomerSettings() {
			if (
				this.GET_SHOP_CUSTOMERS_DRAWER_SETTINGS.current_page <
				this.GET_SHOP_CUSTOMERS_DRAWER_SETTINGS.total_pages
			) {
				this.loadingShopCustomers = true;
				const num = window.localStorage.getItem("cnum");
				const limit =
					parseInt(num) && parseInt(num) < 201 ? parseInt(num) : 10;

				this.$store
					.dispatch("portal/getCompanyEndCustomers", {
						page:
							this.$services.endpoints.COMPANY_ENDCUSTOMERS +
							`?page=${
								this.GET_SHOP_CUSTOMERS_DRAWER_SETTINGS
									.current_page + 1
							}&limit=${limit}`,
						setting: true,
					})
					.then((_) => {
						this.loadingShopCustomers = false;
					})
					.catch((err) => {
						this.loadingShopCustomers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		loadShopCustomers() {
			if (
				this.GET_SHOP_CUSTOMERS_DRAWER.current_page <
				this.GET_SHOP_CUSTOMERS_DRAWER.total_pages
			) {
				this.loadingShopCustomers = true;
				const num = window.localStorage.getItem("cnum");
				const limit =
					parseInt(num) && parseInt(num) < 201 ? parseInt(num) : 10;

				this.$store
					.dispatch("portal/getCompanyEndCustomers", {
						page:
							this.$services.endpoints.COMPANY_ENDCUSTOMERS +
							`?page=${
								this.GET_SHOP_CUSTOMERS_DRAWER.current_page + 1
							}&limit=${limit}`,
						drawer: true,
					})
					.then((_) => {
						this.loadingShopCustomers = false;
					})
					.catch((err) => {
						this.loadingShopCustomers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		loadMoreCategory() {
			if (
				this.GET_CUSTOMER_CATEGORY.current_page <
				this.GET_CUSTOMER_CATEGORY.total_pages
			) {
				this.loadingMoreCategory = true;
				const limit =
					parseInt(num) && parseInt(num) < 201 ? parseInt(num) : 10;

				this.$store
					.dispatch("portal/getCompanyEndCustomers", {
						page:
							this.$services.endpoints.COMPANY_ENDCUSTOMERS +
							`?page=${
								this.GET_SHOP_CUSTOMERS_DRAWER.current_page + 1
							}&limit=${limit}`,
						drawer: true,
					})
					.then((_) => {
						this.loadingShopCustomers = false;
					})
					.catch((err) => {
						this.loadingShopCustomers = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		updateMultiRelevantCustomer() {
			this.updating = true;
			const customerArray = [];
			this.GET_SHOP_CUSTOMERS_CHECKED.data.forEach((element) => {
				const obj = {};
				obj.customer_id = element.id;
				customerArray.push(obj);
			});

			const payload = {
				customer_data: customerArray,
				is_relevant: this.relevantCustomerObj.is_relevant,
			};
			this.$store
				.dispatch("portal/setMultiRelevantCustomer", payload)
				.then((_) => {
					const existingShopCustomers = this.GET_SHOP_CUSTOMERS.data;
					this.GET_SHOP_CUSTOMERS_CHECKED.data.filter((item) => {
						const dataIndex = existingShopCustomers.findIndex(
							(itemIndex) => itemIndex.id === item.id
						);
						existingShopCustomers[dataIndex].is_relevant =
							this.relevantCustomerObj.is_relevant;
						existingShopCustomers[dataIndex].checked = false;
					});
					this.GET_SHOP_CUSTOMERS.data = existingShopCustomers;
					// window.Bus.$emit('get-customer-info', { removed : true })
					this.updating = false;
					this.$services.helpers.notification(
						this.$t("customerInfoUpdated"),
						"success",
						this
					);
					this.relevantCustomerObj = {};
					this.modalRelevantCustomerEdit = false;
				})
				.catch((err) => {
					this.updating = false;
					this.$services.helpers.notification(
						err.response.data[0],
						"error",
						this
					);
				});
		},
		updateRelevantCustomer() {
			this.updating = true;
			const payload = {
				id: this.relevantCustomerObj.id,
				is_relevant: this.relevantCustomerObj.is_relevant,
				customer_number: this.relevantCustomerObj.customer_number,
			};
			this.$store
				.dispatch("portal/updateCustomer", payload)
				.then((_) => {
					// window.Bus.$emit('get-customer-info')
					this.updating = false;
					this.$services.helpers.notification(
						this.$t("customerInfoUpdated"),
						"success",
						this
					);
					this.relevantCustomerObj = {};
					this.modalRelevantCustomerEdit = false;
				})
				.catch((err) => {
					this.updating = false;
					this.$services.helpers.notification(
						err.response.data[0],
						"error",
						this
					);
				});
		},
		setupInvitation() {
			if (
				this.formData.customer_name === "" ||
				this.formData.customer_number === "" ||
				this.formData.email === ""
			) {
				//  || this.formData.category_id === ''   || this.formData.organization_number === ''
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.addingUsers = true;

				const body = {
					id: this.formData.id,
					email: this.formData.email,
					customer_name: this.formData.customer_name,
					customer_number: this.formData.customer_number,
				};
				this.$store
					.dispatch("portal/setupInvitation", body)
					.then((_) => {
						this.addingUsers = false;
						this.clearFieldCustomers();
						this.modalInviteCustomer = false;
						window.Bus.$emit("get-invited-customers");
						this.$services.helpers.notification(
							this.$t("invitedSuccessful"),
							"success",
							this
						);
					})
					.catch((err) => {
						this.addingUsers = false;
						return this.processErrorNotification(err);
					});
			}
		},
		processErrorNotification: function (err) {
			if (err.response.status === 401) {
				this.$store.commit("auth/CLEAR_AUTH_USER", null);
				window.Bus.$emit("sign-out");
			} else {
				if (
					err.response.data.hasOwnProperty("message") &&
					err.response.data.hasOwnProperty("message_swe")
				) {
					if (this.GET_LOCALE_LANG === "se") {
						this.$services.helpers.notification(
							err.response.data.message_swe,
							"error",
							this
						);
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				} else {
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				}
			}
		},
		assignMassProduct() {
			if (
				this.parentCategory.length < 1 &&
				this.childCategory.length < 1
			) {
				this.$services.helpers.notification(
					this.$t("selectCategory"),
					"error",
					this
				);
			} else {
				this.assigningProduct = true;
				const arrayData = [];
				this.GET_ARTICLES_CHECKED.data.forEach((element) => {
					arrayData.push({ article_id: element.id });
				});
				const payload = {
					article_rows: arrayData,
				};
				payload["many-to-many"] = true;
				if (this.parentCategory.length > 0) {
					payload.parent_category = this.parentCategory;
				} else {
					payload.parent_category = [];
				}
				if (this.childCategory.length > 0) {
					if (this.childCategory.length === 1) {
						payload.sub_category = [this.childCategory[0].id];
					} else {
						const array = [];
						this.childCategory.forEach((item) =>
							array.push(item.id)
						);
						payload.sub_category = array;
					}
				} else {
					payload.sub_category = [];
				}
				this.$store
					.dispatch("portal/assignMassProductCategory", payload)
					.then((response) => {
						this.assigningProduct = false;
						this.$services.helpers.notification(
							this.$t("assignSuccess"),
							"success",
							this
						);
						this.parentCategory = [];
						this.childCategory = [];
						this.updateArticleData(response.data.data);
						this.$store.commit("portal/TOGGLE_ALL_CHECKBOX", false);
						this.modalAssignProductCategory = false;
					})
					.catch((err) => {
						this.assigningProduct = false;
						this.$services.helpers.notification(
							err.response.data,
							"error",
							this
						);
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		updateAssignMassProduct() {
			if (
				this.parentCategory.length < 1 &&
				this.childCategory.length < 1
			) {
				this.$services.helpers.notification(
					this.$t("selectCategory"),
					"error",
					this
				);
			} else {
				this.assigningProduct = true;
				const payload = {};
				if (this.parentCategory.length > 0) {
					payload.parent_category = this.parentCategory;
				} else {
					payload.parent_category = [];
				}
				if (this.childCategory.length > 0) {
					if (this.childCategory.length === 1) {
						payload.sub_category = [this.childCategory[0].id];
					} else {
						const array = [];
						this.childCategory.forEach((item) =>
							array.push(item.id)
						);
						payload.sub_category = array;
					}
				} else {
					payload.sub_category = [];
				}
				const content = {
					quantity_in_stock: this.articleContent.quantity_in_stock,
					sales_price: this.articleContent.sales_price,
					article_number: this.articleContent.article_number,
					article_id: this.articleContent.id,
					category: payload.parent_category,
					sub_category: payload.sub_category,
					price: this.articleContent.price,
					purchase_price: this.articleContent.purchase_price,
					product_name: this.articleContent.product_name,
				};
				this.$store
					.dispatch("portal/updateArticle", content)
					.then((_) => {
						this.assigningProduct = false;
						this.$services.helpers.notification(
							this.$t("assignSuccess"),
							"success",
							this
						);
						this.$services.helpers.notification(
							this.$t("assignSuccess"),
							"success",
							this
						);
						const categoryName = [];
						this.parentCategory.map((itemIndex) => {
							const filter =
								this.GET_PRODUCT_CATEGORY.data.filter(
									(item) =>
										parseInt(item.parent_category_id) ===
										parseInt(itemIndex)
								);
							categoryName.push(filter[0].parent_category_name);
						});
						const subCategoryName = [];
						this.articleContent.checked = false;
						this.GET_PRODUCT_CATEGORY.data.map((item) => {
							item.sub_category.map((item) => {
								if (payload.sub_category.includes(item.id)) {
									subCategoryName.push(item.name);
								}
							});
						});
						this.articleContent.parent_category_id =
							payload.parent_category;
						this.articleContent.sub_category_id =
							payload.sub_category;
						this.articleContent.parent_category_name = categoryName;
						this.articleContent.sub_category_name = subCategoryName;
						this.modalEditAssignProductCategory = false;
						this.parentCategory = [];
						this.childCategory = [];
					})
					.catch((err) => {
						this.assigningProduct = false;
						this.$services.helpers.notification(
							err.response.data,
							"error",
							this
						);
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		updateArticleData(payload) {
			const newArticle = this.GET_COPY_ARTICLE.data;
			payload.map((item) => {
				const copyIndex = newArticle.findIndex(
					(itemIndex) => itemIndex.id === item.id
				);
				newArticle[copyIndex] = item;
			});
			this.GET_ARTICLES.data = newArticle;
		},
		addProductCategoryForm() {
			if (this.productCategoryForm.name === "") {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.addingProductCategory = true;
				const payload = {
					name: this.productCategoryForm.name,
					account_number:
						parseInt(this.productCategoryForm.account) || 0,
					cost_center:
						parseInt(this.productCategoryForm.costCenter) || 0,
				};
				this.$store
					.dispatch("portal/addProductCategory", payload)
					.then((_) => {
						this.addingProductCategory = false;
						this.$services.helpers.notification(
							this.$t("productCategorySuccess"),
							"success",
							this
						);
						Object.keys(this.productCategoryForm).forEach(
							(element) => {
								this.productCategoryForm[element] = "";
							}
						);
						window.Bus.$emit("fetch-product-category");
						this.modalProductCategory = false;
					})
					.catch((err) => {
						this.addingProductCategory = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		addSubCategoryForm() {
			if (
				this.subCategoryForm.name === "" ||
				this.subCategoryForm.parentCategory === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.addingSubCategory = true;
				const payload = {
					name: this.subCategoryForm.name,
					category: parseInt(this.subCategoryForm.parentCategory),
				};
				this.$store
					.dispatch("portal/addSubCategory", payload)
					.then((_) => {
						this.addingSubCategory = false;
						this.$services.helpers.notification(
							this.$t("subCategorySuccess"),
							"success",
							this
						);
						Object.keys(this.subCategoryForm).forEach((element) => {
							this.subCategoryForm[element] = "";
						});
						window.Bus.$emit("fetch-product-category");
						this.modalSubCategory = false;
					})
					.catch((err) => {
						this.addingSubCategory = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		getCustomerCategory() {
			this.$store.dispatch("portal/getCustomerCategory");
		},
		getCostCenter() {
			this.$store.dispatch("portal/getCostCenter");
		},
		createCustomerCategory() {
			if (this.cateName === "" || this.selectAccount === "") {
				// || this.accountNumber === ''
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (this.selectAccount === "") {
				this.$services.helpers.notification(
					this.$t("invalidAccount"),
					"error",
					this
				);
			} else {
				this.updating = true;
				let payload = {};
				if (this.ccValue === "") {
					payload = {
						name: this.cateName,
						account_number: this.selectAccount,
						// cost_center : this.ccValue
					};
				} else {
					payload = {
						name: this.cateName,
						account_number: this.selectAccount,
						cost_center: this.ccValue,
					};
				}
				this.$store
					.dispatch("portal/createCustomerCategory", payload)
					.then((_) => {
						this.updating = false;
						this.clearCategoryData();
						this.$store.dispatch("portal/getCostCenter");
						this.$store.dispatch("portal/getCustomerCategory");
						this.$services.helpers.notification(
							this.$t("customerCategoryCreated"),
							"success",
							this
						);
						setTimeout(() => {
							this.modalVisible = false;
						}, 1000);
					})
					.catch((err) => {
						this.updating = false;
						window.Bus.$emit("output-error", err);
					});
			}
		},
		croppie(e) {
			const self = this;
			const docType = ["png", "jpg", "jpeg"];
			const fileType = e.target.files[0].type.split("/")[1];

			if (docType.includes(fileType)) {
				self.showCrop = true;

				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				var reader = new FileReader();
				reader.onload = (e) => {
					this.$refs.croppieRef.bind({
						url: e.target.result,
					});
				};
				reader.readAsDataURL(files[0]);
			} else {
				this.$services.helpers.notification(
					this.$t("invalidFormat"),
					"error",
					this
				);
				document.getElementById("upload-open").value = "";
			}
		},
		crop() {
			this.initializingCropping = true;
			this.croppingSuccess = false;
			const options = {
				type: "base64",
				size: "viewport",
				format: "png",
				circle: true,
			};
			this.$refs.croppieRef.result(options, (output) => {
				this.croppieImage = output;

				this.initializingCropping = false;
				this.croppingSuccess = true;
				this.showPreviewCropping = true;
				this.addedImage = true;
				return this.getBase64ToFire(this.croppieImage);
			});
			// document.getElementById('upload').value = ''
		},
		getBase64ToFire(base64URL) {
			const renamFile = `${uuidv4()}.png`;
			fetch(base64URL)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], renamFile, {
						type: "image/png",
					});
					if (file !== undefined) {
						this.userPhoto = file;
					}
				});
		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader();
				reader.onload = (e) => {
					resolve(e.target.result);
				};
				reader.readAsDataURL(file);
			});
		},
		getPreviewImage(file) {
			this.getBase64File(file)
				.then((res) => {
					this.blogPostPreviewImage = res;
				})
				.catch((_) => {
					this.blogPostPreviewImage = "";
				});
		},
		handlePreview(file) {
			const self = this;
			const reader = new FileReader();
			reader.onload = function (e) {
				self.dialogImageUrl = e.target.result;
			};
			reader.readAsDataURL(file.raw);
			this.dialogVisible = true;
		},
		handleRemove(file, fileList) {
			const imageArray = this.productImages.filter((item) => {
				return item.uid !== file.uid;
			});
			this.productImages = imageArray;
		},
		productUploadOnchange(files, fileList) {
			const fileType = ["png", "jpg", "jpeg"];
			const file = files.raw.type.split("/")[1];
			const limit = 4 - this.productImages.length;
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image();
					img.src = window.URL.createObjectURL(files.raw);
					img.onload = () => {
						if (img.width <= 1500 && img.height <= 1500) {
							this.getBase64File(files.raw).then((res) => {
								files.raw.url = res;
								this.productImages.push(files.raw);
							});
						} else {
							this.productImages.pop();
							this.$services.helpers.notification(
								this.$t("maxArticleImageUploadMessageError"),
								"error",
								this
							);
							setTimeout(function () {
								document
									.getElementsByClassName("el-icon-close")[0]
									.click();
							}, 500);
						}
					};
				} else {
					return this.$services.helpers.notification(
						this.$t("invalidFormat"),
						"error",
						this
					);
				}
			} else {
				// this.productImages.push(files.raw)
				this.productImages.pop();
				return this.$services.helpers.notification(
					this.$t("maximumFour"),
					"error",
					this
				);
			}
		},
		handleExceed(files, fileList) {
			this.$services.helpers.notification(
				this.$t("fourOnly"),
				"error",
				this
			);
			// this.$message.warning(`You are suppose to upload a 4 Pictures`,);
		},
		uploadArticlePictures() {
			if (this.productImages.length < 1) {
				this.$services.helpers.notification(
					this.$t("imageRequired"),
					"error",
					this
				);
			} else {
				this.updating = true;
				const formData = new FormData();
				this.productImages.forEach((element, index) => {
					formData.append(`image${index + 1}`, element);
				});
				if (getCompanyId()) {
					formData.append(`company_id`, getCompanyId());
				}
				formData.append("article_id", this.articleObj.id);
				this.$store
					.dispatch("portal/uploadArticlePictures", formData)
					.then((response) => {
						// window.Bus.$emit('fetch-article')
						this.updating = false;
						this.productImages = [];
						this.modalArticleImages = false;
						const article =
							this.GET_ARTICLES.data[this.articleObj.index];
						const newImage = response.data.data;
						article.images = [];
						article.images = newImage;
						this.GET_ARTICLES.data[this.articleObj.index] = article;
						this.$services.helpers.notification(
							this.$t("uploadSuccessful"),
							"success",
							this
						);
					})
					.catch((err) => {
						this.updating = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
						if (
							err.response.data.hasOwnProperty("message_swe") &&
							err.response.data.hasOwnProperty("message")
						) {
							if (this.GET_LOCALE_LANG === "se") {
								this.$services.helpers.notification(
									err.response.data.message_swe,
									"error",
									this
								);
							} else {
								this.$services.helpers.notification(
									err.response.data.message,
									"error",
									this
								);
							}
						} else {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						}
					});
			}
		},
		uploadBulkExcelArticleData() {
			if (this.excelFile.length < 1) {
				this.$services.helpers.notification(
					this.$t("article_excel_document_required"),
					"error",
					this
				);
			} else {
				this.updating = true;
				const formData = new FormData();
				if (getCompanyId()) {
					formData.append("company_id", getCompanyId());
				}
				formData.append("articles_flat_file", this.excelFile[0].raw);
				if (this.fileList.length > 0) {
					formData.append("images", this.fileList[0].raw);
				}
				const URL = `${endpoints.ARTICLES_ENDPOINT}/bulk_upload/`;
				const self = this;
				const config = {
					onUploadProgress: function (progressEvent) {
						self.progressPercentage = Math.ceil(
							(progressEvent.loaded * 100) / progressEvent.total
						);
					},
				};
				http.post(URL, formData, config)
					.then((_) => {
						this.updating = false;
						this.modalArticleBulkUpload = false;
						this.fileList = [];
						this.excelFile = [];
						this.$services.helpers.notification(
							this.$t("article_upload_in_progress"),
							"success",
							this
						);
					})
					.catch((err) => {
						this.updating = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						} else {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						}
					});
			}
		},
		clearFieldCustomers() {
			Object.keys(this.formData).forEach((el) => {
				this.formData[el] = "";
			});
		},
		clearInviteField() {
			this.firstname = "";
			this.lastname = "";
			this.email = "";
			this.role = "";
		},
		clearCategoryData() {
			this.cateName = "";
			this.accountNumber = "";
			this.selectAccount = "";
			this.ccValue = "";
		},
		clearImageField() {
			document.getElementById("upload").value = "";
			this.userPhoto = {};
			this.showCrop = false;
			this.croppieImage = null;
			this.initializingCropping = false;
			this.croppingSuccess = false;
			this.showPreviewCropping = false;
			this.uploadingNow = false;
		},
		sortCustomerAsc() {
			this.$store.commit("portal/SORT_CUSTOMER_CATEGORY_NAME_ASC");
		},
		sortCustomerDsc() {
			this.$store.commit("portal/SORT_CUSTOMER_CATEGORY_NAME_DSC");
		},
		sortCusNameAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_NAME_ASC");
		},
		sortCusNameDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_NAME_DESC");
		},
		sortNumberAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_NUMBER_ASC");
		},
		sortNumberDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_NUMBER_DESC");
		},
		sortCategoryAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_CATE_ASC");
		},
		sortCategoryDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_CATE_DESC");
		},
		sortAccNumAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_ACCOUNT_NUM_ASC");
		},
		sortAccNumDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_ACCOUNT_NUM_DESC");
		},
		sortCostCenterAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_COST_CENTER_ASC");
		},
		sortCostCenterDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_DRAWER_COST_CENTER_DESC");
		},
		sortCusNameDrawerAsc() {
			this.$store.commit(
				"portal/SORT_CUSTOMERS_SETTING_CUSTOMER_NAME_ASC"
			);
		},
		sortCusNameDrawerDsc() {
			this.$store.commit(
				"portal/SORT_CUSTOMERS_SETTING_CUSTOMER_NAME_DESC"
			);
		},
		sortEmailDrawerAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_SETTING_EMAIL_ASC");
		},
		sortEmailDrawerDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_SETTING_EMAIL_DESC");
		},
		sortCateDrawerAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_SETTING_COST_CENTER_ASC");
		},
		sortCateDrawerDsc() {
			this.$store.commit(
				"portal/SORT_CUSTOMERS_SETTING_COST_CENTER_DESC"
			);
		},
		sortMaxLimitDrawerAsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_SETTING_MAX_LIMIT_ASC");
		},
		sortMaxLimitDrawerDsc() {
			this.$store.commit("portal/SORT_CUSTOMERS_SETTING_MAX_LIMIT_DESC");
		},
		sortProductCategoryAsc() {
			this.$store.commit("portal/SORT_PRODUCT_CATEGORY_ASC");
		},
		sortProductCategoryDsc() {
			this.$store.commit("portal/SORT_PRODUCT_CATEGORY_DSC");
		},
		updateAssignMassCustomerCategory() {
			const checkShopCustomers = this.GET_SHOP_CUSTOMERS.data.filter(
				(item) => item.checked === true
			);
			if (this.assignedCustomerCategory === "") {
				return this.$services.helpers.notification(
					this.$t("selectCategory"),
					"error",
					this
				);
			}
			const shopCustomersArray = [];
			checkShopCustomers.map((item) => {
				shopCustomersArray.push({ customer_id: item.id });
			});
			this.updating = true;
			if (checkShopCustomers.length === 1) {
				this.$store
					.dispatch("portal/putCustomer", {
						customer_number: checkShopCustomers[0].customer_number,
						category: { id: this.assignedCustomerCategory },
						id: checkShopCustomers[0].id,
						email: checkShopCustomers[0].email,
						customer_name: checkShopCustomers[0].customer_name,
					})
					.then((response) => {
						this.updating = false;
						this.$services.helpers.notification(
							this.$t("save"),
							"success",
							this
						);
						const ShopCustomerIndex =
							this.GET_SHOP_CUSTOMERS.data.findIndex(
								(item) => item.id === checkShopCustomers[0].id
							);
						const newShopCustomer = this.GET_SHOP_CUSTOMERS.data;
						this.$store
							.dispatch("portal/getCompanyEndCustomer", {
								id: checkShopCustomers[0].id,
							})
							.then((response) => {
								newShopCustomer[ShopCustomerIndex].category =
									response.data.category;
								this.GET_SHOP_CUSTOMERS.data = newShopCustomer;
							});
						this.GET_SHOP_CUSTOMERS.data.map((item) => {
							item.checked = false;
						});
					})
					.catch((error) => {
						this.updating = false;
						if (error.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						} else if (error.response.status === 500) {
							this.$services.helpers.notification(
								"server error",
								"error",
								this
							);
						} else {
							this.$services.helpers.notification(
								error.response.data,
								"error",
								this
							);
						}
					});
			} else {
				this.updating = true;
				checkShopCustomers.map((item) => {
					this.$store
						.dispatch("portal/putCustomer", {
							customer_number: item.customer_number,
							category: { id: this.assignedCustomerCategory },
							id: item.id,
							email: item.email,
							customer_name: item.customer_name,
						})
						.then((response) => {
							const ShopCustomerIndex =
								this.GET_SHOP_CUSTOMERS.data.findIndex(
									(item) =>
										item.id === checkShopCustomers[0].id
								);
							const newShopCustomer =
								this.GET_SHOP_CUSTOMERS.data;
							this.$store
								.dispatch("portal/getCompanyEndCustomer", {
									id: checkShopCustomers[0].id,
								})
								.then((response) => {
									newShopCustomer[
										ShopCustomerIndex
									].category = response.data.category;
									this.GET_SHOP_CUSTOMERS.data =
										newShopCustomer;
								});
						});
				});
				this.updating = false;
				this.$services.helpers.notification(
					this.$t("save"),
					"success",
					this
				);
				this.assignMultipleCustomerCategory = false;
				this.GET_SHOP_CUSTOMERS.data.map((item) => {
					item.checked = false;
				});
			}
		},
		beforeCloseBlogPreview() {
			this.modal = false;
			this.blogPostPreview = false;
			this.blogPostPreviewImage = "";
			window.Bus.$emit("close-preview-blog");
		},
		renderArrays(data) {
			const result = [];
			let html = "";
			if (data) {
				if (data.length > 0) {
					data.map((v) => result.push(v.name));
					// return result.join(', ')
					result.forEach((element) => {
						html += `<span class='px-2 text-gray-700 py-1 border rounded-full'>${element}</span>&nbsp;`;
					});
					return html;
				}
			}
		},
		getSingleCustomerCategory(data) {
			this.EditcustomerCategory = data;
			this.modalEditCustomerCategory = true;
		},
		updateCustomerCategory() {
			if (this.EditcustomerCategory.name === "") {
				return this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			}
			this.updateBtn = true;
			const payload = {
				id: this.EditcustomerCategory.id,
				account_number: this.EditcustomerCategory.account_number,
				name: this.EditcustomerCategory.name,
			};
			this.$store
				.dispatch("portal/updateCompanyEndCustomerCategory", payload)
				.then((response) => {
					this.updateBtn = false;
					this.modalEditCustomerCategory = false;
					this.$services.helpers.notification(
						`${this.$t("updatedSuccess")}`,
						"success",
						this
					);
				})
				.catch((err) => {
					this.updateBtn = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					if (err.response.status === 400) {
						this.$services.helpers.notification(
							err.response.data.error,
							"error",
							this
						);
					}
				});
		},
		deleteCustomerCategory(data) {
			const self = this;
			this.$confirm(
				`${this.$t("removeUser")} ${data.name}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";

							self.$store
								.dispatch(
									"portal/deleteCompanyEndCustomerCategory",
									{ id: data.id }
								)
								.then((_) => {
									const itemIndex =
										this.GET_CUSTOMER_CATEGORY.results.findIndex(
											(item) => item.id === data.id
										);
									const copyCategory =
										this.GET_CUSTOMER_CATEGORY.results;
									copyCategory.splice(itemIndex, 0);
									// this.$store.commit('portal/UPDATE_CUSTOMER_CATEGORY', copyCategory)
									this.$store.dispatch(
										"portal/getCustomerCategory"
									);
									done();
									this.$services.helpers.notification(
										`${this.$t("removeSuccessful")}`,
										"success",
										this
									);
									instance.confirmButtonLoading = false;
								})
								.catch((err) => {
									instance.confirmButtonLoading = false;
									if (
										err.response.status === 400 ||
										err.response.status === 404 ||
										err.response.status === 500 ||
										err.response.status === 403
									) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									}
									if (err.response.status === 401) {
										this.$store.commit(
											"auth/CLEAR_AUTH_USER",
											null
										);
										window.Bus.$emit("sign-out");
									}
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {
					done();
				})
				.catch(() => {});
		},
		getArticleDetail(articleNumber) {
			this.$store
				.dispatch("portal/getSingleArticle", {
					url: `/${articleNumber}`,
				})
				.then((res) => {
					this.modalRelevantArticleObject = res.data;
					this.loadingRelevantArticle = false;
					this.selectedCustomers = this.GET_ALL_CUSTOMERS.filter(
						(item) =>
							this.modalRelevantArticleObject.allowed_customer_numbers.includes(
								item.id
							) === true
					);
				})
				.catch((_) => {
					this.loadingRelevantArticle = false;
				});
		},
		async handleArticleChange(file) {
			this.allImages = [];
			let response = await this.readFileAsyncExcel(file.raw);
			if (response["status"] === true) {
				if (response["rows"].length === 0) {
					this.$services.helpers.notification(
						`${this.$t("invalidExcelFormat")}
					accepted excel headers ${EXCEL_HEADER_FORMAT}`,
						"error",
						this
					);
					setTimeout(function () {
						let children = document.querySelectorAll(
							".upload-excel .el-icon-close"
						);
						if (children.length > 0) {
							children[0].click();
						}
					}, 500);
					return false;
				}
				this.excelFile.push(file);
			} else {
				this.$services.helpers.notification(
					`${this.$t(
						"invalidExcelFormat"
					)} accepted excel headers ${EXCEL_HEADER_FORMAT}`,
					"error",
					this
				);
				setTimeout(function () {
					let children = document.querySelectorAll(
						".upload-excel .el-icon-close"
					);
					if (children.length > 0) {
						children[0].click();
					}
				}, 500);
				return false;
			}
		},
		handleArticleRemove() {
			this.excelFile.pop();
		},
		async handleZipImageChange(file) {
			this.totalImagesToUpload = [];
			this.estimatedTime = "";
			if (this.excelFile.length <= 1) {
				let files = await this.unzipFile(file.raw);
				if (this.excelFile.length <= 0) {
					this.$services.helpers.notification(
						this.$t("uploadExcelFile"),
						"error",
						this
					);
					setTimeout(function () {
						let children = document.querySelectorAll(
							".upload-zip .el-icon-close"
						);
						if (children.length > 0) {
							children[0].click();
						} else {
							children.click();
						}
					}, 500);
					return false;
				}
				if (files["status"] === true) {
					if (files["total_images"] <= 0) {
						this.$services.helpers.notification(
							this.$t("emptyUploadedZip"),
							"error",
							this
						);
						setTimeout(function () {
							let children = document.querySelectorAll(
								".upload-zip .el-icon-close"
							);
							if (children.length > 0) {
								children[0].click();
							}
						}, 500);
						return false;
					}
					if (files["image_with_error"].length > 0) {
						this.$services.helpers.notification(
							`${files["image_with_error"].join(
								","
							)} following file has an invalid image file`,
							"error",
							this
						);
						setTimeout(function () {
							let children = document.querySelectorAll(
								".upload-zip .el-icon-close"
							);
							if (children.length > 0) {
								children[0].click();
							}
						}, 500);
						return false;
					}
					this.totalImagesToUpload = files["total_images"];
					var uploadSpeedRate = navigator.connection.downlink;
					if (uploadSpeedRate <= 0) {
						uploadSpeedRate = 0.1;
					}
					let kps = uploadSpeedRate * 1000;
					let uploadtime = file.size / kps;
					let timeInSec = Math.round(uploadtime / 60);
					this.estimatedTime = `${(timeInSec / 60).toFixed(
						2
					)} minutes`;
					this.allZipImages = files["all_images"];
					this.fileList.push(file);
				} else {
					setTimeout(function () {
						let children = document.querySelectorAll(
							".upload-zip .el-icon-close"
						);
						if (children.length > 0) {
							children[0].click();
						}
					}, 500);
					return this.$services.helpers.notification(
						"fileCouldNotBeProcess",
						"error",
						this
					);
				}
			}
		},
		handleZipImageRemove() {
			this.totalImagesToUpload = 0;
			this.fileList.pop();
		},
		async unzipFile(data) {
			try {
				let context = { status: true };
				const zipper = new JSZip();
				const zip = await zipper.loadAsync(data);
				context["total_images"] = Object.keys(zip.files).length - 1;
				let imageWithError = [];
				let allImages = [];
				let errorCount = 0;
				for (const [key, value] of Object.entries(zip.files)) {
					await zip.files[key]
						.async("base64")
						.then(function (fileData) {
							if (fileData !== "") {
								const image = new Image();
								image.src = `data:image/png;base64,${fileData}`;
								image.onload = function () {
									allImages.push(key);
									if (
										image.width > 800 &&
										image.height > 800
									) {
										if (key) {
											imageWithError.push(key);
											errorCount += 1;
										}
									}
								};
							}
						});
				}
				context["image_with_error"] = imageWithError;
				context["image_with_error_count"] = imageWithError.length;
				context["errorCount"] = errorCount;
				context["all_images"] = allImages;
				context["files"] = zip.files;
				return context;
			} catch (e) {
				return { status: false, message: e };
			}
		},
		async processExcel(data) {
			let context = { status: false };
			
			let resp = await this.readFileAsyncExcel(data);
			
			return context;
		},
		async readFileAsyncExcel(file) {
			return new Promise((resolve, reject) => {
				import("xlsx").then((wrapper) => {
					let reader = new FileReader();
					let context = { status: false };
					reader.onload = () => {
						var fileData = reader.result;
						var wb = wrapper.read(fileData, { type: "binary" });
						if (wb.SheetNames.length > 0) {
							var rowObj = wrapper.utils.sheet_to_json(
								wb.Sheets[wb.SheetNames[0]]
							);
							context["status"] = true;
							context["rows"] = rowObj;
						}
						resolve(context);
					};
					reader.onerror = () => {
						reject(context);
					};
					reader.readAsArrayBuffer(file);
				});
			});
		},
		getProductCategory() {
			this.$store.dispatch("portal/getProductCategory");
		},
		editArticleSpecification: function (item) {
			this.articleSpecificationData = item;
			this.openArticleSpecificationData = true;
		},
		updateArticleSpecification() {
			this.updating = true;
			if (
				this.articleSpecificationData.title === "" ||
				this.articleSpecificationData.text === ""
			) {
				this.updating = false;
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else {
				this.updating = true;
				const payload = {
					title: this.articleSpecificationData.title,
					text: this.articleSpecificationData.text,
					article_id: this.articleSpecificationData.article,
				};
				if (getCompanyId()) {
					payload["company_id"] = getCompanyId();
				}
				this.$store
					.dispatch("portal/updateArticleSpecification", {
						id: this.articleSpecificationData.id,
						data: payload,
					})
					.then((_) => {
						this.updating = false;
						this.$services.helpers.notification(
							this.$t("save"),
							"success",
							this
						);
						this.openArticleSpecificationData = false;
					})
					.catch((err) => {
						this.updating = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
						if (err.response.status === 400) {
							if (err.response.data.hasOwnProperty("errors")) {
								this.$services.helpers.notification(
									this.$t("serverError"),
									"error",
									this
								);
							} else {
								this.$services.helpers.notification(
									err.response.data.message,
									"error",
									this
								);
							}
						}
					});
			}
		},
		updateNote() {
			this.addingUsers = true;
			let data = {
				is_seller_handle_note: this.orderObj.is_seller_handle_note,
			};
			if (this.orderObj.seller_note) {
				data["seller_note"] = this.orderObj.seller_note;
			}
			const payload = {
				data: data,
				URL: `/${this.orderObj.id}/notes/`,
			};
			this.$store
				.dispatch("portal/updateOrderNote", payload)
				.then((_) => {
					this.addingUsers = false;
					this.$services.helpers.notification(
						this.$t("updateSuccess"),
						"success",
						this
					);
					setTimeout(() => {
						this.modalNote = false;
					}, 1000);
				})
				.catch((err) => {
					this.addingUsers = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				});
		},
		createDeliveryAddress() {
			this.updating = true;
			this.$store
				.dispatch(
					"portal/saveCustomerDeliveryAddress",
					this.deliveryAddress
				)
				.then((res) => {
					this.updating = false;
					window.Bus.$emit(
						"reload-customer-delivery-address",
						res.data.data
					);
					this.modalAddDeliveryAddress = false;
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data[0],
						"error",
						this
					);
				});
		},
		updateCustomerDeliveryAddress() {
			this.updating = true;
			if (this.deliveryAddress.hasOwnProperty("customer")) {
				this.deliveryAddress["customer_id"] =
					this.deliveryAddress.customer;
				delete this.deliveryAddress["customer"];
			}
			this.$store
				.dispatch("portal/updateCustomerDeliveryAddress", {
					id: this.deliveryAddress.id,
					data: this.deliveryAddress,
				})
				.then((res) => {
					this.updating = false;
					this.modalEditDeliveryAddress = false;
					this.$services.helpers.notification(
						this.$t("addressUpdated"),
						"success",
						this
					);
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data[0],
						"error",
						this
					);
				});
		},
		createWhiteLabelCategory: function () {
			this.updating = true;
			this.catObj["company"] = this.companySettingObj.id;
			this.$store
				.dispatch("portal/saveFaqCategory", this.catObj)
				.then((_) => {
					this.updating = false;
					this.openFaqCategoryAddModal = false;
					this.$services.helpers.notification(
						this.$t("faqCategorySuccess"),
						"success",
						this
					);
					window.Bus.$emit("reload-faq-category");
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				});
		},
		updateWhiteLabelCategory: function () {
			this.catObj["company"] = this.companySettingObj.id;
			this.$store
				.dispatch("portal/updateFaqCategory", {
					id: this.catID,
					data: this.catObj,
				})
				.then((res) => {
					this.updating = false;
					this.openFaqCategoryEditModal = false;
					this.$services.helpers.notification(
						this.$t("faqCategorySuccess"),
						"success",
						this
					);
					window.Bus.$emit("update-faq-category", {
						id: this.catID,
						data: res.data.data,
					});
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				});
		},
		createWhiteLabelFaq: function () {
			this.updating = true;
			this.faqObj["company"] = this.companySettingObj.id;
			this.$store
				.dispatch("portal/saveWhiteLabelFaq", this.faqObj)
				.then((_) => {
					this.updating = false;
					this.openFaqAddModal = false;
					this.$services.helpers.notification(
						this.$t("faqSuccess"),
						"success",
						this
					);
					window.Bus.$emit("reload-faq");
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				});
		},
		updateWhiteLabelFaq: function () {
			this.updating = true;
			this.faqObj["company"] = this.companySettingObj.id;
			this.$store
				.dispatch("portal/updateWhiteLabelFaq", {
					id: this.faqID,
					data: this.faqObj,
				})
				.then((res) => {
					this.updating = false;
					this.openFaqEditModal = false;
					this.$services.helpers.notification(
						this.$t("faqSuccess"),
						"success",
						this
					);
					window.Bus.$emit("update-faq", {
						id: this.faqID,
						data: res.data.data,
					});
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
					this.$services.helpers.notification(
						err.response.data.message,
						"error",
						this
					);
				});
		},
		getAllArticles() {
			this.$store
				.dispatch("portal/getFullArticles", { url: `?limit=500` })
				.then((res) => {
					for (var i = 1; i <= res.data.total_pages; i++) {
						this.$store.dispatch("portal/getFilterArticles", {
							url: `?limit=500&page=${i}`,
						});
					}
				})
				.catch((err) => {
					window.Bus.$emit("output-error", err);
				});
		},
		getAccounts: function () {
			this.$store.dispatch("portal/getActiveAccounts").then((resp) => {
				let total_pages = Math.ceil(resp.data.count / 500);
				for (var i = 1; i <= total_pages; i++) {
					this.$store.dispatch("portal/getAllActiveAccounts", {
						url: `?limit=500&page=${i}`,
					});
				}
			});
		},
		getCostCenters: function () {
			this.$store.dispatch("portal/getCostCenter").then((resp) => {
				let total_pages = Math.ceil(resp.data.count / 500);
				for (var i = 1; i <= total_pages; i++) {
					this.$store.dispatch("portal/getAllCostCenter", {
						data: `?limit=500&page=${i}`,
					});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	padding-top: unset !important;
}

.form-control {
	height: unset !important;
	padding: 0.275rem 0.75rem !important;
}

.app-theme-gray.app-container {
	background: #f1f4f6 !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control,
.form-select {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	// cursor: pointer;
}

.carousel-cell {
	width: 100%; /* full width */
	height: 200px;
	//   background: #222;
	/* center images in cells with flexbox */
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
}

.height-increase {
	height: 63vh;
}

.drawer-category-management {
	.table th {
		color: #050505 !important;
		padding: 0.6rem 0.55rem !important;
	}

	.table tbody tr > td {
		font-size: 13px !important;
		color: #6f6f6f !important;
		padding: 0.4rem 0.55rem !important;
	}
}

.previewTitle {
	text-align: center;
	margin-right: 25px;
	margin-left: 25px;
}
</style>
